import {
  AdminDeleteMessageBlastMutationVariables,
  AdminGetMessageBlastsQueryVariables
} from '@/gql/generated/graphql';
import {
  deleteMessageBlastMutation,
  getMessageBlastsQuery
} from './blasts.server';
import clientWrapper from './wrappers/client.wrapper';

export const getBlastsActions = () => {
  const getMessageBlasts = (variables: AdminGetMessageBlastsQueryVariables) => {
    return getMessageBlastsQuery(variables);
  };

  const deleteMessageBlast = (
    variables: AdminDeleteMessageBlastMutationVariables
  ) => {
    return deleteMessageBlastMutation(variables);
  };

  return {
    getMessageBlasts: clientWrapper(getMessageBlasts),
    deleteMessageBlast: clientWrapper(deleteMessageBlast)
  };
};
