import {
  AdminGetUserQueryVariables,
  AdminGetUsersQueryVariables,
  AdminUpdateCreatorProfileMutationVariables
} from '@/gql/generated/graphql';
import {
  getCreatorProfileQuery,
  getUserForCreatorsDataProviderQuery,
  getUsersForCreatorsDataProviderQuery,
  updateCreatorProfileMutation
} from './creators.server';
import clientWrapper from './wrappers/client.wrapper';

export const getCreatorsActions = () => {
  const getUserForCreatorsDataProvider = (
    variables: AdminGetUserQueryVariables
  ) => {
    return getUserForCreatorsDataProviderQuery(variables);
  };

  const getUsersForCreatorsDataProvider = (
    variables: AdminGetUsersQueryVariables
  ) => {
    return getUsersForCreatorsDataProviderQuery(variables);
  };

  const getCreatorProfile = (variables: AdminGetUserQueryVariables) => {
    return getCreatorProfileQuery(variables);
  };

  const updateCreatorProfile = (
    variables: AdminUpdateCreatorProfileMutationVariables
  ) => {
    return updateCreatorProfileMutation(variables);
  };

  return {
    getUserForCreatorsDataProvider: clientWrapper(
      getUserForCreatorsDataProvider
    ),
    getUsersForCreatorsDataProvider: clientWrapper(
      getUsersForCreatorsDataProvider
    ),
    getCreatorProfile: clientWrapper(getCreatorProfile),
    updateCreatorProfile: clientWrapper(updateCreatorProfile)
  };
};
