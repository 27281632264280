/* eslint-disable react-hooks/exhaustive-deps */
import { UserRoles } from '@/gql/generated/graphql';
import { getCreatorsActions, getUsersActions } from '@/server-actions';
import EditIcon from '@mui/icons-material/Edit';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';
import {
  Edit,
  EmailField,
  ImageField,
  Link,
  SaveButton,
  Show,
  SimpleForm,
  TabbedShowLayout,
  TextInput,
  Toolbar,
  ToolbarProps,
  useEditContext
} from 'react-admin';
import NotesModal from '../shared/Notes/NotesModal';
import ConfirmationModal from './ConfirmationModal';
import { CreatorSocialMedia } from './CreatorSocialMedia';
import ForeignAccountConfirmationModal from './ForeignAccountConfirmationModal';

export const CreatorEdit = () => (
  <Edit redirect={false}>
    <CreatorEditForm />
  </Edit>
);

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  p: 2
};

const CreatorEditForm = (props: ToolbarProps) => {
  const [accepted, setAccepted] = useState(false);
  const [rejected, setRejected] = useState(false);
  const { record } = useEditContext<User>();
  const creatorId = record?.id as string;

  const [showApproveConfirmationModal, setShowApproveConfirmationModal] =
    useState(false);
  const [showRejectConfirmationModal, setShowRejectConfirmationModal] =
    useState(false);
  const [foreignAccount, setForeignAccount] = useState(
    record?.creatorProfile?.foreignAccount
  );
  const [
    showForeignAccountConfirmationModal,
    setShowForeignAccountConfirmationModal
  ] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showImportFollowersModal, setShowImportFollowersModal] =
    useState(false);
  const [importingFollowers, setImportingFollowers] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<{
    content: string;
    type: 'error' | 'success';
  }>({ content: '', type: 'error' });
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const { getCreatorProfile, updateCreatorProfile } = getCreatorsActions();

  const { updateUser } = getUsersActions();
  const [roles, setRoles] = useState<UserRoles[]>([]);

  const confirmReject = async () => {
    try {
      const rejectedAt: Date = new Date();

      await updateCreatorProfile({
        userId: creatorId,
        input: {
          rejectedAt,
          acceptedAt: null
        }
      });

      setShowRejectConfirmationModal(false);
      setRejected(true);
      setAccepted(false);
    } catch (error) {
      console.error(error);
    }
  };

  const confirmApprove = async () => {
    try {
      const acceptedAt: Date = new Date();

      await updateCreatorProfile({
        userId: creatorId,
        input: {
          acceptedAt,
          rejectedAt: null
        }
      });

      setShowApproveConfirmationModal(false);
      setAccepted(true);
      setRejected(false);
    } catch (error) {
      console.error(error);
    }
  };

  const adjustForeignAccountStatus = async (makeForeignAccount: boolean) => {
    try {
      const input = {
        foreignAccount: makeForeignAccount
      };

      await updateCreatorProfile({
        userId: creatorId,
        input: input
      });

      setShowForeignAccountConfirmationModal(false);
      setForeignAccount(makeForeignAccount);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCreatorData = async () => {
    try {
      const data = await getCreatorProfile({ adminGetUserId: creatorId });

      setAccepted(
        data.adminGetUser?.creatorProfile?.acceptedAt === null ? false : true
      );
      setRejected(
        data.adminGetUser?.creatorProfile?.rejectedAt === null ? false : true
      );

      setRoles(data.adminGetUser?.roles);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const updateUserRoles = async () => {
    try {
      const data = await updateUser({
        adminUpdateUserId: creatorId,
        input: {
          roles: [...roles, UserRoles.Creator]
        }
      });
      setRoles(data.adminUpdateUser?.roles);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCreatorData();
  }, []);

  const ButtonComponent = () => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              data-testid={'creator-approve-button-ad'}
              disabled={accepted}
              variant="contained"
              color="success"
              onClick={() => setShowApproveConfirmationModal(true)}
            >
              {accepted ? 'Creator Approved' : 'Approve Creator'}
            </Button>
            <ConfirmationModal
              open={showApproveConfirmationModal}
              onClose={() => setShowApproveConfirmationModal(false)}
              onConfirm={() => confirmApprove()}
              action="approve"
            />
          </Grid>
          <Grid item>
            <Button
              data-testid={'creator-reject-button-ad'}
              disabled={rejected}
              variant="contained"
              color="error"
              onClick={() => setShowRejectConfirmationModal(true)}
            >
              {rejected ? 'Creator Rejected' : 'Reject Creator'}
            </Button>
            <ConfirmationModal
              open={showRejectConfirmationModal}
              onClose={() => setShowRejectConfirmationModal(false)}
              onConfirm={() => confirmReject()}
              action="reject"
            />
          </Grid>
        </Grid>
        <Grid container mt={1} spacing={2}>
          <Grid item>
            <Button
              disabled={roles.includes(UserRoles.Creator)}
              variant="contained"
              color={'success'}
              onClick={updateUserRoles}
            >
              Add Creator Role to Roles
            </Button>
          </Grid>
        </Grid>
        <Grid container mt={1} spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color={foreignAccount ? 'success' : 'info'}
              onClick={() => {
                setShowForeignAccountConfirmationModal(true);
              }}
            >
              {foreignAccount
                ? 'Remove Foreign Account'
                : 'Make Foreign Account'}
            </Button>
            <ForeignAccountConfirmationModal
              open={showForeignAccountConfirmationModal}
              onClose={() => setShowForeignAccountConfirmationModal(false)}
              onConfirm={() => {
                foreignAccount
                  ? adjustForeignAccountStatus(false)
                  : adjustForeignAccountStatus(true);
              }}
              action={
                foreignAccount
                  ? 'remove foreign account status'
                  : 'add foreign account status'
              }
            />
          </Grid>
        </Grid>
        <Grid container mt={1} spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="info"
              onClick={() =>
                window.location.replace(
                  `${location.origin}/#/users/${creatorId}`
                )
              }
            >
              Go to User Profile
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Show actions={false}>
        <TabbedShowLayout>
          <TabbedShowLayout.Tab label="Creator Profile">
            <SimpleForm
              data-testid={'creator-edit-form'}
              toolbar={
                <Toolbar>
                  <SaveButton />
                </Toolbar>
              }
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item md={4}>
                  <ImageField
                    label={'Profile pic'}
                    source="userProfile.avatarUrl"
                    title="profile picture"
                  />
                </Grid>
                <Grid item md={4} alignItems="center">
                  <TextInput
                    data-testid={'creator-userId-input-ad'}
                    label={'User Id'}
                    source="id"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item md={4} alignItems="center">
                  <TextInput
                    data-testid={'creator-username-input-ad'}
                    label="Username"
                    source="userProfile.username"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item md={4} alignItems="center">
                  <p>
                    Email: <EmailField label="Email" source="email" />
                  </p>
                </Grid>
                <Grid item md={4} alignItems="center">
                  <TextInput
                    label="First Name"
                    source="userProfile.firstName"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item md={4} alignItems="center">
                  <TextInput
                    label="Last Name"
                    source="userProfile.lastName"
                    fullWidth
                    disabled
                  />
                </Grid>
                {/* <Grid item md={4} alignItems="center">
                  <TextInput
                    label="Subscribers Count"
                    source="statistics.subscribersCount"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item md={4} alignItems="center">
                  <TextInput
                    label="Post Count"
                    source="statistics.postCount"
                    fullWidth
                    disabled
                  />
                </Grid> */}
                <Grid item md={4} alignItems="center">
                  <TextInput
                    label="Minimum Message Tip"
                    source="creatorProfile.minimumMessageTip"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item md={4} alignItems="center">
                  <TextInput
                    label="Minimum Media Tip"
                    source="creatorProfile.minimumMediaTip"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item md={4} alignItems="center">
                  <TextInput
                    label="Free Messaging Enabled?"
                    source="creatorProfile.freeMessageTipEnabled"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item md={4} alignItems="center">
                  <TextInput
                    label="Persona Status"
                    source="creatorProfile.personaStatus"
                    disabled={true}
                  />
                </Grid>
              </Grid>

              <Grid item xs={3} alignItems="flex-start">
                <div style={{ marginTop: '10px' }}>
                  <strong>Notes:</strong>
                </div>
                <Button
                  data-testid={`delete-bio-button-ad`}
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={() => setOpenNotesModal(true)}
                  endIcon={<EditIcon />}
                  style={{ marginTop: '10px' }}
                >
                  Notes
                </Button>
              </Grid>
              <Grid item xs={3} alignItems="flex-end">
                <div style={{ marginTop: '10px' }}>
                  <strong>Subscriptions:</strong>
                </div>
                <Link
                  to={`subscriptions?isCreator=${true}`}
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    data-testid={`delete-bio-button-ad`}
                    variant="outlined"
                    color="primary"
                    size="large"
                    endIcon={<ReceiptLongIcon />}
                    style={{ marginTop: '10px' }}
                  >
                    Subscriptions
                  </Button>
                </Link>
              </Grid>
              <br />
              <Grid container spacing={2}>
                <Grid item>{ButtonComponent()}</Grid>
              </Grid>

              <Snackbar
                open={Boolean(alertMessage.content)}
                autoHideDuration={10000}
                onClose={() => setAlertMessage({ content: '', type: 'error' })}
              >
                <Alert
                  onClose={() =>
                    setAlertMessage({ content: '', type: 'error' })
                  }
                  severity={alertMessage.type}
                  sx={{ width: '100%' }}
                >
                  {alertMessage.content}
                </Alert>
              </Snackbar>
              <NotesModal
                open={openNotesModal}
                onClose={() => setOpenNotesModal(false)}
                userId={record?.id as string}
              />
              <Modal
                open={showImportFollowersModal}
                onClose={(event, reason) => {
                  if (reason && reason == 'backdropClick' && importingFollowers)
                    return;
                  setShowImportFollowersModal(false);
                }}
              >
                <Box sx={style}>
                  <Stack direction="column" alignItems="center" spacing={2}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item alignItems="center">
                        <Typography variant="h5" color="black">
                          Import Followers
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent={'flex-end'}
                    ></Grid>
                  </Stack>
                </Box>
              </Modal>
            </SimpleForm>
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="Social Media" path="socialmedia">
            <CreatorSocialMedia />
          </TabbedShowLayout.Tab>
          {/* <TabbedShowLayout.Tab label="Redesign" name="Redesign">
            <CreatorProfileEdit />
          </TabbedShowLayout.Tab> */}
          {/* <TabbedShowLayout.Tab label="Posts" path="posts">
            <Resource
              name="posts"
              edit={PostEdit}
              list={<PostList username={record?.userProfile?.username} />}
              recordRepresentation="Posts"
              icon={ReceiptLongIcon}
            />
          </TabbedShowLayout.Tab> */}
          {/* <TabbedShowLayout.Tab label="Subscriptions" path="subscriptions">
            <Resource
              name="subscriptions"
              edit={SubscriptionDetails}
              list={Subscriptions}
              recordRepresentation="Subscriptions"
              icon={ReceiptLongIcon}
            /> 
            <Subscriptions />
           </TabbedShowLayout.Tab> 
           */}
          {/* <TabbedShowLayout.Tab label="Notes" name="notes">
            <NotesModal
              userId={creatorId}
              open={!openNotesModal}
              onClose={() => console.log('close')}
            />
          </TabbedShowLayout.Tab> */}
        </TabbedShowLayout>
      </Show>
    </>
  );
};

export interface User {
  id: string;
  email: string;
  userProfile: UserProfile | null;
  createdAt: string | null;
  roles: string[];
  creatorProfile: CreatorProfile;
  statistics?: Statistics;
}

interface UserProfile {
  id?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  bio?: string;
  avatarUrl?: string;
  bannerUrl?: string;
  websiteUrl?: string;
}

interface CreatorProfile {
  acceptedAt: string | null;
  rejectedAt: string | null;
  foreignAccount: boolean;
  socialAccounts?: SocialAccounts;
  minimumMessageTip?: number | null;
  minimumMediaTip?: number | null;
  freeMessageTipEnabled?: boolean;
  allowsMessaging?: boolean;
  freeMediaTipEnabled?: boolean;
  personaStatus?: string;
}

interface Statistics {
  postCount?: number | null;
  subscribersCount?: number | null;
}

interface SocialAccounts {
  instagram?: string | null;
  facebook?: string | null;
  twitter?: string | null;
  youtube?: string | null;
  tiktok?: string | null;
  website?: string | null;
}
