import { Edit } from 'react-admin';
import { LivestreamEditForm } from './Livestream';

export const LivestreamEdit = () => {
  return (
    <Edit>
      <LivestreamEditForm />
    </Edit>
  );
};
