import Chip from '@mui/material/Chip';

import {
  BooleanInput,
  Datagrid,
  DateField,
  List,
  NumberField,
  Pagination,
  TextField,
  TextInput
} from 'react-admin';

const QuickFilter = ({
  label
}: {
  label: string;
  source?: string;
  defaultValue?: string;
}) => {
  return <Chip sx={{ marginBottom: 1 }} label={label} />;
};

const filters = [
  <TextInput key="1" label="Search" source="query" alwaysOn />,
  <QuickFilter
    key="2"
    source="deletedAt"
    label="Status: Deleted"
    defaultValue={'true'}
  />,
  <BooleanInput key="3" label="Recommended" source="isRecommended" alwaysOn />
];

const PostPagination = () => (
  <div style={{ width: '100%', display: 'flex' }}>
    <Pagination rowsPerPageOptions={[10, 25, 50]} />
  </div>
);

export const PostList = () => (
  <div
    style={{
      overflowX: 'auto',
      whiteSpace: 'nowrap',
      maxWidth: '85%'
    }}
  >
    <List
      data-testid={'post-list-ad'}
      filter={{ withDeleted: true }}
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={filters}
      pagination={<PostPagination />}
      filterDefaultValues={{
        isRecommended: window.location.href.includes('recommended')
          ? true
          : false
      }}
    >
      <Datagrid
        data-testid={'post-datagrid-ad'}
        rowClick="edit"
        bulkActionButtons={false}
      >
        <TextField data-testid={'post-id-column-ad'} source="id" />
        <TextField
          data-testid={'post-creator-username-column-ad'}
          label="Creator username"
          source="author.username"
        />
        <TextField
          data-testid={'post-title-column-ad'}
          label="Text"
          source="title"
        />
        <DateField
          data-testid={'post-deletedAt-column-ad'}
          label="Deleted at"
          source="deletedAt"
        />
        <DateField
          data-testid={'post-createdAt-column-ad'}
          label="Created at"
          source="createdAt"
        />
        <NumberField
          data-testid={'post-viewCount-column-ad'}
          label="Views"
          source="viewCount"
        />
        <TextField label="Likes" source="likeCount" />
        <TextField label="Comments" source="commentCount" />
        <TextField label="Pinned?" source="isPinned" />
      </Datagrid>
    </List>
  </div>
);
