import { deleteCookie, setCookie } from 'cookies-next';
import { ACCESS_TOKEN_EXPIRY, REFRESH_TOKEN_EXPIRY } from './constants';

export const cookieDomain = () => {
  'use client';
  if (typeof window === 'undefined') {
    return undefined;
  }

  if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
    return undefined;
  }

  const parts = location.hostname.split('.');
  const domain = parts.slice(-2).join('.');
  return domain;
};

const COOKIE_OPTIONS = {
  domain: cookieDomain(),
  path: '/'
};

export const setAuthCookies = (data: {
  accessToken: string;
  refreshToken: string;
}) => {
  setCookie('adminAccessToken', data.accessToken, {
    ...COOKIE_OPTIONS,
    expires: new Date(Date.now() + ACCESS_TOKEN_EXPIRY)
  });
  setCookie('adminRefreshToken', data.refreshToken, {
    ...COOKIE_OPTIONS,
    expires: new Date(Date.now() + REFRESH_TOKEN_EXPIRY)
  });
};

export const deleteAuthCookies = () => {
  deleteCookie('adminAccessToken', {
    domain: cookieDomain(),
    path: '/'
  });
  deleteCookie('adminRefreshToken', {
    domain: cookieDomain(),
    path: '/'
  });
};
