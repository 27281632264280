import { useReCaptcha } from 'next-recaptcha-v3';
import { useCallback, useState } from 'react';
import { useLogin, useNotify } from 'react-admin';

const CustomLoginForm = () => {
  const { executeRecaptcha } = useReCaptcha();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const login = useLogin();
  const notify = useNotify();

  const handleSubmit = useCallback(
    async (e: { preventDefault: () => void }) => {
      e.preventDefault();
      setLoading(true);
      const recaptchaToken = await executeRecaptcha('login');
      try {
        await login({
          username: email,
          password,
          recaptchaToken
        });
      } catch (error) {
        notify('Invalid email or password');
      } finally {
        setLoading(false);
      }
    },
    [email, executeRecaptcha, login, notify, password]
  );

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#333'
      }}
    >
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '20px',
          borderRadius: '5px',
          width: '300px',
          backgroundColor: '#555'
        }}
        onSubmit={handleSubmit}
      >
        <h2 style={{ marginBottom: '20px', color: '#fff' }}>Login</h2>
        <input
          data-testid={`admin-login-email-input`}
          name="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value.trim().toLowerCase())}
          placeholder="Email"
          style={{
            width: '100%',
            marginBottom: '10px',
            padding: '8px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            outline: 'none'
          }}
          required
        />
        <input
          data-testid={`admin-login-password-input`}
          name="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          style={{
            width: '100%',
            marginBottom: '10px',
            padding: '8px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            outline: 'none'
          }}
          required
        />
        <button
          type="submit"
          disabled={loading}
          style={{
            width: '100%',
            padding: '10px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            outline: 'none'
          }}
        >
          {loading ? 'Loading...' : 'Login'}
        </button>
      </form>
    </div>
  );
};

export default CustomLoginForm;
