import {
  AdminGetLivestreamQueryVariables,
  AdminGetLivestreamsQueryVariables,
  AdminUpdateLivestreamMutationVariables
} from '@/gql/generated/graphql';
import {
  endAdminLiveStreamMutation,
  getAdminLiveStreamQuery,
  getAdminLiveStreamsQuery,
  updateAdminLiveStreamMutation
} from './livestreams.server';

import clientWrapper from './wrappers/client.wrapper';

export const getAdminLivestreamActions = () => {
  const getAdminLiveStreams = (
    variables: AdminGetLivestreamsQueryVariables
  ) => {
    return getAdminLiveStreamsQuery(variables);
  };
  const getAdminLiveStream = (variables: AdminGetLivestreamQueryVariables) => {
    return getAdminLiveStreamQuery(variables);
  };
  const endLiveStream = (variables: {
    input: { creatorId: string; id: string };
  }) => {
    return endAdminLiveStreamMutation(variables);
  };
  const updateLiveStream = (
    variables: AdminUpdateLivestreamMutationVariables
  ) => {
    return updateAdminLiveStreamMutation(variables);
  };

  return {
    getAdminLivestreams: clientWrapper(getAdminLiveStreams),
    getAdminLivestream: clientWrapper(getAdminLiveStream),
    endLiveStream: clientWrapper(endLiveStream),
    updateLiveStream: clientWrapper(updateLiveStream)
  };
};
