import { AdminAdminNoteDto } from '@/gql/generated/graphql';
import { convertTime } from '@/utils/convertTime';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import TocIcon from '@mui/icons-material/Toc';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import useAdminNotes from './useAdminNotes';
interface NotesModalProps {
  userId: string;
  open: boolean;
  onClose: () => void;
}
const NotesModal = ({ open, onClose, userId }: NotesModalProps) => {
  const [itemSelected, setItemSelected] = useState<AdminAdminNoteDto | null>(
    null
  );
  const [newNoteContent, setNewNoteContent] = useState('');
  const { list, createAdminNote, deleteAdminNote, editAdminNote } =
    useAdminNotes(userId);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState<AdminAdminNoteDto | null>(
    null
  );
  const [errorMessage, setErrorMessage] = useState({ content: '' });
  useEffect(() => {
    if (!open) {
      setItemSelected(null);
      setNewNoteContent('');
    }
  }, [open]);

  const handleCancelEdit = () => {
    setItemSelected(null);
  };

  const handleSave = async () => {
    try {
      await createAdminNote(newNoteContent);
      setNewNoteContent('');
    } catch (error: any) {
      setErrorMessage({ content: error.message });
    }
  };

  const handleEdit = (item: AdminAdminNoteDto) => {
    setItemSelected(item);
  };

  const handleUpdate = async () => {
    if (itemSelected) {
      try {
        await editAdminNote(itemSelected.id, itemSelected.content);
        setItemSelected(null);
      } catch (error: any) {
        setErrorMessage({ content: error.message });
      }
    }
  };

  const handleOpenConfirm = (item: AdminAdminNoteDto) => {
    setNoteToDelete(item);
    setConfirmOpen(true);
  };

  const handleCloseConfirm = () => {
    setNoteToDelete(null);
    setConfirmOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (noteToDelete) {
      try {
        await deleteAdminNote(noteToDelete.id);
        handleCloseConfirm();
      } catch (error: any) {
        setErrorMessage({ content: error.message });
      }
    }
  };

  return (
    <>
      <Dialog
        PaperProps={{
          sx: {
            height: '400px'
          }
        }}
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <div>
            <Typography variant="h4" color="black">
              Notes
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          {list && list.length > 0 ? (
            <Box
              sx={{
                maxHeight: '200px',
                overflowY: 'auto',
                overflowX: 'hidden'
              }}
            >
              <List sx={{ pt: 0, height: '100%' }}>
                {list.map((item) =>
                  item.id === itemSelected?.id ? (
                    <Box display="flex" key={item.id}>
                      <TextField
                        value={itemSelected.content}
                        autoFocus
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(e) =>
                          setItemSelected({
                            ...itemSelected,
                            content: e.target.value
                          })
                        }
                      />
                      <IconButton
                        onClick={handleUpdate}
                        edge="end"
                        disableRipple
                        aria-label="done"
                      >
                        <DoneIcon />
                      </IconButton>
                      <IconButton
                        disableRipple
                        onClick={handleCancelEdit}
                        edge="end"
                        aria-label="cancel"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <ListItem
                      style={{ display: 'flex' }}
                      secondaryAction={
                        <>
                          <IconButton
                            onClick={() => handleEdit(item)}
                            edge="end"
                            aria-label="edit"
                            disableRipple
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => handleOpenConfirm(item)}
                            edge="end"
                            aria-label="delete"
                            disableRipple
                          >
                            <DeleteIcon />
                          </IconButton>
                        </>
                      }
                      disableGutters
                      key={item.id}
                    >
                      <ListItemText
                        primary={item.content}
                        style={{ width: '100%' }}
                        primaryTypographyProps={{
                          fontSize: '0.9rem'
                        }}
                      />
                      <ListItemText
                        style={{ flexShrink: 0, paddingRight: '5px' }}
                        primaryTypographyProps={{
                          fontSize: '0.7rem',
                          color: 'gray'
                        }}
                        primary={convertTime(item.createdAt, false, true)}
                      />
                    </ListItem>
                  )
                )}
              </List>
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              style={{ opacity: 0.5 }}
            >
              <TocIcon style={{ fontSize: '100px' }} />
              <Typography variant="h4">
                You don&apos;t have notes yet
              </Typography>
            </Box>
          )}
          <TextField
            autoFocus
            name="note"
            label="Type here"
            type="text"
            fullWidth
            variant="standard"
            value={newNoteContent}
            onChange={(e) => setNewNoteContent(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            disabled={newNoteContent.length < 1}
            type="submit"
            onClick={handleSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmOpen} onClose={handleCloseConfirm}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this note ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={Boolean(errorMessage.content)}
        autoHideDuration={10000}
        onClose={() => setErrorMessage({ content: '' })}
      >
        <Alert
          onClose={() => setErrorMessage({ content: '' })}
          severity="error"
          sx={{ width: '100%' }}
        >
          {errorMessage.content}
        </Alert>
      </Snackbar>
    </>
  );
};
export default NotesModal;
