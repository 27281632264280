import { AdminSocailAccountDto } from '@/gql/generated/graphql';
import { getCreatorsActions } from '@/server-actions';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import {
  Edit,
  SaveButton,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextInput,
  Toolbar,
  useRecordContext
} from 'react-admin';

export const CreatorSocialMedia = () => (
  <Edit>
    <SocialMedia />
  </Edit>
);

const SocialMedia = () => {
  const { updateCreatorProfile } = getCreatorsActions();
  const record = useRecordContext();
  const [hasChanges, setHasChanges] = useState(false);

  const [socialAccounts, setSocialAccounts] = useState<AdminSocailAccountDto>({
    instagram: '',
    facebook: '',
    twitter: '',
    tiktok: '',
    youtube: '',
    website: ''
  });

  // const fetchCreatorData = async () => {
  //   try {
  //     const data = await getCreatorProfile({
  //       adminGetUserId: record.id.toString()
  //     });
  //     console.log('data', data);
  //     setSocialAccounts(
  //       data.adminGetUser.creatorProfile
  //         ?.socialAccounts as AdminSocailAccountDto
  //     );
  //     console.log('socialAccounts', socialAccounts);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };

  useEffect(() => {
    if (record.creatorProfile.socialAccounts) {
      setSocialAccounts(record.creatorProfile.socialAccounts);
    }
  }, []);

  const updateAccounts = async (key: string, value: string) => {
    setHasChanges(true);
    setSocialAccounts((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const removeTypename = (key: string, value: string) => {
    return key === '__typename' ? undefined : value;
  };

  const updateSocialAccounts = async () => {
    const cleanedSocialAccounts = JSON.parse(
      JSON.stringify(socialAccounts),
      removeTypename
    );
    try {
      await updateCreatorProfile({
        userId: record.id.toString(),
        input: {
          socialAccounts: {
            ...cleanedSocialAccounts
          }
        }
      });
      setHasChanges(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <SimpleForm
        data-testid={'creator-edit-form'}
        // toolbar needs to be there, but the save button should not and does not work
        toolbar={<Toolbar>{!true && <SaveButton />}</Toolbar>}
      >
        <Show actions={false}>
          <SimpleShowLayout>
            <strong>Social Media Accounts:</strong>
          </SimpleShowLayout>
        </Show>
        <Show actions={false}>
          <SimpleShowLayout>
            <TextInput
              label="Instagram"
              source="instagram"
              onChange={(e) => {
                updateAccounts('instagram', e.target.value);
              }}
              defaultValue={record.creatorProfile.socialAccounts.instagram}
            />
          </SimpleShowLayout>

          <SimpleShowLayout>
            <TextInput
              label="Facebook"
              source="facebook"
              onChange={(e) => {
                updateAccounts('facebook', e.target.value);
              }}
              defaultValue={record.creatorProfile.socialAccounts?.facebook}
            />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextInput
              label="Twitter"
              source="twitter"
              onChange={(e) => {
                updateAccounts('twitter', e.target.value);
              }}
              defaultValue={record.creatorProfile.socialAccounts?.twitter}
            />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextInput
              label="TikTok"
              source="tiktok"
              onChange={(e) => {
                updateAccounts('tiktok', e.target.value);
              }}
              defaultValue={record.creatorProfile.socialAccounts?.tiktok}
            />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextInput
              label="YouTube"
              source="youtube"
              onChange={(e) => {
                updateAccounts('youtube', e.target.value);
              }}
              defaultValue={record.creatorProfile.socialAccounts?.youtube}
            />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextInput
              label="Website"
              source="website"
              onChange={(e) => {
                updateAccounts('website', e.target.value);
              }}
              defaultValue={record.creatorProfile.socialAccounts?.website}
            />
          </SimpleShowLayout>
        </Show>
        <Show actions={false}>
          <SimpleShowLayout>
            <Button
              variant={hasChanges ? 'contained' : 'outlined'}
              color={hasChanges ? 'secondary' : 'primary'}
              size="large"
              onClick={updateSocialAccounts}
              disabled={!hasChanges}
            >
              Update Social Accounts
            </Button>
          </SimpleShowLayout>
        </Show>
      </SimpleForm>
    </>
  );
};
