import { Datagrid, DateField, List, Pagination, TextField } from 'react-admin';
import { AdvancedListActions } from '../shared/AdvancedFilterForm';

const CreatorPagination = () => (
  <div style={{ display: 'flex' }}>
    <Pagination rowsPerPageOptions={[10, 25, 50]} />
  </div>
);

export const CreatorList = () => (
  <div
    style={{
      overflowX: 'auto',
      whiteSpace: 'nowrap',
      maxWidth: '85%'
    }}
  >
    <List
      data-testid={'creator-list-ad'}
      filter={{ withDeleted: true }}
      sort={{ field: 'createdAt', order: 'DESC' }}
      pagination={<CreatorPagination />}
      actions={<AdvancedListActions />}
    >
      <Datagrid
        data-testid={'creator-datagrid-ad'}
        rowClick="edit"
        bulkActionButtons={false}
      >
        <TextField
          data-testid={'creator-userId-column-ad'}
          label="userId"
          source="id"
        />
        <TextField
          data-testid={'creator-username-column-ad'}
          label="Username"
          source="userProfile.username"
        />
        <TextField
          data-testid={'creator-email-column-ad'}
          label="Email"
          source="email"
        />
        <TextField
          data-testid={'creator-firstName-column-ad'}
          label="First Name"
          source="userProfile.firstName"
        />
        <TextField
          data-testid={'creator-lastName-column-ad'}
          label="Last Name"
          source="userProfile.lastName"
        />
        <DateField
          data-testid={'creator-acceptedAt-column-ad'}
          label="Accepted At"
          source="creatorProfile.acceptedAt"
        />
        <DateField
          data-testid={'creator-rejectedAt-column-ad'}
          label="Rejected At"
          source="creatorProfile.rejectedAt"
        />
        <DateField
          data-testid={'creator-appliedAt-column-ad'}
          label="Applied At"
          source="creatorProfile.appliedAt"
        />
      </Datagrid>
    </List>
  </div>
);
