import { addHours, format } from 'date-fns';
import { enUS } from 'date-fns/locale';
export const convertTime = (
  date: Date | string,
  formatTime: boolean = false,
  withTime: boolean = true
) => {
  if (!date) return 'Invalid Date';
  // Get the current date
  const currentDate = new Date();
  // Get the time zone offset in minutes / hours
  const timezoneOffset = currentDate.getTimezoneOffset() / 60;
  let timeFormat = 'MMM dd, yyyy';
  if (withTime) timeFormat += ' hh:mm a';

  const parsedDate = typeof date === 'string' ? new Date(date) : date;

  return formatTime
    ? format(addHours(parsedDate, timezoneOffset), timeFormat, {
        locale: enUS
      })
    : format(parsedDate, timeFormat, { locale: enUS });
};
