// import Papa from "papaparse";
import { getPostsActions } from '@/server-actions';
import MuxPlayer from '@mux/mux-player-react';
import { useEffect, useState } from 'react';
import {
  Button,
  Edit,
  SimpleForm,
  TextInput,
  ToolbarProps,
  useEditContext
} from 'react-admin';

export const PostEdit = () => (
  <Edit redirect={false}>
    <PostEditForm />
  </Edit>
);

//TODO: check -- do we still use this in another file?
export const CustomTextInput = ({
  label,
  source,
  disabled
}: {
  label: string;
  source: string;
  disabled?: boolean;
}) => {
  return (
    <>
      <p style={{ fontSize: 'small', marginTop: 0, marginBottom: '5px' }}>
        {label}
      </p>
      <TextInput
        disabled={!!disabled}
        label={false}
        source={source}
        sx={{
          marginTop: '0',
          marginBottom: '0',
          '& .MuiFilledInput-input': {
            paddingY: '5px',
            width: '400px'
          }
        }}
      />
    </>
  );
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  p: 2
};
const PostEditForm = (props: ToolbarProps) => {
  const parts = window.location.href.split('/');
  const postId = parts[parts.length - 1];
  const [comments, setComments] = useState<any>([]);
  const [assets, setAssets] = useState<any>([]);
  const [postDeleted, setPostDeleted] = useState(false);
  const { deletePost, restorePost, deletePostComment, restorePostComment } =
    getPostsActions();

  // const params = useParams();
  // const id = params.id as string;
  // const notify = useNotify();
  // const refresh = useRefresh();
  const { record, isLoading } = useEditContext<Post>();

  useEffect(() => {
    record && setComments(record.comments);
    record && setAssets(record.postAssets);
    record && setPostDeleted(record.deletedAt ? true : false);
  }, [record, postId]);

  const softDeletePost: any = async (id: string) => {
    const result = await deletePost({ id });
    setPostDeleted(Boolean(result?.adminSoftDeletePost));
  };

  const handleRestorePost: any = async (id: string) => {
    const result = await restorePost({ id });
    setPostDeleted(!result?.adminRestorePost);
  };

  const softDeletePostComment: any = async (id: string, postId: string) => {
    await deletePostComment({ id, postId });

    const updatedComments = comments.map((comment: any) => {
      if (comment.id === id) {
        comment.deletedAt = new Date();
        return comment;
      }

      return comment;
    });

    setComments(updatedComments);
  };

  const handleRestorePostComment: any = async (id: string, postId: string) => {
    await restorePostComment({ id, postId });

    const updatedComments = comments.map((comment: any) => {
      if (comment.id === id) {
        comment.deletedAt = null;
        return comment;
      }

      return comment;
    });

    setComments(updatedComments);
  };

  const assembleComments = (comments: Comment[]) => {
    return comments.map((comment: Comment) => {
      // const deleted = comment.deletedAt ? true : false;
      return (
        <div
          key={comment.id}
          style={{ marginTop: '5px', marginBottom: '20px' }}
        >
          <p
            style={{
              fontSize: 'large',
              marginTop: '5px',
              marginBottom: '5px',
              fontWeight: 'bold',
              color: 'gray'
            }}
          >
            {comment.author?.username}
          </p>
          <p style={{ fontSize: 'medium', marginBottom: '10px' }}>
            {comment.comment}
          </p>
          <Button
            data-testid="delete-comment-button-ad"
            disabled={comment.deletedAt ? true : false}
            variant="contained"
            color="error"
            onClick={() => softDeletePostComment(comment.id, postId)}
            style={{ marginRight: '5px', marginLeft: '5px' }}
          >
            {comment.deletedAt ? <p>Deleted</p> : <p>Delete</p>}
          </Button>
          <Button
            data-testid="restore-comment-button-ad"
            disabled={comment.deletedAt ? false : true}
            variant="contained"
            color="success"
            onClick={() => handleRestorePostComment(comment.id, postId)}
            style={{ marginRight: '5px', marginLeft: '5px' }}
          >
            {comment.deletedAt ? <p>Restore</p> : <p>Restored</p>}
          </Button>
        </div>
      );
    }, []);
  };

  const media = (type: string, asset: AssetAgain) => {
    if (type === 'image' || type === 'image/jpeg' || type === 'image/png') {
      if (!asset.resizedURL) {
        return <p>No URL found for this image</p>;
      } else {
        return (
          <img
            style={{ maxWidth: '450px', height: 'auto', marginBottom: '10px' }}
            src={asset.resizedURL}
          />
        );
      }
    }
    if (
      asset.videoPlaybackId !== null ||
      type === 'video' ||
      type === 'video/mp4' ||
      type === 'video/quicktime'
    ) {
      return <MuxPlayer playbackId={asset.videoPlaybackId} loop />;
    } else {
      return <span>Asset of type {type} is not supported by the admin UI</span>;
    }
  };

  const assembleAssets = (assets: PostAssets) => {
    return Object.values(assets).map((asset: Asset) => {
      const assetCode = media(asset.asset.mimeType, asset.asset);
      return (
        <div
          key={asset.asset.id}
          style={{ marginTop: '5px', marginBottom: '20px' }}
        >
          <div>{assetCode}</div>
        </div>
      );
    }, []);
  };

  return (
    <Edit>
      <SimpleForm
        toolbar={<></>}
        style={{
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <div
          style={{
            flex: 1,
            marginRight: 10
          }}
        >
          <h2
            style={{
              fontSize: 'x-large',
              marginTop: '30px',
              marginBottom: '20px',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            DETAILS
          </h2>
          <p
            data-testid="creator-username-container-ad"
            style={{
              fontSize: 'medium',
              marginTop: '10px',
              marginBottom: '10px'
            }}
          >
            <strong>Creator username -- </strong>{' '}
            <a href={`/#/creators/${record!.author.userId}`}>
              {record!.author.username}
            </a>
          </p>
          <p
            data-testid="post-id-container-ad"
            style={{
              fontSize: 'medium',
              marginTop: '10px',
              marginBottom: '10px'
            }}
          >
            <strong>Post id -- </strong> {record!.id}
          </p>
          <p
            data-testid="post-views-container-ad"
            style={{
              fontSize: 'medium',
              marginTop: '10px',
              marginBottom: '10px'
            }}
          >
            <strong>Views -- </strong> {record!.viewCount}
          </p>

          <p
            data-testid="post-likes-container-ad"
            style={{
              fontSize: 'medium',
              marginTop: '10px',
              marginBottom: '10px'
            }}
          >
            <strong>Likes -- </strong> {record!.likeCount}
          </p>

          <p
            data-testid="post-created-at-container-ad"
            style={{
              fontSize: 'medium',
              marginTop: '10px',
              marginBottom: '10px'
            }}
          >
            <strong>Created at -- </strong> {record!.createdAt}
          </p>
        </div>
        <div
          data-testid="post-assets-container-ad"
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <h2
            style={{
              fontSize: 'x-large',
              marginTop: '30px',
              marginBottom: '20px',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            TEXT
          </h2>
          <div data-testid="post-title-container-ad">
            <p>{record!.title}</p>
          </div>
          <h2
            style={{
              fontSize: 'x-large',
              marginTop: '30px',
              marginBottom: '20px',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            DELETE POST?
          </h2>
          <Button
            data-testid="delete-post-button-ad"
            disabled={postDeleted ? true : false}
            variant="contained"
            color="error"
            onClick={() => softDeletePost(record!.id)}
            style={{ marginRight: '5px', marginLeft: '5px' }}
          >
            {postDeleted ? <p>Deleted</p> : <p>Delete</p>}
          </Button>
          <Button
            data-testid="restore-post-button-ad"
            disabled={postDeleted ? false : true}
            variant="contained"
            color="success"
            onClick={() => handleRestorePost(record!.id)}
            style={{ marginRight: '5px', marginLeft: '5px' }}
          >
            {postDeleted ? <p>Restore</p> : <p>Restored</p>}
          </Button>
          <h2
            style={{
              fontSize: 'x-large',
              marginTop: '30px',
              marginBottom: '20px',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            MEDIA ASSETS
          </h2>
          <div>
            {assets && assets.length > 0 ? (
              assembleAssets(assets)
            ) : (
              <p>No media attached to this post</p>
            )}
          </div>
        </div>
        <div>
          <h2
            style={{
              fontSize: 'x-large',
              marginTop: '30px',
              marginBottom: '20px',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            COMMENTS
          </h2>
          {comments && comments.length > 0 ? (
            assembleComments(comments)
          ) : (
            <p>No comments on this post</p>
          )}
        </div>
      </SimpleForm>
    </Edit>
  );
};

export interface Post {
  id: string;
  creatorId: string;
  isExclusive: boolean;
  isPremium: boolean;
  isPinned: boolean;
  commentCount: number;
  likeCount: number;
  viewCount: number;
  title: string;
  subscriberPrice: number;
  nonSubscriberPrice: number;
  deletedAt: number;
  createdAt: number;
  comments: Comment[];
  postAssets: PostAssets;
  author: {
    username: string;
    userId: string;
  };
}

export interface Comment {
  id: string;
  comment: string;
  deletedAt: Date | null;
  createdAt: Date;
  author: {
    id: string;
    username: string;
  };
}

export interface PostAssets {
  asset: Asset[];
}

export interface Asset {
  asset: AssetAgain;
}

export interface AssetAgain {
  id: string;
  media: string | null;
  resizedURL: string | null;
  originalURL: string | null;
  videoPlaybackId: string;
  mimeType: string;
}

export interface GetPostResult {
  getOnePost?: {
    id: string;
    deletedAt: Date | null;
    comments: Comment[];
    postAssets: PostAssets[];
  };
}
