import { getBlastsActions } from '@/server-actions';
import { ImageModal, Thumbnail } from '@fanfix/design-system';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import MuxPlayer from '@mux/mux-player-react';
import { useEffect, useState } from 'react';
import {
  Button,
  Datagrid,
  DateField,
  FunctionField,
  List,
  Pagination,
  TextField,
  TextInput,
  UseRecordContextParams,
  useListContext,
  useListController,
  useNotify,
  useRefresh
} from 'react-admin';
import 'swiper/css';
import 'swiper/css/keyboard';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const QuickFilter = ({
  label
}: {
  label: string;
  source?: string;
  defaultValue?: string;
}) => {
  return <Chip sx={{ marginBottom: 1 }} label={label} />;
};

const filters = [
  <TextInput key="1" label="Search by Username" source="query" alwaysOn />,

  <QuickFilter
    key="2"
    source="deletedAt"
    label="Status: Deleted"
    defaultValue={'true'}
  />
];

const BlastPagination = () => (
  <div style={{ width: '100%', display: 'flex' }}>
    <Pagination rowsPerPageOptions={[10, 25, 50]} />
  </div>
);

const blastRowStyle = (record: { nb_views: number }, index: any) => ({
  backgroundColor: index % 2 === 0 ? '#EEE' : 'white'
});

export const BlastsList = (props: UseRecordContextParams<Blast>) => {
  const listController = useListController();
  const listContext = useListContext(listController);
  const notify = useNotify();
  const refresh = useRefresh();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null);
  const { deleteMessageBlast } = getBlastsActions();

  const onMediaClick = (index: number, asset: Asset, record: Blast) => {
    if (record.deletedAt) {
      return;
    }
    setSelectedAsset(asset);
    setIsModalOpen(true);
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    const fresh = currentUrl.split('/').pop();

    if (fresh === 'blasts') {
      listContext.setFilters({}, []);
      // set the url to the current url without the query string
      window.history.replaceState({}, '', currentUrl.split('?')[0]);
    }
  }, [listContext, listContext.filterValues]);

  const media = (asset: Asset, record: Blast) => {
    if (record.assets.length > 0) {
      return (
        <Grid
          container
          spacing={1}
          columns={{ xs: 3, sm: 3, md: 3 }}
          sx={{
            marginTop: 1
          }}
        >
          {record.assets.map((asset, i) => (
            <Grid
              item
              key={i}
              xs={1}
              sm={1}
              md={1}
              sx={{
                aspectRatio: '1/1',
                minWidth: '50px',
                cursor: 'pointer'
              }}
              onClick={() => onMediaClick(i, asset, record)}
            >
              <Thumbnail
                key={i}
                type={asset.videoPlaybackId ? 'video' : 'image'}
                src={
                  record.deletedAt
                    ? (asset.blurredURL ?? '')
                    : (asset.resizedURL ?? '')
                }
              />
            </Grid>
          ))}
          <ImageModal
            open={isModalOpen}
            onClose={() => {
              setIsModalOpen(false);
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '20px'
              }}
            >
              {selectedAsset?.videoPlaybackId && (
                <MuxPlayer
                  playbackId={selectedAsset.videoPlaybackId}
                  style={{
                    maxWidth: '250px',
                    height: 'auto',
                    marginBottom: '10px'
                  }}
                  loop
                />
              )}
              {!selectedAsset?.videoPlaybackId && (
                <img src={selectedAsset?.resizedURL || ''} alt="media" />
              )}
            </Box>
          </ImageModal>
        </Grid>
      );
    } else {
      return <p>No media found</p>;
    }
  };

  const deleteBlastHandler = async (record: Blast) => {
    await deleteMessageBlast({ creatorId: record.creatorId, id: record.id });
    refresh();
  };

  return (
    <List
      filter={{ withDeleted: true }}
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={filters}
      pagination={<BlastPagination />}
    >
      <Datagrid
        rowStyle={blastRowStyle}
        bulkActionButtons={false}
        sx={{
          '& .RaDatagrid-headerCell': {
            fontSize: '1rem',
            fontWeight: 800
          }
        }}
      >
        <FunctionField
          label="Deleted"
          render={(record: Blast) => (
            <Button
              disabled={Boolean(record.deletedAt)}
              variant="contained"
              color="error"
              onClick={() => deleteBlastHandler(record)}
              style={{ marginRight: '5px', marginLeft: '5px' }}
            >
              <p>Delete</p>
            </Button>
          )}
        />
        <TextField label="Creator Name" source="creatorUsername" />
        <FunctionField
          label="Media"
          render={(record: Blast) =>
            record.assets[0] ? (
              media(record.assets[0], record)
            ) : (
              <p>No media found</p>
            )
          }
        />
        <FunctionField
          label="Text"
          source="text"
          render={(record: Blast) => (
            <div
              style={{
                whiteSpace: 'normal',
                wordBreak: 'break-word'
              }}
              dangerouslySetInnerHTML={{ __html: record.text as string }}
            />
          )}
        />
        <TextField label="ID" source="id" />
        <DateField source="createdAt" />
        <DateField source="deletedAt" />
      </Datagrid>
    </List>
  );
};

export interface Blast {
  id: string;
  type: string | null;
  media: string;
  username: string | null;
  createdAt: Date;
  text: string | null;
  assets: Asset[];
  deletedAt: Date | null;
  creatorId: string;
}

export interface Asset {
  id: string;
  media: string | null;
  createdAt: Date;
  deletedAt: Date | null;
  resizedURL: string | null;
  blurredURL: string | null;
  videoPlaybackId: string;
  mimeType: string | null;
}
