import {
  AdminGetSubscriptionsOutput,
  AdminSubscriptionDto,
  MutationAdminCancelSubscriptionArgs,
  SubscriptionCancellationType
} from '@/gql/generated/graphql';
import { getSubscriptionsActions } from '@/server-actions';
import { convertTime } from '@/utils';
import { currencyFormatter } from '@fanfix/design-system';
import TocIcon from '@mui/icons-material/Toc';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Pagination from '@mui/material/Pagination';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Snackbar from '@mui/material/Snackbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const Subscriptions = () => {
  const { userId } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const initialPage = Number(query.get('page')) || 1;
  const isCreator = query.get('isCreator') === 'false' ? false : true;

  const cancellationTypes = [
    {
      id: 0,
      name: 'Cancel & refund subscription immediately',
      value: {
        cancellationType: SubscriptionCancellationType.Immediate,
        cancellationOptions: { refund: true }
      }
    },
    {
      id: 1,
      name: 'Cancel subscription immediately with no refund',
      value: {
        cancellationType: SubscriptionCancellationType.Immediate,
        cancellationOptions: { refund: false }
      }
    },
    {
      id: 2,
      name: 'Cancel subscription at the end of the billing cycle with no refund',
      value: {
        cancellationType: SubscriptionCancellationType.AtPeriodEnd,
        cancellationOptions: { refund: false }
      }
    }
  ];

  const [page, setPage] = useState(initialPage);
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);
  const { getSubscriptions, cancelSubscription } = getSubscriptionsActions();
  const [list, setList] = useState<AdminGetSubscriptionsOutput>();
  const [item, setItem] = useState<AdminSubscriptionDto>();
  const [selectedCancellationType, setSelectedCancellationType] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ content: '' });
  const [filteredUserId, setFilteredUserId] = useState('');

  const navigate = useNavigate();
  const fetchCreatorAdminSubscriptions = async (filteredUserId?: string) => {
    try {
      setLoading(true);
      const filter = isCreator
        ? { creatorId: userId, fanId: filteredUserId || null }
        : { fanId: userId, creatorId: filteredUserId || null };
      const data = await getSubscriptions({
        input: {
          filter,
          pagination: { limit: 10, offset: (page - 1) * 10 }
        }
      });
      if (data.adminGetSubscriptions) {
        setList(data.adminGetSubscriptions as AdminGetSubscriptionsOutput);
      }
    } catch (err) {
      throw new Error(err?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = async () => {
    if (item) {
      const payload: MutationAdminCancelSubscriptionArgs = {
        input: {
          creatorId: isCreator ? (userId as string) : item.creatorId,
          fanId: isCreator ? item.fanId : (userId as string),
          ...cancellationTypes[selectedCancellationType].value
        }
      };

      try {
        const { adminCancelSubscription } = await cancelSubscription(payload);
        setList((prev) =>
          prev
            ? {
                ...prev,
                subscriptions: prev?.subscriptions.map((sub) =>
                  item.id === sub.id
                    ? {
                        ...sub,
                        status: adminCancelSubscription.status,
                        endDate: new Date()
                      }
                    : sub
                )
              }
            : prev
        );
        handleCloseConfirm();
      } catch (e) {
        setErrorMessage({ content: 'Failed to cancel subscription' });
        throw new Error(`Failed to cancel subscription: ${e}`);
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    navigate(`?page=${value}&isCreator=${isCreator}`);
  };

  const handleCloseConfirm = () => {
    setConfirmIsOpen(false);
  };

  useEffect(() => {
    if (userId) {
      fetchCreatorAdminSubscriptions();
    }
  }, [userId, page]);

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding={10}
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="flex-end" mb={1}>
        <TextField
          value={filteredUserId}
          helperText={false}
          label={isCreator ? 'Fan ID' : 'Creator ID'}
          onChange={(e) => {
            setFilteredUserId(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              fetchCreatorAdminSubscriptions(filteredUserId);
            }
          }}
          sx={{ minWidth: '200px', marginRight: '10px' }}
        />

        <Box component="span" mr={2} mb={1.5}>
          <Button
            variant="outlined"
            color="primary"
            type="submit"
            onClick={() => fetchCreatorAdminSubscriptions(filteredUserId)}
          >
            Filter
          </Button>
        </Box>

        <Box component="span" mr={2} mb={1.5}>
          <Button
            variant="outlined"
            color="primary"
            type="submit"
            onClick={() => {
              setFilteredUserId('');
              fetchCreatorAdminSubscriptions();
            }}
          >
            Clear
          </Button>
        </Box>
      </Box>

      {list && list.totalCount > 0 ? (
        <Box
          sx={{
            maxHeight: '100%',
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  {isCreator ? (
                    <TableCell>Fan</TableCell>
                  ) : (
                    <TableCell>Creator</TableCell>
                  )}
                  <TableCell>Months</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.subscriptions.map((subscription) => (
                  <TableRow key={subscription.id}>
                    <TableCell>{subscription.id}</TableCell>
                    {isCreator ? (
                      <TableCell>{subscription.fanId}</TableCell>
                    ) : (
                      <TableCell>{subscription.creatorId}</TableCell>
                    )}
                    <TableCell>{subscription.months}</TableCell>
                    <TableCell>
                      {currencyFormatter.formatCentsToDollar(
                        subscription.price
                      )}
                    </TableCell>
                    <TableCell>{subscription.status}</TableCell>
                    <TableCell>
                      {convertTime(subscription.createdAt, false, true)}
                    </TableCell>
                    <TableCell>
                      {subscription.endDate
                        ? convertTime(subscription.endDate, false, true)
                        : null}
                    </TableCell>
                    <TableCell>
                      <Button
                        disabled={
                          !['active', 'pending'].includes(subscription.status)
                        }
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          setItem(subscription);
                          setConfirmIsOpen(true);
                        }}
                      >
                        cancel
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            sx={{ padding: '10px' }}
            page={page}
            count={Math.ceil(list?.totalCount / 10)}
            onChange={handleChange}
          />
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          padding={10}
          justifyContent="center"
          style={{ opacity: 0.5 }}
        >
          <TocIcon style={{ fontSize: '100px' }} />
          <Typography variant="h4">
            You don&apos;t have subscriptions yet
          </Typography>
        </Box>
      )}

      <Dialog open={confirmIsOpen} onClose={handleCloseConfirm}>
        <DialogTitle>Confirm cancel subscription</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to cancel this subscription ?
          </Typography>
          <FormControl>
            <RadioGroup>
              {cancellationTypes.map((type) => (
                <FormControlLabel
                  key={type.id}
                  control={
                    <Radio
                      checked={selectedCancellationType === type.id}
                      onChange={() => {
                        setSelectedCancellationType(type.id);
                      }}
                    />
                  }
                  label={type.name}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm}>Cancel</Button>
          <Button onClick={handleCancel} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={Boolean(errorMessage.content)}
        autoHideDuration={10000}
        onClose={() => setErrorMessage({ content: '' })}
      >
        <Alert
          onClose={() => setErrorMessage({ content: '' })}
          severity="error"
          sx={{ width: '100%' }}
        >
          {errorMessage.content}
        </Alert>
      </Snackbar>
    </>
  );
};
export default Subscriptions;
