import {
  AdminCreateCreatorProfileMutationVariables,
  AdminGetPaymentsQueryVariables,
  AdminRestoreUserMutationVariables,
  AdminSoftDeleteUserCommentsMutationVariables,
  AdminSoftDeleteUserMutationVariables,
  AdminUpdateUserMutationVariables,
  AdminUpdateUserProfileMutationVariables,
  GetAdminUserQueryVariables,
  GetAdminUsersQueryVariables
} from '@/gql/generated/graphql';
import {
  createCreatorProfileMutation,
  getFanPaymentsQuery,
  getUserForUsersDataProviderQuery,
  getUsersForUsersDataProviderQuery,
  restoreUserMutation,
  softDeleteUserCommentsMutation,
  softDeleteUserMutation,
  updateUserMutation,
  updateUserProfileAdminMutation
} from './users.server';
import clientWrapper from './wrappers/client.wrapper';

export const getUsersActions = () => {
  const getUsersForUsersDataProvider = (
    variables: GetAdminUsersQueryVariables
  ) => {
    return getUsersForUsersDataProviderQuery(variables);
  };

  const getUserForUsersDataProvider = (
    variables: GetAdminUserQueryVariables
  ) => {
    return getUserForUsersDataProviderQuery(variables);
  };

  const softDeleteUser = (variables: AdminSoftDeleteUserMutationVariables) => {
    return softDeleteUserMutation(variables);
  };

  const restoreUser = (variables: AdminRestoreUserMutationVariables) => {
    return restoreUserMutation(variables);
  };

  const softDeleteUserComments = (
    variables: AdminSoftDeleteUserCommentsMutationVariables
  ) => {
    return softDeleteUserCommentsMutation(variables);
  };

  const createCreatorProfile = (
    variables: AdminCreateCreatorProfileMutationVariables
  ) => {
    return createCreatorProfileMutation(variables);
  };

  const updateUserProfileAdmin = (
    variables: AdminUpdateUserProfileMutationVariables
  ) => {
    return updateUserProfileAdminMutation(variables);
  };

  const updateUser = (variables: AdminUpdateUserMutationVariables) => {
    return updateUserMutation(variables);
  };

  const getFanPayments = (variables: AdminGetPaymentsQueryVariables) => {
    return getFanPaymentsQuery(variables);
  };

  return {
    getUsersForUsersDataProvider: clientWrapper(getUsersForUsersDataProvider),
    getUserForUsersDataProvider: clientWrapper(getUserForUsersDataProvider),
    softDeleteUser: clientWrapper(softDeleteUser),
    restoreUser: clientWrapper(restoreUser),
    softDeleteUserComments: clientWrapper(softDeleteUserComments),
    createCreatorProfile: clientWrapper(createCreatorProfile),
    updateUserProfileAdmin: clientWrapper(updateUserProfileAdmin),
    updateUser: clientWrapper(updateUser),
    getFanPayments: clientWrapper(getFanPayments)
  };
};
