import {
  CreateAdminNoteMutationVariables,
  DeleteAdminNotesMutationVariables,
  UpdateNoteMutationVariables
} from '@/gql/generated/graphql';
import {
  createNoteMutation,
  deleteNoteMutation,
  getNotesQuery,
  updateNoteMutation
} from './notes.server';
import clientWrapper from './wrappers/client.wrapper';

export const getNotesActions = () => {
  const getNotes = (variables: { input: { userId: string } }) => {
    return getNotesQuery(variables);
  };

  const createNote = (variables: CreateAdminNoteMutationVariables) => {
    return createNoteMutation(variables);
  };

  const updateNote = (variables: UpdateNoteMutationVariables) => {
    return updateNoteMutation(variables);
  };

  const deleteNote = (variables: DeleteAdminNotesMutationVariables) => {
    return deleteNoteMutation(variables);
  };

  return {
    getNotes: clientWrapper(getNotes),
    createNote: clientWrapper(createNote),
    updateNote: clientWrapper(updateNote),
    deleteNote: clientWrapper(deleteNote)
  };
};
