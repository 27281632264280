/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any };
};

export enum ActivityType {
  Comment = 'COMMENT',
  Joined = 'JOINED',
  Kicked = 'KICKED',
  Left = 'LEFT',
  Tip = 'TIP'
}

export type AddFanToFanListInput = {
  fanId: Scalars['String']['input'];
  fanListId: Scalars['String']['input'];
};

export type AdminAdminNoteDto = {
  __typename?: 'AdminAdminNoteDto';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
};

export type AdminAssetDto = {
  __typename?: 'AdminAssetDto';
  assetOrder?: Maybe<Scalars['Float']['output']>;
  blurredURL?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  mimeType?: Maybe<Scalars['String']['output']>;
  resizedURL?: Maybe<Scalars['String']['output']>;
  videoPlaybackId?: Maybe<Scalars['String']['output']>;
};

export enum AdminBulkUpdateCategoriesAction {
  Add = 'ADD',
  Remove = 'REMOVE'
}

export type AdminBulkUpdateCategoriesInput = {
  action: AdminBulkUpdateCategoriesAction;
  categoryId: Scalars['String']['input'];
  creatorIds: Array<Scalars['String']['input']>;
};

export type AdminBulkUpdateCategoriesOutput = {
  __typename?: 'AdminBulkUpdateCategoriesOutput';
  totalCount: Scalars['Float']['output'];
  users: Array<UsersEntity>;
};

export type AdminBulkUpdatePostsInput = {
  posts: Array<AdminUpdatePostDto>;
};

export type AdminBulkUpdatePostsOutput = {
  __typename?: 'AdminBulkUpdatePostsOutput';
  posts: Array<PostsEntity>;
  totalCount: Scalars['Float']['output'];
};

export type AdminCancelSubscriptionInput = {
  cancellationOptions?: InputMaybe<SubscriptionCancellationOptions>;
  cancellationType: SubscriptionCancellationType;
  creatorId: Scalars['String']['input'];
  fanId: Scalars['String']['input'];
};

export type AdminCancelSubscriptionOutput = {
  __typename?: 'AdminCancelSubscriptionOutput';
  status: Scalars['String']['output'];
  subscriptionId: Scalars['String']['output'];
};

export type AdminCategoryDto = {
  __typename?: 'AdminCategoryDto';
  createdAt: Scalars['DateTime']['output'];
  creatorProfiles: Array<AdminCreatorProfileDto>;
  displayOrder?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  imageURL: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AdminCommentDto = {
  __typename?: 'AdminCommentDto';
  author: AdminUserProfileDto;
  comment: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
};

export type AdminCreateAdminNoteInput = {
  content: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type AdminCreateCategoryInput = {
  displayOrder?: InputMaybe<Scalars['Float']['input']>;
  imageURL: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
};

export type AdminCreateCreatorProfileOutput = {
  __typename?: 'AdminCreateCreatorProfileOutput';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  allowsComments: Scalars['Boolean']['output'];
  allowsMessaging: Scalars['Boolean']['output'];
  appliedAt?: Maybe<Scalars['DateTime']['output']>;
  foreignAccount: Scalars['Boolean']['output'];
  freeMediaTipEnabled: Scalars['Boolean']['output'];
  freeMessageTipEnabled: Scalars['Boolean']['output'];
  isFeatured: Scalars['Boolean']['output'];
  minimumMediaTip: Scalars['Float']['output'];
  minimumMessageTip: Scalars['Float']['output'];
  personaStatus: PersonaStatus;
  rejectedAt?: Maybe<Scalars['DateTime']['output']>;
  socialAccounts?: Maybe<AdminSocailAccountDto>;
  userId: Scalars['String']['output'];
};

export type AdminCreatorProfileDto = {
  __typename?: 'AdminCreatorProfileDto';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  allowsComments: Scalars['Boolean']['output'];
  allowsMessaging: Scalars['Boolean']['output'];
  appliedAt?: Maybe<Scalars['DateTime']['output']>;
  foreignAccount: Scalars['Boolean']['output'];
  freeMediaTipEnabled: Scalars['Boolean']['output'];
  freeMessageTipEnabled: Scalars['Boolean']['output'];
  isFeatured: Scalars['Boolean']['output'];
  minimumMediaTip: Scalars['Float']['output'];
  minimumMessageTip: Scalars['Float']['output'];
  personaStatus: PersonaStatus;
  rejectedAt?: Maybe<Scalars['DateTime']['output']>;
  socialAccounts?: Maybe<AdminSocailAccountDto>;
  userId: Scalars['String']['output'];
};

export type AdminDeleteAdminNoteInput = {
  id: Scalars['String']['input'];
};

export type AdminEndLivestreamInput = {
  creatorId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type AdminEndLivestreamOutput = {
  __typename?: 'AdminEndLivestreamOutput';
  allowsComments: Scalars['Boolean']['output'];
  allowsTips: Scalars['Boolean']['output'];
  audienceType: AudienceType;
  createdAt: Scalars['DateTime']['output'];
  creatorId: Scalars['String']['output'];
  creatorUserProfile: UserProfilesEntity;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  minimumCommentTipAmountInCents: Scalars['Float']['output'];
  roomId: Scalars['String']['output'];
  sessionId: Scalars['String']['output'];
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  totalTipAmountInCents?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type AdminGetAdminNotesInput = {
  userId: Scalars['String']['input'];
};

export type AdminGetLivestreamOutput = {
  __typename?: 'AdminGetLivestreamOutput';
  allowsComments: Scalars['Boolean']['output'];
  allowsTips: Scalars['Boolean']['output'];
  audienceType: AudienceType;
  createdAt: Scalars['DateTime']['output'];
  creatorId: Scalars['String']['output'];
  creatorUserProfile: UserProfilesEntity;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  minimumCommentTipAmountInCents: Scalars['Float']['output'];
  roomId: Scalars['String']['output'];
  sessionId: Scalars['String']['output'];
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  totalTipAmountInCents?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type AdminGetLivestreamsFilterInput = {
  audienceType?: InputMaybe<AudienceType>;
  creatorId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AdminGetLivestreamsInput = {
  filter?: InputMaybe<AdminGetLivestreamsFilterInput>;
  pagination: PaginationInput;
};

export type AdminGetLivestreamsOutput = {
  __typename?: 'AdminGetLivestreamsOutput';
  livestreams: Array<LivestreamDto>;
  totalCount: Scalars['Float']['output'];
};

export type AdminGetMessageBlastsFilterInput = {
  deletedOnly?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type AdminGetMessageBlastsInput = {
  filter?: InputMaybe<AdminGetMessageBlastsFilterInput>;
  pagination: PaginationInput;
};

export type AdminGetMessageBlastsOutput = {
  __typename?: 'AdminGetMessageBlastsOutput';
  messageBlasts: Array<AdminMessageBlastDto>;
  totalCount: Scalars['Float']['output'];
};

export type AdminGetPaymentsFilterInput = {
  creatorId?: InputMaybe<Scalars['String']['input']>;
  relatedEntityId?: InputMaybe<Scalars['String']['input']>;
  tipType?: InputMaybe<TipType>;
};

export type AdminGetPaymentsInput = {
  filter?: InputMaybe<AdminGetPaymentsFilterInput>;
  pagination: PaginationInput;
};

export type AdminGetPaymentsOutput = {
  __typename?: 'AdminGetPaymentsOutput';
  payments: Array<AdminPaymentDto>;
  totalCount: Scalars['Float']['output'];
};

export type AdminGetPostOutput = {
  __typename?: 'AdminGetPostOutput';
  author?: Maybe<AdminUserProfileDto>;
  commentCount: Scalars['Int']['output'];
  comments?: Maybe<Array<AdminCommentDto>>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  isExclusive: Scalars['Boolean']['output'];
  isPinned: Scalars['Boolean']['output'];
  likeCount: Scalars['Int']['output'];
  nonSubscriberPrice?: Maybe<Scalars['Int']['output']>;
  postAssets?: Maybe<Array<AdminPostsAssetDto>>;
  subscriberPrice?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  viewCount: Scalars['Int']['output'];
};

export type AdminGetPostsFilterInput = {
  isRecommended?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type AdminGetPostsInput = {
  filter?: InputMaybe<AdminGetPostsFilterInput>;
  pagination: PaginationInput;
};

export type AdminGetPostsOutput = {
  __typename?: 'AdminGetPostsOutput';
  posts: Array<PostsEntity>;
  totalCount: Scalars['Float']['output'];
};

export type AdminGetSubscriptionsFilterInput = {
  creatorId?: InputMaybe<Scalars['String']['input']>;
  fanId?: InputMaybe<Scalars['String']['input']>;
};

export type AdminGetSubscriptionsInput = {
  filter?: InputMaybe<AdminGetSubscriptionsFilterInput>;
  pagination: PaginationInput;
};

export type AdminGetSubscriptionsOutput = {
  __typename?: 'AdminGetSubscriptionsOutput';
  subscriptions: Array<AdminSubscriptionDto>;
  totalCount: Scalars['Float']['output'];
};

export type AdminGetUsersFilterInput = {
  query?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<UserRoles>>;
};

export type AdminGetUsersFilterOptionInput = {
  property: FilterProps;
  value?: InputMaybe<Scalars['String']['input']>;
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AdminGetUsersInput = {
  filter?: InputMaybe<AdminGetUsersFilterInput>;
  filters?: InputMaybe<Array<AdminGetUsersFilterOptionInput>>;
  pagination: PaginationInput;
};

export type AdminGetUsersOutput = {
  __typename?: 'AdminGetUsersOutput';
  totalCount: Scalars['Float']['output'];
  users: Array<AdminUserDto>;
};

export type AdminMessageBlastDto = {
  __typename?: 'AdminMessageBlastDto';
  assets: Array<AdminAssetDto>;
  createdAt: Scalars['DateTime']['output'];
  creatorId?: Maybe<Scalars['String']['output']>;
  creatorUsername?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  text?: Maybe<Scalars['String']['output']>;
};

export type AdminPaymentDto = {
  __typename?: 'AdminPaymentDto';
  amountInCents: Scalars['Float']['output'];
  applicationFeeAmountInCents?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creatorId: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  fanId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  paymentProcessor: PaymentProcessor;
  paymentProcessorTransactionId: Scalars['String']['output'];
  processingFeeAmountInCents?: Maybe<Scalars['Float']['output']>;
  relatedEntityId?: Maybe<Scalars['String']['output']>;
  salesTaxAmountInCents?: Maybe<Scalars['Float']['output']>;
  salesTaxBillingLocation?: Maybe<SalesTaxBillingLocation>;
  status: PaymentStatus;
  tipType: TipType;
  updatedAt: Scalars['DateTime']['output'];
};

export type AdminPostsAssetDto = {
  __typename?: 'AdminPostsAssetDto';
  asset: AdminAssetDto;
  order?: Maybe<Scalars['Float']['output']>;
};

export type AdminRefundPaymentOutput = {
  __typename?: 'AdminRefundPaymentOutput';
  payment: AdminPaymentDto;
  refundPayment: StripeRefundPaymentData;
};

export type AdminSocailAccountDto = {
  __typename?: 'AdminSocailAccountDto';
  facebook?: Maybe<Scalars['String']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  tiktok?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  youtube?: Maybe<Scalars['String']['output']>;
};

export type AdminSubscriptionDto = {
  __typename?: 'AdminSubscriptionDto';
  createdAt: Scalars['DateTime']['output'];
  creatorId: Scalars['String']['output'];
  creatorUserProfile?: Maybe<UserProfilesEntity>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  fanId: Scalars['String']['output'];
  fanNote?: Maybe<FanNotesEntity>;
  fanUserProfile?: Maybe<UserProfilesEntity>;
  id: Scalars['String']['output'];
  months: Scalars['Float']['output'];
  price: Scalars['Float']['output'];
  status: Scalars['String']['output'];
  syncedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AdminUpdateAdminNoteInput = {
  content: Scalars['String']['input'];
};

export type AdminUpdateCategoryInput = {
  displayOrder?: InputMaybe<Scalars['Float']['input']>;
  imageURL: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
};

export type AdminUpdateCreatorProfileInput = {
  acceptedAt?: InputMaybe<Scalars['DateTime']['input']>;
  foreignAccount?: InputMaybe<Scalars['Boolean']['input']>;
  rejectedAt?: InputMaybe<Scalars['DateTime']['input']>;
  socialAccounts?: InputMaybe<AdminUpdateSocialAccountInput>;
};

export type AdminUpdateCreatorProfileOutput = {
  __typename?: 'AdminUpdateCreatorProfileOutput';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  allowsComments: Scalars['Boolean']['output'];
  allowsMessaging: Scalars['Boolean']['output'];
  appliedAt?: Maybe<Scalars['DateTime']['output']>;
  foreignAccount: Scalars['Boolean']['output'];
  freeMediaTipEnabled: Scalars['Boolean']['output'];
  freeMessageTipEnabled: Scalars['Boolean']['output'];
  isFeatured: Scalars['Boolean']['output'];
  minimumMediaTip: Scalars['Float']['output'];
  minimumMessageTip: Scalars['Float']['output'];
  personaStatus: PersonaStatus;
  rejectedAt?: Maybe<Scalars['DateTime']['output']>;
  socialAccounts?: Maybe<AdminSocailAccountDto>;
  userId: Scalars['String']['output'];
};

export type AdminUpdateLivestreamInput = {
  allowsComments?: InputMaybe<Scalars['Boolean']['input']>;
  allowsTips?: InputMaybe<Scalars['Boolean']['input']>;
  creatorId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AdminUpdateLivestreamOutput = {
  __typename?: 'AdminUpdateLivestreamOutput';
  allowsComments: Scalars['Boolean']['output'];
  allowsTips: Scalars['Boolean']['output'];
  audienceType: AudienceType;
  createdAt: Scalars['DateTime']['output'];
  creatorId: Scalars['String']['output'];
  creatorUserProfile: UserProfilesEntity;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  minimumCommentTipAmountInCents: Scalars['Float']['output'];
  roomId: Scalars['String']['output'];
  sessionId: Scalars['String']['output'];
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  totalTipAmountInCents?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type AdminUpdatePostDto = {
  id: Scalars['String']['input'];
  recommendedOrder?: InputMaybe<Scalars['Float']['input']>;
};

export type AdminUpdateSocialAccountInput = {
  facebook?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  tiktok?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
};

export type AdminUpdateUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  roles?: InputMaybe<Array<UserRoles>>;
};

export type AdminUpdateUserProfileInput = {
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type AdminUserDto = {
  __typename?: 'AdminUserDto';
  createdAt: Scalars['DateTime']['output'];
  creatorProfile?: Maybe<AdminCreatorProfileDto>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** @deprecated Use roles instead */
  isAdmin: Scalars['Boolean']['output'];
  lastLoginAt?: Maybe<Scalars['DateTime']['output']>;
  lastSeenAt?: Maybe<Scalars['DateTime']['output']>;
  roles: Array<UserRoles>;
  statistics?: Maybe<AdminUserStatisticsDto>;
  userProfile?: Maybe<AdminUserProfileDto>;
};

export type AdminUserProfileDto = {
  __typename?: 'AdminUserProfileDto';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  bannerUrl?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  userId: Scalars['String']['output'];
  username: Scalars['String']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type AdminUserStatisticsDto = {
  __typename?: 'AdminUserStatisticsDto';
  postsCount: Scalars['Float']['output'];
  subscribersCount: Scalars['Float']['output'];
};

export type AgenciesEntity = {
  __typename?: 'AgenciesEntity';
  agents?: Maybe<Array<AgentProfilesEntity>>;
  createdAt: Scalars['DateTime']['output'];
  creatorProfile?: Maybe<CreatorProfilesEntity>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AgentProfilesEntity = {
  __typename?: 'AgentProfilesEntity';
  accessLevel: Scalars['String']['output'];
  agency: AgenciesEntity;
  createdAt: Scalars['DateTime']['output'];
  creatorProfile?: Maybe<CreatorProfilesEntity>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  role: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<UsersEntity>;
  userId: Scalars['String']['output'];
};

export enum AllowedOnboardingActions {
  BiometricConsentGivenAt = 'BIOMETRIC_CONSENT_GIVEN_AT'
}

export type ApplePayMerchantValidationOutput = {
  __typename?: 'ApplePayMerchantValidationOutput';
  jsonString: Scalars['String']['output'];
};

export type AssetsEntity = {
  __typename?: 'AssetsEntity';
  blurredURL?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creatorAssetVaults?: Maybe<Array<CreatorAssetVaultsEntity>>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  fanAssetVaults?: Maybe<Array<FanAssetVaultsEntity>>;
  id: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  originalURL: Scalars['String']['output'];
  owner?: Maybe<UserProfilesEntity>;
  postAssets?: Maybe<Array<PostAssetsEntity>>;
  resizedURL: Scalars['String']['output'];
  size: Scalars['Float']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  videoPlaybackId?: Maybe<Scalars['String']['output']>;
};

export type AudienceInsights = {
  __typename?: 'AudienceInsights';
  averageSessionTime: Scalars['Float']['output'];
  creatorProfileViews: Scalars['Float']['output'];
};

export enum AudienceType {
  Public = 'PUBLIC',
  Subscriber = 'SUBSCRIBER'
}

export type CancelSubscriptionOutput = {
  __typename?: 'CancelSubscriptionOutput';
  status: Scalars['String']['output'];
  subscriptionId: Scalars['String']['output'];
};

export type CategoriesEntity = {
  __typename?: 'CategoriesEntity';
  createdAt: Scalars['DateTime']['output'];
  displayOrder?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  imageURL: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ChangePasswordInput = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export type CommentLikesEntity = {
  __typename?: 'CommentLikesEntity';
  author: UserProfilesEntity;
  comment: CommentsEntity;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CommentReportsEntity = {
  __typename?: 'CommentReportsEntity';
  comment: CommentsEntity;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  reporter: UserProfilesEntity;
  resolvedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type CommentsEntity = {
  __typename?: 'CommentsEntity';
  author: UserProfilesEntity;
  comment: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  likeCount: Scalars['Float']['output'];
  likes?: Maybe<Array<CommentLikesEntity>>;
  parent?: Maybe<CommentsEntity>;
  post: PostsEntity;
  replies?: Maybe<Array<CommentsEntity>>;
  reports: Array<CommentReportsEntity>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ConfirmCardOutput = {
  __typename?: 'ConfirmCardOutput';
  status: Scalars['String']['output'];
};

export type ConfirmCreatorTipInput = {
  creatorId: Scalars['String']['input'];
  paymentId: Scalars['String']['input'];
};

export type ConfirmPostTipInput = {
  paymentId: Scalars['String']['input'];
  postId: Scalars['String']['input'];
};

export type ConfirmSendLivestreamCommentInput = {
  paymentId: Scalars['String']['input'];
};

export type ConfirmSendLivestreamTipInput = {
  paymentId: Scalars['String']['input'];
};

export type ConfirmSendMessageFromFanInput = {
  paymentId: Scalars['String']['input'];
  recipientUserId: Scalars['String']['input'];
  senderUserId: Scalars['String']['input'];
};

export type ConfirmSubscriptionInput = {
  creatorId: Scalars['String']['input'];
  location?: InputMaybe<LocationDto>;
  months?: InputMaybe<Scalars['Int']['input']>;
};

export type ConfirmSubscriptionOutput = {
  __typename?: 'ConfirmSubscriptionOutput';
  nextActionUrl?: Maybe<Scalars['String']['output']>;
  paymentStatus?: Maybe<Scalars['String']['output']>;
  processorSubscriptionId?: Maybe<Scalars['String']['output']>;
  subscriptionId: Scalars['String']['output'];
  subscriptionPlanId: Scalars['String']['output'];
  subscriptionStatus: Scalars['String']['output'];
};

export type ConfirmUnlockMessageInput = {
  messageId: Scalars['String']['input'];
  paymentId: Scalars['String']['input'];
};

export type ConfirmUnlockPostInput = {
  paymentId: Scalars['String']['input'];
  postId: Scalars['String']['input'];
};

export type CreateCommentInput = {
  comment: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['String']['input']>;
  postId: Scalars['String']['input'];
};

export type CreateCreatorTipInput = {
  amountInCents: Scalars['Float']['input'];
  creatorId: Scalars['String']['input'];
  location?: InputMaybe<LocationDto>;
};

export type CreateCreatorTipOutput = {
  __typename?: 'CreateCreatorTipOutput';
  nextActionUrl?: Maybe<Scalars['String']['output']>;
  paymentId?: Maybe<Scalars['String']['output']>;
  status: PaymentStatus;
};

export type CreateFanListInput = {
  minimumMessageTipInCents?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
};

export type CreateLivestreamInput = {
  allowsComments: Scalars['Boolean']['input'];
  allowsTips: Scalars['Boolean']['input'];
  audienceType: AudienceType;
  minimumCommentTipAmountInCents: Scalars['Float']['input'];
  title: Scalars['String']['input'];
};

export type CreateLivestreamOutput = {
  __typename?: 'CreateLivestreamOutput';
  accessToken: Scalars['String']['output'];
  isNew: Scalars['Boolean']['output'];
  livestream: LivestreamDto;
};

export type CreateNotificationInput = {
  message: Scalars['String']['input'];
  read: Scalars['Boolean']['input'];
};

export type CreateOrUpdateFanNoteInput = {
  fanId: Scalars['String']['input'];
  nickname?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrUpdateSubscriptionPlanInput = {
  amountInCents: Scalars['Float']['input'];
  username: Scalars['String']['input'];
};

export type CreateOrUpdateSubscriptionPlanOutput = {
  __typename?: 'CreateOrUpdateSubscriptionPlanOutput';
  amountInCents: Scalars['Float']['output'];
  creatorId: Scalars['String']['output'];
  /** @deprecated Remove this after nearest s-pmt release it is always true */
  isTierSubscriptionEnabled: Scalars['Boolean']['output'];
  username: Scalars['String']['output'];
};

export type CreatePostInput = {
  assets?: InputMaybe<Array<Scalars['String']['input']>>;
  isExclusive?: InputMaybe<Scalars['Boolean']['input']>;
  isFirstAssetPublic?: InputMaybe<Scalars['Boolean']['input']>;
  nonSubscriberPrice?: InputMaybe<Scalars['Float']['input']>;
  scheduledFor?: InputMaybe<Scalars['DateTime']['input']>;
  subscriberPrice?: InputMaybe<Scalars['Float']['input']>;
  title: Scalars['String']['input'];
};

export type CreatePostOutput = {
  __typename?: 'CreatePostOutput';
  allowsComments: Scalars['Boolean']['output'];
  assets?: Maybe<Array<PostAssetDto>>;
  author?: Maybe<UserProfilesEntity>;
  commentCount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  creatorLastSeenAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  isCreatorOnline: Scalars['Boolean']['output'];
  isCreatorProfileBlocked: Scalars['Boolean']['output'];
  isCreatorProfileSubscribed: Scalars['Boolean']['output'];
  isExclusive: Scalars['Boolean']['output'];
  isFirstAssetPublic: Scalars['Boolean']['output'];
  isLiked: Scalars['Boolean']['output'];
  isLocked: Scalars['Boolean']['output'];
  isPinned: Scalars['Boolean']['output'];
  isWatermarkEnabled: Scalars['Boolean']['output'];
  likeCount: Scalars['Float']['output'];
  nonSubscriberPrice?: Maybe<Scalars['Float']['output']>;
  paymentAmount?: Maybe<Scalars['Float']['output']>;
  postUnlock?: Maybe<PremiumPostUnlocksEntity>;
  scheduledFor?: Maybe<Scalars['DateTime']['output']>;
  status: PostStatus;
  subscriberPrice?: Maybe<Scalars['Float']['output']>;
  title: Scalars['String']['output'];
  unlockCount?: Maybe<Scalars['Int']['output']>;
  unlockPriceInCents?: Maybe<Scalars['Float']['output']>;
  unlockRevenueInCents?: Maybe<Scalars['Int']['output']>;
  unlockedAt?: Maybe<Scalars['DateTime']['output']>;
  viewCount: Scalars['Float']['output'];
};

export type CreatePostTipInput = {
  amountInCents: Scalars['Float']['input'];
  creatorId: Scalars['String']['input'];
  location?: InputMaybe<LocationDto>;
  postId: Scalars['String']['input'];
};

export type CreatePostTipOutputDto = {
  __typename?: 'CreatePostTipOutputDto';
  nextActionUrl?: Maybe<Scalars['String']['output']>;
  paymentId?: Maybe<Scalars['String']['output']>;
  status: PaymentStatus;
};

export type CreatePostV3Input = {
  isFirstAssetPublic: Scalars['Boolean']['input'];
  nonSubscriberPrice?: InputMaybe<Scalars['Float']['input']>;
  postAssets?: InputMaybe<Array<CreatePostV3PostAssetsInput>>;
  scheduledFor?: InputMaybe<Scalars['DateTime']['input']>;
  status: PostStatus;
  subscriberPrice: Scalars['Float']['input'];
  title: Scalars['String']['input'];
};

export type CreatePostV3Output = {
  __typename?: 'CreatePostV3Output';
  commentCount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isPinned: Scalars['Boolean']['output'];
  likeCount: Scalars['Float']['output'];
  nonSubscriberPrice?: Maybe<Scalars['Float']['output']>;
  postAssets?: Maybe<Array<PostAssetsEntity>>;
  scheduledFor?: Maybe<Scalars['DateTime']['output']>;
  status: PostStatus;
  subscriberPrice: Scalars['Float']['output'];
  title: Scalars['String']['output'];
  viewCount: Scalars['Float']['output'];
};

export type CreatePostV3PostAssetsInput = {
  assetId: Scalars['String']['input'];
  order: Scalars['Float']['input'];
};

export type CreateSubscriptionInput = {
  creatorId: Scalars['String']['input'];
  location?: InputMaybe<LocationDto>;
  months?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateSubscriptionOutput = {
  __typename?: 'CreateSubscriptionOutput';
  nextActionUrl?: Maybe<Scalars['String']['output']>;
  paymentStatus?: Maybe<Scalars['String']['output']>;
  processorSubscriptionId?: Maybe<Scalars['String']['output']>;
  subscriptionId: Scalars['String']['output'];
  subscriptionPlanId: Scalars['String']['output'];
  subscriptionStatus: Scalars['String']['output'];
};

export type CreateTagInput = {
  text: Scalars['String']['input'];
};

export type CreatorAnalytics = {
  __typename?: 'CreatorAnalytics';
  messageRevenue: Scalars['Float']['output'];
  messagesCount: Scalars['Float']['output'];
  postCount: Scalars['Float']['output'];
  subscribersCount: Scalars['Float']['output'];
  subscriptionRevenue: Scalars['Float']['output'];
  tipRevenue: Scalars['Float']['output'];
};

export type CreatorAssetVaultsEntity = {
  __typename?: 'CreatorAssetVaultsEntity';
  asset: AssetsEntity;
  createdAt: Scalars['DateTime']['output'];
  creatorProfile: CreatorProfilesEntity;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  tags: Array<TagsEntity>;
  updatedAt: Scalars['DateTime']['output'];
};

export type CreatorBlocksEntity = {
  __typename?: 'CreatorBlocksEntity';
  blockedUser: UserProfilesEntity;
  blockingCreator: CreatorProfilesEntity;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CreatorCategoryInput = {
  categoryId: Scalars['String']['input'];
};

export type CreatorDailyInsightDto = {
  __typename?: 'CreatorDailyInsightDto';
  creatorTipsRevenue: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  livestreamTipsRevenue: Scalars['Int']['output'];
  mediaUnlockTipsRevenue: Scalars['Int']['output'];
  messageTipsRevenue: Scalars['Int']['output'];
  postTipsRevenue: Scalars['Int']['output'];
  postUnlockTipsRevenue: Scalars['Int']['output'];
  subscriptionsRevenue: Scalars['Int']['output'];
  totalRevenue: Scalars['Int']['output'];
  totalTipsRevenue: Scalars['Int']['output'];
};

export type CreatorDashboardDto = {
  __typename?: 'CreatorDashboardDto';
  messageCount?: Maybe<Scalars['Int']['output']>;
  messageRevenue?: Maybe<Scalars['Int']['output']>;
  postCount?: Maybe<Scalars['Int']['output']>;
  postRevenue?: Maybe<Scalars['Int']['output']>;
  subscriberCount?: Maybe<Scalars['Int']['output']>;
  subscriptionRevenue?: Maybe<Scalars['Int']['output']>;
  tipRevenue?: Maybe<Scalars['Int']['output']>;
};

export type CreatorDashboardDtoV2 = {
  __typename?: 'CreatorDashboardDtoV2';
  creatorTipsRevenue?: Maybe<Scalars['Int']['output']>;
  livestreamTipsRevenue?: Maybe<Scalars['Int']['output']>;
  mediaUnlockTipsRevenue?: Maybe<Scalars['Int']['output']>;
  messageCount?: Maybe<Scalars['Int']['output']>;
  messageTipsRevenue?: Maybe<Scalars['Int']['output']>;
  postCount?: Maybe<Scalars['Int']['output']>;
  postTipsRevenue?: Maybe<Scalars['Int']['output']>;
  postUnlockTipsRevenue?: Maybe<Scalars['Int']['output']>;
  subscriberCount?: Maybe<Scalars['Int']['output']>;
  subscriptionsRevenue?: Maybe<Scalars['Int']['output']>;
};

export type CreatorFollowsEntity = {
  __typename?: 'CreatorFollowsEntity';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  followedCreator: CreatorProfilesEntity;
  followingUser: UserProfilesEntity;
  id: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CreatorPostsViewCountDto = {
  __typename?: 'CreatorPostsViewCountDto';
  postId: Scalars['String']['output'];
  viewCount: Scalars['Float']['output'];
};

export type CreatorProfileDto = {
  __typename?: 'CreatorProfileDto';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  agency?: Maybe<AgenciesEntity>;
  allowsComments: Scalars['Boolean']['output'];
  allowsMessaging: Scalars['Boolean']['output'];
  appliedAt?: Maybe<Scalars['DateTime']['output']>;
  blocked?: Maybe<Scalars['Boolean']['output']>;
  categories: Array<CategoriesEntity>;
  chatBubbleColor?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** @deprecated Use `categories` instead */
  creatorCategories?: Maybe<Array<CategoriesEntity>>;
  displayOnlineStatus: Scalars['Boolean']['output'];
  exclusivePostCount: Scalars['Int']['output'];
  fanLists: Array<FanListsEntity>;
  foreignAccount: Scalars['Boolean']['output'];
  freeMediaTipEnabled: Scalars['Boolean']['output'];
  freeMessageTipEnabled: Scalars['Boolean']['output'];
  hasTransferCapability?: Maybe<Scalars['Boolean']['output']>;
  isFeatured: Scalars['Boolean']['output'];
  isFollower?: Maybe<Scalars['Boolean']['output']>;
  isOnline: Scalars['Boolean']['output'];
  isWatermarkEnabled: Scalars['Boolean']['output'];
  lastSeenAt?: Maybe<Scalars['DateTime']['output']>;
  minimumMediaTip: Scalars['Float']['output'];
  minimumMessageTip: Scalars['Float']['output'];
  onboardingFlow?: Maybe<OnboardingFlowsDto>;
  personaStatus: PersonaStatus;
  postCount: Scalars['Int']['output'];
  prioritySortLevel?: Maybe<Scalars['Int']['output']>;
  publicPostCount?: Maybe<Scalars['Int']['output']>;
  rejectedAt?: Maybe<Scalars['DateTime']['output']>;
  socialAccounts?: Maybe<SocialAccountsEntity>;
  source?: Maybe<Scalars['String']['output']>;
  subscribed?: Maybe<Scalars['Boolean']['output']>;
  subscriptionPlan?: Maybe<GetSubscriptionPlan>;
  totalExclusivePost: Scalars['Float']['output'];
  totalPublicPost: Scalars['Float']['output'];
  underFollowersExperiment?: Maybe<Scalars['Boolean']['output']>;
  unreadChannelsCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: UsersEntity;
  userId: Scalars['String']['output'];
  userProfile?: Maybe<UserProfilesEntity>;
};

export type CreatorProfileFanDto = {
  __typename?: 'CreatorProfileFanDto';
  allowsComments?: Maybe<Scalars['Boolean']['output']>;
  allowsMessaging?: Maybe<Scalars['Boolean']['output']>;
  blocked?: Maybe<Scalars['Boolean']['output']>;
  categories?: Maybe<Array<CategoriesEntity>>;
  chatBubbleColor?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `categories` instead */
  creatorCategories?: Maybe<Array<CategoriesEntity>>;
  displayOnlineStatus?: Maybe<Scalars['Boolean']['output']>;
  exclusivePostCount?: Maybe<Scalars['Int']['output']>;
  freeMediaTipEnabled?: Maybe<Scalars['Boolean']['output']>;
  freeMessageTipEnabled?: Maybe<Scalars['Boolean']['output']>;
  hasTransferCapability?: Maybe<Scalars['Boolean']['output']>;
  isFeatured?: Maybe<Scalars['Boolean']['output']>;
  isFollower?: Maybe<Scalars['Boolean']['output']>;
  isOnline?: Maybe<Scalars['Boolean']['output']>;
  isWatermarkEnabled?: Maybe<Scalars['Boolean']['output']>;
  lastSeenAt?: Maybe<Scalars['DateTime']['output']>;
  minimumMediaTip?: Maybe<Scalars['Float']['output']>;
  minimumMessageTip?: Maybe<Scalars['Float']['output']>;
  onboardingFlow?: Maybe<OnboardingFlowsDto>;
  personaStatus?: Maybe<PersonaStatus>;
  postCount?: Maybe<Scalars['Float']['output']>;
  publicPostCount?: Maybe<Scalars['Int']['output']>;
  socialAccounts?: Maybe<SocialAccountsEntity>;
  subscribed?: Maybe<Scalars['Boolean']['output']>;
  subscriptionPlan?: Maybe<GetSubscriptionPlan>;
  totalExclusivePost?: Maybe<Scalars['Float']['output']>;
  totalPublicPost?: Maybe<Scalars['Float']['output']>;
  underFollowersExperiment?: Maybe<Scalars['Boolean']['output']>;
  unreadChannelsCount?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<UsersEntity>;
  userId?: Maybe<Scalars['String']['output']>;
  userProfile?: Maybe<UserProfilesEntity>;
};

export type CreatorProfilesEntity = {
  __typename?: 'CreatorProfilesEntity';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  agency?: Maybe<AgenciesEntity>;
  allowsComments: Scalars['Boolean']['output'];
  allowsMessaging: Scalars['Boolean']['output'];
  appliedAt?: Maybe<Scalars['DateTime']['output']>;
  categories: Array<CategoriesEntity>;
  chatBubbleColor?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  displayOnlineStatus: Scalars['Boolean']['output'];
  fanLists: Array<FanListsEntity>;
  foreignAccount: Scalars['Boolean']['output'];
  freeMediaTipEnabled: Scalars['Boolean']['output'];
  freeMessageTipEnabled: Scalars['Boolean']['output'];
  isFeatured: Scalars['Boolean']['output'];
  isWatermarkEnabled: Scalars['Boolean']['output'];
  minimumMediaTip: Scalars['Float']['output'];
  minimumMessageTip: Scalars['Float']['output'];
  onboardingFlow?: Maybe<OnboardingFlowsDto>;
  personaStatus: PersonaStatus;
  prioritySortLevel?: Maybe<Scalars['Int']['output']>;
  rejectedAt?: Maybe<Scalars['DateTime']['output']>;
  socialAccounts?: Maybe<SocialAccountsEntity>;
  source?: Maybe<Scalars['String']['output']>;
  totalExclusivePost: Scalars['Float']['output'];
  totalPublicPost: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: UsersEntity;
  userId: Scalars['String']['output'];
  userProfile?: Maybe<UserProfilesEntity>;
};

export type DeleteFanFromFanListInput = {
  fanId: Scalars['String']['input'];
  fanListId: Scalars['String']['input'];
};

export type DeleteFanListInput = {
  id: Scalars['String']['input'];
};

export type DeleteFanListOutput = {
  __typename?: 'DeleteFanListOutput';
  createdAt: Scalars['DateTime']['output'];
  creatorProfile: CreatorProfilesEntity;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  fanCount: Scalars['Float']['output'];
  fanToFanList?: Maybe<Array<FanListFansEntity>>;
  id: Scalars['String']['output'];
  minimumMessageTipInCents?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  systemListLabel?: Maybe<FanListSystemListLabel>;
  type: FanListType;
  updatedAt: Scalars['DateTime']['output'];
};

export type DeleteMessageDto = {
  __typename?: 'DeleteMessageDto';
  messageChannelId: Scalars['String']['output'];
  messageId: Scalars['String']['output'];
};

export type DeleteMessageInput = {
  messageId: Scalars['String']['input'];
};

export type EditFanListInput = {
  fanListId: Scalars['String']['input'];
  minimumMessageTipInCents?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
};

export type EditMessageBlastInput = {
  expiresAt?: InputMaybe<Scalars['String']['input']>;
  messageBlastId: Scalars['String']['input'];
  scheduledAt: Scalars['String']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
  unlockPriceInCents?: InputMaybe<Scalars['Float']['input']>;
};

export type EditMessageBlastOutput = {
  __typename?: 'EditMessageBlastOutput';
  messageBlastId: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type EstimateMessageBlastReachInput = {
  assetIdsToExcludeFans?: InputMaybe<Array<Scalars['String']['input']>>;
  excludeLists?: InputMaybe<Array<Scalars['String']['input']>>;
  includeGroups?: InputMaybe<Scalars['String']['input']>;
  includeLists?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EstimateMessageBlastReachOutput = {
  __typename?: 'EstimateMessageBlastReachOutput';
  estimatedReach: Scalars['Float']['output'];
};

export type EstimatePaymentInput = {
  amountInCents: Scalars['Float']['input'];
  creatorId?: InputMaybe<Scalars['String']['input']>;
  fanId: Scalars['String']['input'];
  location?: InputMaybe<LocationDto>;
  /** Tip type, one of Message,Media Unlock,Livestream,Livestream Comment,Creator Tip,Post,Post Unlock,Subscription Charge */
  tipType: Scalars['String']['input'];
};

export type EstimatePaymentOutput = {
  __typename?: 'EstimatePaymentOutput';
  applicationFeeAmountInCents: Scalars['Float']['output'];
  priceInCents: Scalars['Float']['output'];
  processingFeeAmountInCents: Scalars['Float']['output'];
  processingFeePercentage: Scalars['Float']['output'];
  taxAmountInCents: Scalars['Float']['output'];
  totalAmountInCents: Scalars['Float']['output'];
  totalApplicationFeeAmountInCents: Scalars['Float']['output'];
};

export type EstimateSubscriptionInput = {
  creatorId: Scalars['String']['input'];
  location?: InputMaybe<LocationDto>;
  months?: InputMaybe<Scalars['Int']['input']>;
};

export type EstimateSubscriptionOutput = {
  __typename?: 'EstimateSubscriptionOutput';
  processingFeeAmountInCents: Scalars['Float']['output'];
  processingFeePercentage: Scalars['Float']['output'];
  taxAmountInCents: Scalars['Float']['output'];
  totalAmountInCents: Scalars['Float']['output'];
};

export type FanAssetVaultsEntity = {
  __typename?: 'FanAssetVaultsEntity';
  asset: AssetsEntity;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  fan: UserProfilesEntity;
  id: Scalars['String']['output'];
  relatedEntityId?: Maybe<Scalars['String']['output']>;
  sourceType?: Maybe<Scalars['String']['output']>;
  totalPaid: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type FanListFansEntity = {
  __typename?: 'FanListFansEntity';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  fan: UserProfilesEntity;
  fanList: FanListsEntity;
  id: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum FanListSystemListLabel {
  Followers = 'FOLLOWERS',
  Subscribers = 'SUBSCRIBERS',
  TopSpenders = 'TOP_SPENDERS'
}

export enum FanListType {
  SystemEditable = 'SYSTEM_EDITABLE',
  SystemReadonly = 'SYSTEM_READONLY',
  UserEditable = 'USER_EDITABLE'
}

export type FanListsEntity = {
  __typename?: 'FanListsEntity';
  createdAt: Scalars['DateTime']['output'];
  creatorProfile: CreatorProfilesEntity;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  fanCount: Scalars['Float']['output'];
  fanToFanList?: Maybe<Array<FanListFansEntity>>;
  id: Scalars['String']['output'];
  minimumMessageTipInCents?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  systemListLabel?: Maybe<FanListSystemListLabel>;
  type: FanListType;
  updatedAt: Scalars['DateTime']['output'];
};

export type FanNotesEntity = {
  __typename?: 'FanNotesEntity';
  createdAt: Scalars['DateTime']['output'];
  creator: CreatorProfilesEntity;
  fan: UserProfilesEntity;
  id: Scalars['String']['output'];
  nickname?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type FanfixPriceDto = {
  applicationFeeAmountInCents: Scalars['Float']['input'];
  priceInCents: Scalars['Float']['input'];
  processingFeeAmountInCents: Scalars['Float']['input'];
  processingFeePercentage: Scalars['Float']['input'];
  taxAmountInCents: Scalars['Float']['input'];
  totalAmountInCents: Scalars['Float']['input'];
  totalApplicationFeeAmountInCents: Scalars['Float']['input'];
};

export enum FileType {
  Audio = 'Audio',
  Document = 'Document',
  Image = 'Image',
  Video = 'Video'
}

export type FilterInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<FileType>;
};

export enum FilterProps {
  Email = 'email',
  FirstName = 'firstName',
  FullName = 'fullName',
  Id = 'id',
  LastName = 'lastName',
  Roles = 'roles',
  Username = 'username'
}

export type GetActivityLogsFilterInput = {
  activityType?: InputMaybe<Array<ActivityType>>;
};

export type GetActivityLogsInput = {
  creatorId: Scalars['String']['input'];
  filter?: InputMaybe<GetActivityLogsFilterInput>;
  pagination: PaginationInput;
};

export type GetActivityLogsOutput = {
  __typename?: 'GetActivityLogsOutput';
  activityType: ActivityType;
  amountInCents?: Maybe<Scalars['String']['output']>;
  comment: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  isBlocked: Scalars['Boolean']['output'];
  isFlagged?: Maybe<Scalars['String']['output']>;
  paymentId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
  userProfile: UserProfilesEntity;
};

export type GetCardsOutput = {
  __typename?: 'GetCardsOutput';
  brand?: Maybe<Scalars['String']['output']>;
  expirationMonth: Scalars['Float']['output'];
  expirationYear: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  lastFour: Scalars['String']['output'];
  status: Scalars['String']['output'];
  walletType: Scalars['String']['output'];
};

export type GetChannelMessagesFilterInput = {
  onlyAssets?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetChannelMessagesInput = {
  channelId: Scalars['String']['input'];
  direction?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<GetChannelMessagesFilterInput>;
  messageId?: InputMaybe<Scalars['String']['input']>;
};

export type GetChannelMessagesOutput = {
  __typename?: 'GetChannelMessagesOutput';
  hasMore: Scalars['Boolean']['output'];
  messages: Array<MessageDto>;
};

export type GetChannelsFilterInput = {
  muted?: InputMaybe<Scalars['Boolean']['input']>;
  unreadsOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetChannelsInput = {
  filter?: InputMaybe<GetChannelsFilterInput>;
  pagination: GetChannelsPaginationInput;
};

export enum GetChannelsOrderBy {
  New = 'New',
  NotReplied = 'NotReplied',
  TipTotal = 'TipTotal'
}

export type GetChannelsOutput = {
  __typename?: 'GetChannelsOutput';
  messageChannels: Array<MessageChannelDto>;
  totalCount: Scalars['Int']['output'];
};

export type GetChannelsPaginationInput = {
  limit?: Scalars['Float']['input'];
  offset?: Scalars['Float']['input'];
  orderBy?: InputMaybe<GetChannelsOrderBy>;
};

export type GetCreatorAssetsInput = {
  filter: FilterInput;
  pagination: PaginationInput;
};

export type GetCreatorFanDetailsOutput = {
  __typename?: 'GetCreatorFanDetailsOutput';
  lifetimeRevenue: Scalars['Float']['output'];
  subscriptionCreatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type GetCreatorFanPurchasedAssetsFilterInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  sourceType?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<FileType>;
};

export type GetCreatorFanPurchasedAssetsInput = {
  filter?: InputMaybe<GetCreatorFanPurchasedAssetsFilterInput>;
  pagination: PaginationInput;
};

export type GetCreatorTagsOutput = {
  __typename?: 'GetCreatorTagsOutput';
  assetCount: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type GetFanListIdsByFanInput = {
  fanId: Scalars['String']['input'];
};

export type GetFanListIdsByFanOutput = {
  __typename?: 'GetFanListIdsByFanOutput';
  systemListLabels: Array<FanListSystemListLabel>;
  userEditableListIds: Array<Scalars['String']['output']>;
};

export type GetFanListInput = {
  pagination: PaginationInput;
};

export type GetFansByFanListInput = {
  fanListId: Scalars['String']['input'];
  pagination: RedisPaginationInput;
};

export type GetFansByFanListInputV2 = {
  fanListId: Scalars['String']['input'];
  pagination: PaginationInput;
};

export type GetFansByFanListOutput = {
  __typename?: 'GetFansByFanListOutput';
  cursor: Scalars['String']['output'];
  fans: Array<UserProfilesEntity>;
};

export type GetFansPurchasedEntityFilterInput = {
  fanId?: InputMaybe<Scalars['String']['input']>;
  relatedEntityId: Scalars['String']['input'];
  tipType?: InputMaybe<TipType>;
};

export type GetFansPurchasedEntityInput = {
  filter?: InputMaybe<GetFansPurchasedEntityFilterInput>;
  pagination: PaginationInput;
};

export type GetFansPurchasedEntityOutput = {
  __typename?: 'GetFansPurchasedEntityOutput';
  totalCount: Scalars['Float']['output'];
  users: Array<UsersEntity>;
};

export type GetLivestreamViewersListInput = {
  pagination: PaginationInput;
};

export type GetLivestreamViewersListOutput = {
  __typename?: 'GetLivestreamViewersListOutput';
  createdAt: Scalars['DateTime']['output'];
  isBlocked: Scalars['Boolean']['output'];
  totalAmountInCents: Scalars['Float']['output'];
  userProfile: UserProfilesEntity;
};

export type GetMessageBlastsV2FilterInput = {
  maxDate?: InputMaybe<Scalars['DateTime']['input']>;
  minDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type GetMessageBlastsV2Input = {
  filter?: InputMaybe<GetMessageBlastsV2FilterInput>;
  pagination: PaginationInput;
};

export type GetMinimumMessageTipInput = {
  creatorId: Scalars['String']['input'];
};

export type GetMoreToFollowCreatorsInput = {
  creatorId: Scalars['String']['input'];
};

export type GetPaymentsFilterInput = {
  creatorId?: InputMaybe<Scalars['String']['input']>;
  fanId?: InputMaybe<Scalars['String']['input']>;
  relatedEntityId?: InputMaybe<Scalars['String']['input']>;
  tipType?: InputMaybe<TipType>;
};

export type GetPaymentsInput = {
  filter?: InputMaybe<GetPaymentsFilterInput>;
  pagination: PaginationInput;
};

export type GetPaymentsOutput = {
  __typename?: 'GetPaymentsOutput';
  payments: Array<PaymentDto>;
  totalCount: Scalars['Float']['output'];
};

export type GetPayoutLinkInput = {
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type GetPayoutLinkOutput = {
  __typename?: 'GetPayoutLinkOutput';
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type GetPostDto = {
  __typename?: 'GetPostDto';
  allowsComments: Scalars['Boolean']['output'];
  assets?: Maybe<Array<PostAssetDto>>;
  author?: Maybe<UserProfilesEntity>;
  commentCount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  creatorLastSeenAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  isCreatorOnline: Scalars['Boolean']['output'];
  isCreatorProfileBlocked: Scalars['Boolean']['output'];
  isCreatorProfileSubscribed: Scalars['Boolean']['output'];
  isExclusive: Scalars['Boolean']['output'];
  isFirstAssetPublic: Scalars['Boolean']['output'];
  isLiked: Scalars['Boolean']['output'];
  isLocked: Scalars['Boolean']['output'];
  isPinned: Scalars['Boolean']['output'];
  isWatermarkEnabled: Scalars['Boolean']['output'];
  likeCount: Scalars['Float']['output'];
  nonSubscriberPrice?: Maybe<Scalars['Float']['output']>;
  paymentAmount?: Maybe<Scalars['Float']['output']>;
  postUnlock?: Maybe<PremiumPostUnlocksEntity>;
  scheduledFor?: Maybe<Scalars['DateTime']['output']>;
  status: PostStatus;
  subscriberPrice?: Maybe<Scalars['Float']['output']>;
  title: Scalars['String']['output'];
  unlockCount?: Maybe<Scalars['Int']['output']>;
  unlockPriceInCents?: Maybe<Scalars['Float']['output']>;
  unlockRevenueInCents?: Maybe<Scalars['Int']['output']>;
  unlockedAt?: Maybe<Scalars['DateTime']['output']>;
  viewCount: Scalars['Float']['output'];
};

export type GetPostFilterInput = {
  isFree?: InputMaybe<Scalars['Boolean']['input']>;
  isPurchased?: InputMaybe<Scalars['Boolean']['input']>;
  maxDate?: InputMaybe<Scalars['DateTime']['input']>;
  minDate?: InputMaybe<Scalars['DateTime']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type GetPostsInput = {
  filter?: InputMaybe<GetPostFilterInput>;
  pagination: PaginationInput;
};

export type GetRecommendedCreatorsFilterInput = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  underFollowersExperiment?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetRecommendedCreatorsInput = {
  filter?: InputMaybe<GetRecommendedCreatorsFilterInput>;
  pagination: PaginationInput;
};

export type GetSubscriptionPlan = {
  __typename?: 'GetSubscriptionPlan';
  amountInCents?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Remove this after nearest s-pmt release it is always true */
  isTierSubscriptionEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type GetSubscriptionPlanOutput = {
  __typename?: 'GetSubscriptionPlanOutput';
  amountInCents?: Maybe<Scalars['Float']['output']>;
  creatorId: Scalars['String']['output'];
  hasTransferCapability?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Remove this after nearest s-pmt release it is always true */
  isTierSubscriptionEnabled?: Maybe<Scalars['Boolean']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type GetSubscriptionsOutput = {
  __typename?: 'GetSubscriptionsOutput';
  subscriptions: Array<SubscriptionDto>;
  totalCount: Scalars['Float']['output'];
};

export type GreetingMessageAssetsEntity = {
  __typename?: 'GreetingMessageAssetsEntity';
  asset: AssetsEntity;
  assetOrder: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  greetingMessage: GreetingMessagesEntity;
  id: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GreetingMessagesEntity = {
  __typename?: 'GreetingMessagesEntity';
  createdAt: Scalars['DateTime']['output'];
  creatorProfile: CreatorProfilesEntity;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  greetingMessageAssets: Array<GreetingMessageAssetsEntity>;
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  isPreviewEnabled: Scalars['Boolean']['output'];
  text: Scalars['String']['output'];
  type: Scalars['String']['output'];
  unlockPriceInCents: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type JoinLivestreamOutput = {
  __typename?: 'JoinLivestreamOutput';
  accessToken: Scalars['String']['output'];
  fanId: Scalars['String']['output'];
  livestream: LivestreamDto;
};

export type KickUserInput = {
  fanId: Scalars['String']['input'];
  livestreamId: Scalars['String']['input'];
};

export type LikesEntity = {
  __typename?: 'LikesEntity';
  author: UserProfilesEntity;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  post: PostsEntity;
  updatedAt: Scalars['DateTime']['output'];
};

export type LivestreamActivityDto = {
  __typename?: 'LivestreamActivityDto';
  activityType: ActivityType;
  amountInCents?: Maybe<Scalars['String']['output']>;
  comment: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  isFlagged?: Maybe<Scalars['String']['output']>;
  paymentId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export type LivestreamDto = {
  __typename?: 'LivestreamDto';
  allowsComments: Scalars['Boolean']['output'];
  allowsTips: Scalars['Boolean']['output'];
  audienceType: AudienceType;
  createdAt: Scalars['DateTime']['output'];
  creatorId: Scalars['String']['output'];
  creatorUserProfile: UserProfilesEntity;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  minimumCommentTipAmountInCents: Scalars['Float']['output'];
  roomId: Scalars['String']['output'];
  sessionId: Scalars['String']['output'];
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  totalTipAmountInCents?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type LocationDto = {
  countryCode: Scalars['String']['input'];
  region?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ManageFanListsForFanInput = {
  excludeLists: Array<Scalars['String']['input']>;
  fanId: Scalars['String']['input'];
  includeLists: Array<Scalars['String']['input']>;
};

export type ManageFanListsForFanOutput = {
  __typename?: 'ManageFanListsForFanOutput';
  systemListLabels: Array<FanListSystemListLabel>;
  userEditableListIds: Array<Scalars['String']['output']>;
};

export type MessageAssetDto = {
  __typename?: 'MessageAssetDto';
  assetId: Scalars['String']['output'];
  assetOrder: Scalars['Float']['output'];
  blurredURL?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  originalURL?: Maybe<Scalars['String']['output']>;
  resizedURL?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  videoPlaybackId?: Maybe<Scalars['String']['output']>;
};

export type MessageBlastDto = {
  __typename?: 'MessageBlastDto';
  assets: Array<MessageAssetDto>;
  children?: Maybe<Array<MessageBlastDto>>;
  createdAt: Scalars['DateTime']['output'];
  creatorId: Scalars['String']['output'];
  creatorUsername?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  scheduledAt?: Maybe<Scalars['DateTime']['output']>;
  sentUserCount?: Maybe<Scalars['Int']['output']>;
  status: Scalars['String']['output'];
  text?: Maybe<Scalars['String']['output']>;
  unlockCount?: Maybe<Scalars['Int']['output']>;
  unlockPriceInCents?: Maybe<Scalars['Float']['output']>;
  unlockRevenueInCents?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type MessageChannelDto = {
  __typename?: 'MessageChannelDto';
  allowsMessaging: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  creatorLastSeenAt?: Maybe<Scalars['DateTime']['output']>;
  creatorLastSentAt?: Maybe<Scalars['DateTime']['output']>;
  creatorLeftAt?: Maybe<Scalars['DateTime']['output']>;
  creatorUserId: Scalars['String']['output'];
  creatorUserProfile?: Maybe<UserProfilesEntity>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  fanLastSeenAt?: Maybe<Scalars['DateTime']['output']>;
  fanLastSentAt?: Maybe<Scalars['DateTime']['output']>;
  fanLeftAt?: Maybe<Scalars['DateTime']['output']>;
  fanNote?: Maybe<FanNotesEntity>;
  fanUserId: Scalars['String']['output'];
  fanUserProfile?: Maybe<UserProfilesEntity>;
  /** @deprecated This field is not intended for Fan-facing APIs */
  freeMediaTipEnabled: Scalars['Boolean']['output'];
  /** @deprecated This field is not intended for Fan-facing APIs */
  freeMessageTipEnabled: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isBlocked: Scalars['Boolean']['output'];
  isOnline: Scalars['Boolean']['output'];
  isPriority: Scalars['Boolean']['output'];
  isUnread: Scalars['Boolean']['output'];
  lastMessage?: Maybe<MessageDto>;
  lastSeenAt?: Maybe<Scalars['DateTime']['output']>;
  minimumMediaTip: Scalars['Float']['output'];
  minimumMessageTip: Scalars['Float']['output'];
  syncedAt?: Maybe<Scalars['DateTime']['output']>;
  tipTotal: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type MessageDto = {
  __typename?: 'MessageDto';
  approved?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use messageAssets instead */
  assets?: Maybe<Array<MessageAssetDto>>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Remove it with next release */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  isPreviewEnabled?: Maybe<Scalars['Boolean']['output']>;
  isRead?: Maybe<Scalars['Boolean']['output']>;
  isWatermarkEnabled?: Maybe<Scalars['Boolean']['output']>;
  messageAssets?: Maybe<Array<MessageAssetDto>>;
  paymentAmount?: Maybe<Scalars['Float']['output']>;
  paymentId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Remove it with next release */
  recipientType?: Maybe<Scalars['String']['output']>;
  recipientUserId?: Maybe<Scalars['String']['output']>;
  sendbirdId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Remove it with next release */
  senderType?: Maybe<Scalars['String']['output']>;
  senderUserId: Scalars['String']['output'];
  unlockPriceInCents?: Maybe<Scalars['Float']['output']>;
  unlockedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type Messages = {
  __typename?: 'Messages';
  avatarUrl: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  message: Scalars['String']['output'];
  messageRevenue: Scalars['Float']['output'];
  username: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** @deprecated use adminBulkUpdateCreatorCategories instead */
  addCreatorCategory: CreatorProfilesEntity;
  /** @deprecated Use manageFanListsForFan instead */
  addFanToFanList: Scalars['Boolean']['output'];
  adminBulkUpdateCreatorCategories: AdminBulkUpdateCategoriesOutput;
  adminBulkUpdatePosts: AdminBulkUpdatePostsOutput;
  adminCancelSubscription: AdminCancelSubscriptionOutput;
  adminCreateAdminNote: AdminAdminNoteDto;
  adminCreateCategory: AdminCategoryDto;
  adminCreateCreatorProfile: AdminCreateCreatorProfileOutput;
  adminDeleteAdminNote: Scalars['Float']['output'];
  adminDeleteCategory: Scalars['Float']['output'];
  adminDeleteMessageBlast: Scalars['Boolean']['output'];
  adminEndLivestream: AdminEndLivestreamOutput;
  adminRestorePost: Scalars['Float']['output'];
  adminRestorePostComment: Scalars['Float']['output'];
  adminRestoreUser: Scalars['Float']['output'];
  adminSoftDeletePost: Scalars['Float']['output'];
  adminSoftDeletePostComment: Scalars['Float']['output'];
  adminSoftDeleteUser: Scalars['Float']['output'];
  adminSoftDeleteUserComments: Scalars['Float']['output'];
  adminUpdateAdminNote: AdminAdminNoteDto;
  adminUpdateCategory: AdminCategoryDto;
  adminUpdateCreatorProfile: AdminUpdateCreatorProfileOutput;
  adminUpdateLivestream: AdminUpdateLivestreamOutput;
  adminUpdateUser: AdminUserDto;
  adminUpdateUserProfile: AdminUserProfileDto;
  assignAgent: AgentProfilesEntity;
  blockUser: CreatorBlocksEntity;
  cancelSubscription: CancelSubscriptionOutput;
  changePassword: UsersEntity;
  closeLivestream: LivestreamDto;
  confirmCard: ConfirmCardOutput;
  confirmCreatorTip: CreateCreatorTipOutput;
  confirmPostTip: CreatePostTipOutputDto;
  confirmSendLivestreamComment: SendLivestreamCommentOutput;
  confirmSendLivestreamTip: SendLivestreamTipOutput;
  confirmSendMessageFromFan: SendMessageFromFanOutput;
  confirmSubscription: ConfirmSubscriptionOutput;
  confirmUnlockMessage: UnlockMessageDto;
  confirmUnlockPost: UnlockPostOutput;
  createAgency: AgenciesEntity;
  createAgentProfile: AgentProfilesEntity;
  createComment: CommentsEntity;
  createCommentV2: CommentsEntity;
  createCreatorTip: CreateCreatorTipOutput;
  createFanList: FanListsEntity;
  createLivestream: CreateLivestreamOutput;
  createNotification: NotificationsEntity;
  createOrUpdateFanNote: FanNotesEntity;
  createOrUpdateSubscriptionPlan: CreateOrUpdateSubscriptionPlanOutput;
  createPostTip: CreatePostTipOutputDto;
  /** @deprecated Use createPostV3 instead */
  createPostV2: CreatePostOutput;
  createPostV3: CreatePostV3Output;
  createSubscription: CreateSubscriptionOutput;
  createTag: TagsEntity;
  deleteAgency: AgenciesEntity;
  deleteCard: Scalars['Boolean']['output'];
  deleteComment: Scalars['Boolean']['output'];
  deleteCreatorAssets: Scalars['Boolean']['output'];
  /** @deprecated use adminBulkUpdateCreatorCategories instead */
  deleteCreatorCategory: Scalars['Boolean']['output'];
  /** @deprecated Use manageFanListsForFan instead */
  deleteFanFromFanList: Scalars['Boolean']['output'];
  /** @deprecated Use deleteFanListV2 instead */
  deleteFanList: Scalars['Boolean']['output'];
  deleteFanListV2: DeleteFanListOutput;
  deleteFanNote: Scalars['Boolean']['output'];
  deleteGreetingMessage: Scalars['Boolean']['output'];
  deleteLivestreamComment: LivestreamActivityDto;
  deleteMessage: DeleteMessageDto;
  deleteMessageBlast: Scalars['Boolean']['output'];
  deletePost: PostsEntity;
  editFanList: Scalars['Boolean']['output'];
  editMessageBlast: EditMessageBlastOutput;
  estimatePayment: EstimatePaymentOutput;
  estimateSubscription: EstimateSubscriptionOutput;
  followCreator: CreatorFollowsEntity;
  joinLivestream: JoinLivestreamOutput;
  kickUser: LivestreamActivityDto;
  leaveLivestream: LivestreamActivityDto;
  /** Like or unlink a comment. */
  likeComment: Scalars['Boolean']['output'];
  /** Like or unlink a post. */
  likePost: Scalars['Boolean']['output'];
  manageFanListsForFan: ManageFanListsForFanOutput;
  markNotificationAsRead: NotificationsEntity;
  /** Pin or unpin a post. */
  pinPost: PostsEntity;
  removeAgentProfile: Scalars['Boolean']['output'];
  reportComment: CommentReportsEntity;
  reportPost: PostReportsEntity;
  resendMessageBlast: ResendMessageBlastOutput;
  sendLivestreamComment: SendLivestreamCommentOutput;
  sendLivestreamTip: SendLivestreamTipOutput;
  /** @deprecated Use sendMessageBlastV2 instead. */
  sendMessageBlast: SendMessageBlastOutput;
  sendMessageBlastV2: SendMessageBlastOutput;
  sendMessageFromCreator: MessageDto;
  sendMessageFromFan: SendMessageFromFanOutput;
  unassignAgent: AgentProfilesEntity;
  unblockUser: Scalars['Boolean']['output'];
  unfollowCreator: Scalars['Boolean']['output'];
  unlockMessage: UnlockMessageDto;
  unlockPost: UnlockPostOutput;
  updateAgentProfile: AgentProfilesEntity;
  updateComment: CommentsEntity;
  updateCommentV2: CommentsEntity;
  updateCreatorAsset: AssetsEntity;
  updateCreatorAssetVault: CreatorAssetVaultsEntity;
  updateCreatorProfile: CreatorProfileDto;
  updateGreetingMessage: GreetingMessagesEntity;
  updateLivestream: UpdateLivestreamOutput;
  updateMessageChannelAsCreator: Scalars['Boolean']['output'];
  updateMessageChannelAsFan: Scalars['Boolean']['output'];
  updateOnboardingFlow: OnboardingFlowsDto;
  updatePost: PostsEntity;
  updateSocialAccount: SocialAccountsEntity;
  updateUser: UsersEntity;
  updateUserProfile: UserProfilesEntity;
};

export type MutationAddCreatorCategoryArgs = {
  input: CreatorCategoryInput;
};

export type MutationAddFanToFanListArgs = {
  input: AddFanToFanListInput;
};

export type MutationAdminBulkUpdateCreatorCategoriesArgs = {
  input: AdminBulkUpdateCategoriesInput;
};

export type MutationAdminBulkUpdatePostsArgs = {
  input: AdminBulkUpdatePostsInput;
};

export type MutationAdminCancelSubscriptionArgs = {
  input: AdminCancelSubscriptionInput;
};

export type MutationAdminCreateAdminNoteArgs = {
  input: AdminCreateAdminNoteInput;
};

export type MutationAdminCreateCategoryArgs = {
  input: AdminCreateCategoryInput;
};

export type MutationAdminCreateCreatorProfileArgs = {
  userId: Scalars['String']['input'];
};

export type MutationAdminDeleteAdminNoteArgs = {
  input: AdminDeleteAdminNoteInput;
};

export type MutationAdminDeleteCategoryArgs = {
  id: Scalars['String']['input'];
};

export type MutationAdminDeleteMessageBlastArgs = {
  creatorId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationAdminEndLivestreamArgs = {
  input: AdminEndLivestreamInput;
};

export type MutationAdminRestorePostArgs = {
  id: Scalars['String']['input'];
};

export type MutationAdminRestorePostCommentArgs = {
  id: Scalars['String']['input'];
  postId: Scalars['String']['input'];
};

export type MutationAdminRestoreUserArgs = {
  id: Scalars['String']['input'];
};

export type MutationAdminSoftDeletePostArgs = {
  id: Scalars['String']['input'];
};

export type MutationAdminSoftDeletePostCommentArgs = {
  id: Scalars['String']['input'];
  postId: Scalars['String']['input'];
};

export type MutationAdminSoftDeleteUserArgs = {
  id: Scalars['String']['input'];
};

export type MutationAdminSoftDeleteUserCommentsArgs = {
  id: Scalars['String']['input'];
};

export type MutationAdminUpdateAdminNoteArgs = {
  id: Scalars['String']['input'];
  input: AdminUpdateAdminNoteInput;
};

export type MutationAdminUpdateCategoryArgs = {
  id: Scalars['String']['input'];
  input: AdminUpdateCategoryInput;
};

export type MutationAdminUpdateCreatorProfileArgs = {
  input: AdminUpdateCreatorProfileInput;
  userId: Scalars['String']['input'];
};

export type MutationAdminUpdateLivestreamArgs = {
  input: AdminUpdateLivestreamInput;
};

export type MutationAdminUpdateUserArgs = {
  id: Scalars['String']['input'];
  input: AdminUpdateUserInput;
};

export type MutationAdminUpdateUserProfileArgs = {
  input: AdminUpdateUserProfileInput;
  userId: Scalars['String']['input'];
};

export type MutationAssignAgentArgs = {
  accessLevel: Scalars['String']['input'];
  email: Scalars['String']['input'];
  role: Scalars['String']['input'];
};

export type MutationBlockUserArgs = {
  userId: Scalars['String']['input'];
};

export type MutationCancelSubscriptionArgs = {
  creatorId: Scalars['String']['input'];
};

export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type MutationCloseLivestreamArgs = {
  id: Scalars['String']['input'];
};

export type MutationConfirmCardArgs = {
  cardId: Scalars['String']['input'];
};

export type MutationConfirmCreatorTipArgs = {
  input: ConfirmCreatorTipInput;
};

export type MutationConfirmPostTipArgs = {
  input: ConfirmPostTipInput;
};

export type MutationConfirmSendLivestreamCommentArgs = {
  id: Scalars['String']['input'];
  input: ConfirmSendLivestreamCommentInput;
};

export type MutationConfirmSendLivestreamTipArgs = {
  id: Scalars['String']['input'];
  input: ConfirmSendLivestreamTipInput;
};

export type MutationConfirmSendMessageFromFanArgs = {
  input: ConfirmSendMessageFromFanInput;
};

export type MutationConfirmSubscriptionArgs = {
  input: ConfirmSubscriptionInput;
};

export type MutationConfirmUnlockMessageArgs = {
  input: ConfirmUnlockMessageInput;
};

export type MutationConfirmUnlockPostArgs = {
  input: ConfirmUnlockPostInput;
};

export type MutationCreateAgencyArgs = {
  name: Scalars['String']['input'];
};

export type MutationCreateAgentProfileArgs = {
  agencyId: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type MutationCreateCommentArgs = {
  comment: Scalars['String']['input'];
  postId: Scalars['String']['input'];
  replyTo?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateCommentV2Args = {
  input: CreateCommentInput;
};

export type MutationCreateCreatorTipArgs = {
  input: CreateCreatorTipInput;
};

export type MutationCreateFanListArgs = {
  input: CreateFanListInput;
};

export type MutationCreateLivestreamArgs = {
  input: CreateLivestreamInput;
};

export type MutationCreateNotificationArgs = {
  input: CreateNotificationInput;
};

export type MutationCreateOrUpdateFanNoteArgs = {
  input: CreateOrUpdateFanNoteInput;
};

export type MutationCreateOrUpdateSubscriptionPlanArgs = {
  input: CreateOrUpdateSubscriptionPlanInput;
};

export type MutationCreatePostTipArgs = {
  input: CreatePostTipInput;
};

export type MutationCreatePostV2Args = {
  input: CreatePostInput;
};

export type MutationCreatePostV3Args = {
  input: CreatePostV3Input;
};

export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};

export type MutationCreateTagArgs = {
  input: CreateTagInput;
};

export type MutationDeleteCardArgs = {
  cardId: Scalars['String']['input'];
};

export type MutationDeleteCommentArgs = {
  commentId: Scalars['String']['input'];
};

export type MutationDeleteCreatorAssetsArgs = {
  assetIds: Array<Scalars['String']['input']>;
};

export type MutationDeleteCreatorCategoryArgs = {
  categoryId: Scalars['String']['input'];
};

export type MutationDeleteFanFromFanListArgs = {
  input: DeleteFanFromFanListInput;
};

export type MutationDeleteFanListArgs = {
  fanListId: Scalars['String']['input'];
};

export type MutationDeleteFanListV2Args = {
  input: DeleteFanListInput;
};

export type MutationDeleteFanNoteArgs = {
  fanId: Scalars['String']['input'];
};

export type MutationDeleteGreetingMessageArgs = {
  type: Scalars['String']['input'];
};

export type MutationDeleteLivestreamCommentArgs = {
  activityId: Scalars['String']['input'];
  livestreamId: Scalars['String']['input'];
};

export type MutationDeleteMessageArgs = {
  input: DeleteMessageInput;
};

export type MutationDeleteMessageBlastArgs = {
  messageBlastId: Scalars['String']['input'];
};

export type MutationDeletePostArgs = {
  postId: Scalars['String']['input'];
};

export type MutationEditFanListArgs = {
  input: EditFanListInput;
};

export type MutationEditMessageBlastArgs = {
  input: EditMessageBlastInput;
};

export type MutationEstimatePaymentArgs = {
  input: EstimatePaymentInput;
};

export type MutationEstimateSubscriptionArgs = {
  input: EstimateSubscriptionInput;
};

export type MutationFollowCreatorArgs = {
  creatorId: Scalars['String']['input'];
};

export type MutationJoinLivestreamArgs = {
  username: Scalars['String']['input'];
};

export type MutationKickUserArgs = {
  input: KickUserInput;
};

export type MutationLeaveLivestreamArgs = {
  id: Scalars['String']['input'];
};

export type MutationLikeCommentArgs = {
  commentId: Scalars['String']['input'];
};

export type MutationLikePostArgs = {
  postId: Scalars['String']['input'];
};

export type MutationManageFanListsForFanArgs = {
  input: ManageFanListsForFanInput;
};

export type MutationMarkNotificationAsReadArgs = {
  id: Scalars['String']['input'];
};

export type MutationPinPostArgs = {
  postId: Scalars['String']['input'];
};

export type MutationRemoveAgentProfileArgs = {
  agentId: Scalars['String']['input'];
};

export type MutationReportCommentArgs = {
  commentId: Scalars['String']['input'];
  description: Scalars['String']['input'];
};

export type MutationReportPostArgs = {
  description: Scalars['String']['input'];
  postId: Scalars['String']['input'];
};

export type MutationResendMessageBlastArgs = {
  input: ResendMessageBlastInput;
};

export type MutationSendLivestreamCommentArgs = {
  id: Scalars['String']['input'];
  input: SendLivestreamCommentInput;
};

export type MutationSendLivestreamTipArgs = {
  id: Scalars['String']['input'];
  input: SendLivestreamTipInput;
};

export type MutationSendMessageBlastArgs = {
  input: SendMessageBlastInput;
};

export type MutationSendMessageBlastV2Args = {
  input: SendMessageBlastInput;
};

export type MutationSendMessageFromCreatorArgs = {
  input: SendMessageFromCreatorInput;
};

export type MutationSendMessageFromFanArgs = {
  input: SendMessageFromFanInput;
};

export type MutationUnassignAgentArgs = {
  agentId: Scalars['String']['input'];
};

export type MutationUnblockUserArgs = {
  userId: Scalars['String']['input'];
};

export type MutationUnfollowCreatorArgs = {
  creatorId: Scalars['String']['input'];
};

export type MutationUnlockMessageArgs = {
  input: UnlockMessageInput;
};

export type MutationUnlockPostArgs = {
  input: UnlockPostInput;
};

export type MutationUpdateAgentProfileArgs = {
  accessLevel?: InputMaybe<Scalars['String']['input']>;
  agentId: Scalars['String']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateCommentArgs = {
  comment: Scalars['String']['input'];
  commentId: Scalars['String']['input'];
};

export type MutationUpdateCommentV2Args = {
  commentId: Scalars['String']['input'];
  input: UpdateCommentInput;
};

export type MutationUpdateCreatorAssetArgs = {
  assetId: Scalars['String']['input'];
  input: UpdateCreatorAssetInput;
};

export type MutationUpdateCreatorAssetVaultArgs = {
  creatorAssetVaultId: Scalars['String']['input'];
  input: UpdateCreatorAssetVaultInput;
};

export type MutationUpdateCreatorProfileArgs = {
  input: UpdateCreatorInput;
};

export type MutationUpdateGreetingMessageArgs = {
  input: UpdateGreetingMessageInput;
  type: Scalars['String']['input'];
};

export type MutationUpdateLivestreamArgs = {
  id: Scalars['String']['input'];
  input: UpdateLivestreamInput;
};

export type MutationUpdateMessageChannelAsCreatorArgs = {
  channelId: Scalars['String']['input'];
  input: UpdateMessageChannelAsCreatorInput;
};

export type MutationUpdateMessageChannelAsFanArgs = {
  channelId: Scalars['String']['input'];
  input: UpdateMessageChannelAsFanInput;
};

export type MutationUpdateOnboardingFlowArgs = {
  input: UpdateOnboardingFlowInput;
};

export type MutationUpdatePostArgs = {
  input: UpdatePostInput;
};

export type MutationUpdateSocialAccountArgs = {
  input: UpdateSocialAccountInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};

export type NotificationsEntity = {
  __typename?: 'NotificationsEntity';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  message: Scalars['String']['output'];
  read: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userProfile: UserProfilesEntity;
};

export type OnboardingFlowsDto = {
  __typename?: 'OnboardingFlowsDto';
  avatarUrlUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  bannerUrlUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  bioUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  biometricConsentGivenAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  followerMessageUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  identityVerifiedAt?: Maybe<Scalars['DateTime']['output']>;
  stripeConnectedAt?: Maybe<Scalars['DateTime']['output']>;
  subscriberMessageUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  subscriptionPriceUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export type PaginationInput = {
  limit?: Scalars['Float']['input'];
  offset?: Scalars['Float']['input'];
  order?: Scalars['String']['input'];
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentDto = {
  __typename?: 'PaymentDto';
  amountInCents: Scalars['Float']['output'];
  applicationFeeAmountInCents?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creatorId: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  fanId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  processingFeeAmountInCents?: Maybe<Scalars['Float']['output']>;
  relatedEntityId?: Maybe<Scalars['String']['output']>;
  salesTaxAmountInCents?: Maybe<Scalars['Float']['output']>;
  tipType: TipType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum PaymentProcessor {
  Authorizenet = 'AUTHORIZENET',
  Stripe = 'STRIPE',
  Stub = 'STUB'
}

export enum PaymentStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
  Succeeded = 'SUCCEEDED'
}

export enum PersonaStatus {
  Approved = 'APPROVED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Declined = 'DECLINED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Incomplete = 'INCOMPLETE',
  NeedsReview = 'NEEDS_REVIEW',
  Pending = 'PENDING'
}

export type PostAssetDto = {
  __typename?: 'PostAssetDto';
  assetId: Scalars['String']['output'];
  assetOrder: Scalars['Float']['output'];
  blurredURL?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isBlurred: Scalars['Boolean']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  originalURL?: Maybe<Scalars['String']['output']>;
  resizedURL?: Maybe<Scalars['String']['output']>;
  size: Scalars['Float']['output'];
  type: Scalars['String']['output'];
  videoPlaybackId?: Maybe<Scalars['String']['output']>;
};

export type PostAssetsEntity = {
  __typename?: 'PostAssetsEntity';
  asset: AssetsEntity;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  order: Scalars['Float']['output'];
  post: PostsEntity;
  updatedAt: Scalars['DateTime']['output'];
};

export type PostReportsEntity = {
  __typename?: 'PostReportsEntity';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  post: PostsEntity;
  reporter: UserProfilesEntity;
  resolvedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum PostStatus {
  Canceled = 'CANCELED',
  Deleted = 'DELETED',
  Failed = 'FAILED',
  Published = 'PUBLISHED',
  Queued = 'QUEUED',
  Scheduled = 'SCHEDULED'
}

export type PostsEntity = {
  __typename?: 'PostsEntity';
  author?: Maybe<UserProfilesEntity>;
  commentCount: Scalars['Float']['output'];
  comments?: Maybe<Array<CommentsEntity>>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  isExclusive: Scalars['Boolean']['output'];
  isFirstAssetPublic: Scalars['Boolean']['output'];
  isPinned: Scalars['Boolean']['output'];
  likeCount: Scalars['Float']['output'];
  likes?: Maybe<Array<LikesEntity>>;
  nonSubscriberPrice?: Maybe<Scalars['Int']['output']>;
  postAssets?: Maybe<Array<PostAssetsEntity>>;
  recommendedOrder?: Maybe<Scalars['Int']['output']>;
  reports?: Maybe<Array<PostReportsEntity>>;
  scheduledFor?: Maybe<Scalars['DateTime']['output']>;
  status: PostStatus;
  subscriberPrice: Scalars['Int']['output'];
  timelines?: Maybe<Array<UserTimelinePostsEntity>>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  viewCount: Scalars['Float']['output'];
};

export type PremiumPostUnlocksEntity = {
  __typename?: 'PremiumPostUnlocksEntity';
  amountInCents: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  fan: UserProfilesEntity;
  id: Scalars['String']['output'];
  paymentId: Scalars['String']['output'];
  post: PostsEntity;
  updatedAt: Scalars['DateTime']['output'];
};

export type Query = {
  __typename?: 'Query';
  adminGetAdminNotes: Array<AdminAdminNoteDto>;
  adminGetCategories: Array<AdminCategoryDto>;
  adminGetCategory: AdminCategoryDto;
  adminGetLivestream: AdminGetLivestreamOutput;
  adminGetLivestreams: AdminGetLivestreamsOutput;
  adminGetMessageBlasts: AdminGetMessageBlastsOutput;
  adminGetPayments: AdminGetPaymentsOutput;
  adminGetPost: AdminGetPostOutput;
  adminGetPosts: AdminGetPostsOutput;
  adminGetSubscriptions: AdminGetSubscriptionsOutput;
  adminGetUser: AdminUserDto;
  adminGetUsers: AdminGetUsersOutput;
  adminRefundPayment: AdminRefundPaymentOutput;
  applePayMerchantValidation: ApplePayMerchantValidationOutput;
  getActiveLivestreams: Array<LivestreamDto>;
  getActivityLogs: Array<GetActivityLogsOutput>;
  getAgents: Array<AgentProfilesEntity>;
  getAudienceInsights: AudienceInsights;
  getCards: Array<GetCardsOutput>;
  /** @deprecated use adminGetCategories instead */
  getCategories: Array<CategoriesEntity>;
  getChannelById: MessageChannelDto;
  /** @deprecated Use getChannelMessagesV2 instead */
  getChannelMessages: GetChannelMessagesOutput;
  getChannelMessagesV2: GetChannelMessagesOutput;
  /** @deprecated Use getChannelsV2 instead */
  getChannels: GetChannelsOutput;
  getChannelsV2: GetChannelsOutput;
  getCommentLikes: Array<CommentLikesEntity>;
  getComments: Array<CommentsEntity>;
  getCommentsAggregate: Scalars['Float']['output'];
  getCreatorAssets: Array<CreatorAssetVaultsEntity>;
  getCreatorDailyInsights: Array<CreatorDailyInsightDto>;
  /** @deprecated Use getCreatorDashboardV2 instead */
  getCreatorDashboard: CreatorDashboardDto;
  getCreatorDashboardV2: CreatorDashboardDtoV2;
  getCreatorFanDetails: GetCreatorFanDetailsOutput;
  getCreatorFansNotPurchasedAssets: Array<CreatorAssetVaultsEntity>;
  getCreatorFansPurchasedAssets: Array<FanAssetVaultsEntity>;
  getCreatorInsights: CreatorAnalytics;
  getCreatorPostViewCount: Scalars['Float']['output'];
  getCreatorPostsViewCount: Array<CreatorPostsViewCountDto>;
  getCreatorProfile: CreatorProfileDto;
  getCreatorTags: Array<GetCreatorTagsOutput>;
  getCreators: Array<CreatorProfileDto>;
  /** @deprecated Use getRecommendedCreators instead */
  getCreatorsForFans: Array<CreatorProfileFanDto>;
  /** @deprecated Use getEstimatedMessageBlastReachV2 instead. */
  getEstimatedMessageBlastReach: EstimateMessageBlastReachOutput;
  getEstimatedMessageBlastReachV2: EstimateMessageBlastReachOutput;
  getFanAssets: Array<FanAssetVaultsEntity>;
  getFanListIdsByFan: GetFanListIdsByFanOutput;
  /** @deprecated Use getFanListsV2 instead */
  getFanLists: Array<FanListsEntity>;
  getFanListsV2: Array<FanListsEntity>;
  getFanNote: FanNotesEntity;
  getFanProfile: UserProfilesDto;
  /** @deprecated Use getFansByFanListV2 instead */
  getFansByFanList: GetFansByFanListOutput;
  getFansByFanListV2: Array<UserProfilesEntity>;
  getFansPurchasedEntity: GetFansPurchasedEntityOutput;
  getGreetingMessages: Array<GreetingMessagesEntity>;
  getLatestMessages: Array<Messages>;
  getLatestNotifications: Array<NotificationsEntity>;
  getLatestPosts: Array<PostsEntity>;
  getLikes: Array<LikesEntity>;
  getLivestream: LivestreamDto;
  getLivestreamViewersList: Array<GetLivestreamViewersListOutput>;
  getMessageBlast: MessageBlastDto;
  /** @deprecated Use getMessageBlastsV2 instead. */
  getMessageBlasts: Array<MessageBlastDto>;
  getMessageBlastsV2: Array<MessageBlastDto>;
  /** @deprecated Use getMinimumMessagingTipV2 instead */
  getMinimumMessagingTip: Scalars['Float']['output'];
  getMinimumMessagingTipV2: Scalars['Float']['output'];
  getMoreToFollowCreators: Array<CreatorProfileDto>;
  getNewSubscribers: Array<Subscribers>;
  getNotifications: Array<NotificationsEntity>;
  getOrCreateChannel: MessageChannelDto;
  getPayments: GetPaymentsOutput;
  getPayoutLink: GetPayoutLinkOutput;
  getPost: GetPostDto;
  getPosts: Array<GetPostDto>;
  getPostsV2: Array<GetPostDto>;
  getPublicPostsV2: Array<GetPostDto>;
  getPublicProfile: CreatorProfileDto;
  getRecommendedCreators: Array<CreatorProfileFanDto>;
  getRecommendedPosts: Array<GetPostDto>;
  getSubscriptionPlan: GetSubscriptionPlanOutput;
  getSubscriptions: GetSubscriptionsOutput;
  getTimelinePosts: Array<GetPostDto>;
  getTopCountries: Array<TopCountriesData>;
  getTopPosts: Array<PostsEntity>;
  getTopSubscribers: Array<Subscribers>;
  getTotalChannelCountForCreator: Scalars['Int']['output'];
  getTotalRevenueForFan: Scalars['Float']['output'];
  getUserProfile: UserProfilesDto;
};

export type QueryAdminGetAdminNotesArgs = {
  input: AdminGetAdminNotesInput;
};

export type QueryAdminGetCategoryArgs = {
  categoryId: Scalars['String']['input'];
};

export type QueryAdminGetLivestreamArgs = {
  id: Scalars['String']['input'];
};

export type QueryAdminGetLivestreamsArgs = {
  input: AdminGetLivestreamsInput;
};

export type QueryAdminGetMessageBlastsArgs = {
  input: AdminGetMessageBlastsInput;
};

export type QueryAdminGetPaymentsArgs = {
  input: AdminGetPaymentsInput;
  userId: Scalars['String']['input'];
};

export type QueryAdminGetPostArgs = {
  id: Scalars['String']['input'];
};

export type QueryAdminGetPostsArgs = {
  input: AdminGetPostsInput;
};

export type QueryAdminGetSubscriptionsArgs = {
  input: AdminGetSubscriptionsInput;
};

export type QueryAdminGetUserArgs = {
  id: Scalars['String']['input'];
};

export type QueryAdminGetUsersArgs = {
  input: AdminGetUsersInput;
};

export type QueryAdminRefundPaymentArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetActivityLogsArgs = {
  input: GetActivityLogsInput;
  livestreamId: Scalars['String']['input'];
};

export type QueryGetAudienceInsightsArgs = {
  timeFrame: Scalars['String']['input'];
};

export type QueryGetChannelByIdArgs = {
  channelId: Scalars['String']['input'];
};

export type QueryGetChannelMessagesArgs = {
  channelId: Scalars['String']['input'];
  direction?: InputMaybe<Scalars['String']['input']>;
  messageId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetChannelMessagesV2Args = {
  input: GetChannelMessagesInput;
};

export type QueryGetChannelsArgs = {
  limit?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<GetChannelsOrderBy>;
  unreadsOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetChannelsV2Args = {
  input: GetChannelsInput;
};

export type QueryGetCommentLikesArgs = {
  commentId: Scalars['String']['input'];
};

export type QueryGetCommentsArgs = {
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  postId: Scalars['String']['input'];
};

export type QueryGetCommentsAggregateArgs = {
  postId: Scalars['String']['input'];
};

export type QueryGetCreatorAssetsArgs = {
  input: GetCreatorAssetsInput;
};

export type QueryGetCreatorFanDetailsArgs = {
  fanId: Scalars['String']['input'];
};

export type QueryGetCreatorFansNotPurchasedAssetsArgs = {
  fanId: Scalars['String']['input'];
  input: GetCreatorAssetsInput;
};

export type QueryGetCreatorFansPurchasedAssetsArgs = {
  fanId: Scalars['String']['input'];
  input: GetCreatorFanPurchasedAssetsInput;
};

export type QueryGetCreatorPostViewCountArgs = {
  postId: Scalars['String']['input'];
  timeFrame: Scalars['String']['input'];
};

export type QueryGetCreatorPostsViewCountArgs = {
  postIds: Array<Scalars['String']['input']>;
  timeFrame: Scalars['String']['input'];
};

export type QueryGetCreatorsArgs = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  underFollowersExperiment?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetCreatorsForFansArgs = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  underFollowersExperiment?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetEstimatedMessageBlastReachArgs = {
  input: EstimateMessageBlastReachInput;
};

export type QueryGetEstimatedMessageBlastReachV2Args = {
  input: EstimateMessageBlastReachInput;
};

export type QueryGetFanAssetsArgs = {
  fileType?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetFanListIdsByFanArgs = {
  input: GetFanListIdsByFanInput;
};

export type QueryGetFanListsV2Args = {
  input: GetFanListInput;
};

export type QueryGetFanNoteArgs = {
  fanId: Scalars['String']['input'];
};

export type QueryGetFanProfileArgs = {
  fanUserId: Scalars['String']['input'];
};

export type QueryGetFansByFanListArgs = {
  input: GetFansByFanListInput;
};

export type QueryGetFansByFanListV2Args = {
  input: GetFansByFanListInputV2;
};

export type QueryGetFansPurchasedEntityArgs = {
  input: GetFansPurchasedEntityInput;
};

export type QueryGetLikesArgs = {
  postId: Scalars['String']['input'];
};

export type QueryGetLivestreamArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetLivestreamViewersListArgs = {
  input: GetLivestreamViewersListInput;
  livestreamId: Scalars['String']['input'];
};

export type QueryGetMessageBlastArgs = {
  messageBlastId: Scalars['String']['input'];
};

export type QueryGetMessageBlastsArgs = {
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryGetMessageBlastsV2Args = {
  input: GetMessageBlastsV2Input;
};

export type QueryGetMinimumMessagingTipArgs = {
  input: GetMinimumMessageTipInput;
};

export type QueryGetMinimumMessagingTipV2Args = {
  input: GetMinimumMessageTipInput;
};

export type QueryGetMoreToFollowCreatorsArgs = {
  input: GetMoreToFollowCreatorsInput;
};

export type QueryGetNotificationsArgs = {
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryGetOrCreateChannelArgs = {
  recipientId: Scalars['String']['input'];
};

export type QueryGetPaymentsArgs = {
  input: GetPaymentsInput;
};

export type QueryGetPayoutLinkArgs = {
  input: GetPayoutLinkInput;
};

export type QueryGetPostArgs = {
  postId: Scalars['String']['input'];
};

export type QueryGetPostsArgs = {
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetPostsV2Args = {
  input: GetPostsInput;
};

export type QueryGetPublicPostsV2Args = {
  creatorId: Scalars['String']['input'];
  input: GetPostsInput;
};

export type QueryGetPublicProfileArgs = {
  username: Scalars['String']['input'];
};

export type QueryGetRecommendedCreatorsArgs = {
  input: GetRecommendedCreatorsInput;
};

export type QueryGetRecommendedPostsArgs = {
  input: GetPostsInput;
};

export type QueryGetSubscriptionPlanArgs = {
  creatorId: Scalars['String']['input'];
};

export type QueryGetSubscriptionsArgs = {
  limit: Scalars['Float']['input'];
  offset: Scalars['Float']['input'];
};

export type QueryGetTimelinePostsArgs = {
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryGetTopCountriesArgs = {
  timeFrame: Scalars['String']['input'];
};

export type QueryGetTotalRevenueForFanArgs = {
  fanId: Scalars['String']['input'];
};

export type RedisPaginationInput = {
  cursor?: Scalars['String']['input'];
  limit?: Scalars['Float']['input'];
  offset?: Scalars['Float']['input'];
};

export type ResendMessageBlastInput = {
  messageBlastId: Scalars['String']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
  unlockPriceInCents?: InputMaybe<Scalars['Float']['input']>;
};

export type ResendMessageBlastOutput = {
  __typename?: 'ResendMessageBlastOutput';
  messageBlastId: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type SalesTaxBillingLocation = {
  __typename?: 'SalesTaxBillingLocation';
  countryCode: Scalars['String']['output'];
  region?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type SendLivestreamCommentInput = {
  amountInCents: Scalars['Float']['input'];
  comment: Scalars['String']['input'];
};

export type SendLivestreamCommentOutput = {
  __typename?: 'SendLivestreamCommentOutput';
  comment?: Maybe<LivestreamActivityDto>;
  nextActionUrl?: Maybe<Scalars['String']['output']>;
  paymentId?: Maybe<Scalars['String']['output']>;
};

export type SendLivestreamTipInput = {
  amountInCents: Scalars['Float']['input'];
  location?: InputMaybe<LocationDto>;
};

export type SendLivestreamTipOutput = {
  __typename?: 'SendLivestreamTipOutput';
  nextActionUrl?: Maybe<Scalars['String']['output']>;
  paymentId?: Maybe<Scalars['String']['output']>;
  status: PaymentStatus;
  tip?: Maybe<LivestreamActivityDto>;
};

export type SendMessageBlastInput = {
  assetIds?: InputMaybe<Array<Scalars['String']['input']>>;
  assetIdsToExcludeFans?: InputMaybe<Array<Scalars['String']['input']>>;
  excludeLists?: InputMaybe<Array<Scalars['String']['input']>>;
  expiresAt?: InputMaybe<Scalars['String']['input']>;
  includeGroups?: InputMaybe<Scalars['String']['input']>;
  includeLists?: InputMaybe<Array<Scalars['String']['input']>>;
  isPreviewEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  messageText: Scalars['String']['input'];
  scheduledAt?: InputMaybe<Scalars['String']['input']>;
  textFallbacks?: InputMaybe<TextFallbacksType>;
  unlockPriceInCents?: InputMaybe<Scalars['Float']['input']>;
};

export type SendMessageBlastOutput = {
  __typename?: 'SendMessageBlastOutput';
  messageBlastId: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type SendMessageFromCreatorInput = {
  assetIds?: InputMaybe<Array<Scalars['String']['input']>>;
  content: Scalars['String']['input'];
  isPreviewEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  recipientUserId: Scalars['String']['input'];
  senderUserId: Scalars['String']['input'];
  unlockPriceInCents?: InputMaybe<Scalars['Float']['input']>;
};

export type SendMessageFromFanInput = {
  content: Scalars['String']['input'];
  paymentAmountInCents?: InputMaybe<Scalars['Int']['input']>;
  recipientUserId: Scalars['String']['input'];
  senderUserId: Scalars['String']['input'];
};

export type SendMessageFromFanOutput = {
  __typename?: 'SendMessageFromFanOutput';
  message?: Maybe<MessageDto>;
  nextActionUrl?: Maybe<Scalars['String']['output']>;
  paymentId?: Maybe<Scalars['String']['output']>;
};

export type SocialAccountsEntity = {
  __typename?: 'SocialAccountsEntity';
  createdAt: Scalars['DateTime']['output'];
  creatorId: Scalars['String']['output'];
  creatorProfile: CreatorProfilesEntity;
  facebook?: Maybe<Scalars['String']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  tiktok?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  website?: Maybe<Scalars['String']['output']>;
  youtube?: Maybe<Scalars['String']['output']>;
};

export type StripeRefundPaymentData = {
  __typename?: 'StripeRefundPaymentData';
  id: Scalars['String']['output'];
};

export type Subscribers = {
  __typename?: 'Subscribers';
  avatarUrl: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  months: Scalars['Float']['output'];
  totalPaid: Scalars['Float']['output'];
  username: Scalars['String']['output'];
};

export type SubscriptionCancellationOptions = {
  /** available only for Immediate cancellationType */
  refund?: Scalars['Boolean']['input'];
};

export enum SubscriptionCancellationType {
  AtPeriodEnd = 'AtPeriodEnd',
  Immediate = 'Immediate'
}

export type SubscriptionDto = {
  __typename?: 'SubscriptionDto';
  createdAt: Scalars['DateTime']['output'];
  creatorExclusivePostCount: Scalars['Int']['output'];
  creatorId: Scalars['String']['output'];
  creatorPublicPostCount: Scalars['Int']['output'];
  creatorUserProfile: UserProfilesEntity;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  fanId: Scalars['String']['output'];
  fanNote?: Maybe<FanNotesEntity>;
  fanUserProfile: UserProfilesEntity;
  id: Scalars['String']['output'];
  isCreatorUnderFollowersExperiment: Scalars['Boolean']['output'];
  isFanBlocked: Scalars['Boolean']['output'];
  isFanFollower: Scalars['Boolean']['output'];
  months: Scalars['Float']['output'];
  price: Scalars['Float']['output'];
  status: Scalars['String']['output'];
  /** @deprecated Kill it next week, we dont use it */
  stripeSubscriptionId: Scalars['String']['output'];
  syncedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TagsEntity = {
  __typename?: 'TagsEntity';
  creatorId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type TextFallbacksType = {
  firstName: Scalars['String']['input'];
};

export enum TipType {
  CreatorTip = 'CREATOR_TIP',
  Livestream = 'LIVESTREAM',
  LivestreamComment = 'LIVESTREAM_COMMENT',
  MediaUnlock = 'MEDIA_UNLOCK',
  Message = 'MESSAGE',
  Post = 'POST',
  PostUnlock = 'POST_UNLOCK',
  SubscriptionCharge = 'SUBSCRIPTION_CHARGE'
}

export type TopCountriesData = {
  __typename?: 'TopCountriesData';
  country: Scalars['String']['output'];
  percent: Scalars['Float']['output'];
  visits: Scalars['Float']['output'];
};

export type UnlockMessageDto = {
  __typename?: 'UnlockMessageDto';
  message?: Maybe<MessageDto>;
  nextActionUrl?: Maybe<Scalars['String']['output']>;
  paymentId?: Maybe<Scalars['String']['output']>;
  paymentStatus?: Maybe<PaymentStatus>;
};

export type UnlockMessageInput = {
  amountInCents: Scalars['Float']['input'];
  creatorId: Scalars['String']['input'];
  fanfixPrice?: InputMaybe<FanfixPriceDto>;
  isApplePay?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<LocationDto>;
  messageId: Scalars['String']['input'];
};

export type UnlockPostInput = {
  amountInCents: Scalars['Float']['input'];
  location?: InputMaybe<LocationDto>;
  postId: Scalars['String']['input'];
};

export type UnlockPostOutput = {
  __typename?: 'UnlockPostOutput';
  nextActionUrl?: Maybe<Scalars['String']['output']>;
  paymentId?: Maybe<Scalars['String']['output']>;
  post?: Maybe<GetPostDto>;
};

export type UpdateCommentInput = {
  comment: Scalars['String']['input'];
};

export type UpdateCreatorAssetInput = {
  name: Scalars['String']['input'];
};

export type UpdateCreatorAssetVaultInput = {
  tagIds: Array<Scalars['String']['input']>;
};

export type UpdateCreatorInput = {
  allowsComments?: InputMaybe<Scalars['Boolean']['input']>;
  allowsMessaging?: InputMaybe<Scalars['Boolean']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  chatBubbleColor?: InputMaybe<Scalars['String']['input']>;
  displayOnlineStatus?: InputMaybe<Scalars['Boolean']['input']>;
  isWatermarkEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  minimumMediaTip?: InputMaybe<Scalars['Float']['input']>;
  minimumMessageTip?: InputMaybe<Scalars['Float']['input']>;
  personaStatus?: InputMaybe<PersonaStatus>;
};

export type UpdateGreetingMessageInput = {
  assetIds?: InputMaybe<Array<Scalars['String']['input']>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isPreviewEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  text: Scalars['String']['input'];
  textFallbacks?: InputMaybe<TextFallbacksType>;
  unlockPriceInCents?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateLivestreamInput = {
  allowsComments?: InputMaybe<Scalars['Boolean']['input']>;
  minimumCommentTipAmountInCents?: InputMaybe<Scalars['Float']['input']>;
  startedAt?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLivestreamOutput = {
  __typename?: 'UpdateLivestreamOutput';
  allowsComments: Scalars['Boolean']['output'];
  allowsTips: Scalars['Boolean']['output'];
  audienceType: AudienceType;
  createdAt: Scalars['DateTime']['output'];
  creatorId: Scalars['String']['output'];
  creatorUserProfile: UserProfilesEntity;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  minimumCommentTipAmountInCents: Scalars['Float']['output'];
  roomId: Scalars['String']['output'];
  sessionId: Scalars['String']['output'];
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  totalTipAmountInCents?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type UpdateMessageChannelAsCreatorInput = {
  creatorLastSeenAt?: InputMaybe<Scalars['DateTime']['input']>;
  creatorLeftAt?: InputMaybe<Scalars['DateTime']['input']>;
  isPriority?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateMessageChannelAsFanInput = {
  fanLastSeenAt?: InputMaybe<Scalars['DateTime']['input']>;
  fanLeftAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateOnboardingFlowInput = {
  onboardingActions: Array<AllowedOnboardingActions>;
};

export type UpdatePostInput = {
  isExclusive?: InputMaybe<Scalars['Boolean']['input']>;
  isFirstAssetPublic?: InputMaybe<Scalars['Boolean']['input']>;
  nonSubscriberPrice?: InputMaybe<Scalars['Float']['input']>;
  postId: Scalars['String']['input'];
  scheduledFor?: InputMaybe<Scalars['DateTime']['input']>;
  subscriberPrice?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSocialAccountInput = {
  facebook?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  tiktok?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserProfileInput = {
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  bannerUrl?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UserProfilesDto = {
  __typename?: 'UserProfilesDto';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  bannerUrl?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  fanToFanList?: Maybe<Array<FanListFansEntity>>;
  firstName: Scalars['String']['output'];
  isOnline?: Maybe<Scalars['Boolean']['output']>;
  lastName: Scalars['String']['output'];
  lastSeenAt?: Maybe<Scalars['DateTime']['output']>;
  unreadChannelsCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: UsersEntity;
  userId: Scalars['String']['output'];
  username: Scalars['String']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type UserProfilesEntity = {
  __typename?: 'UserProfilesEntity';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  bannerUrl?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  fanToFanList?: Maybe<Array<FanListFansEntity>>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: UsersEntity;
  userId: Scalars['String']['output'];
  username: Scalars['String']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export enum UserRoles {
  Admin = 'Admin',
  Creator = 'Creator',
  Fan = 'Fan'
}

export type UserTimelinePostsEntity = {
  __typename?: 'UserTimelinePostsEntity';
  createdAt: Scalars['DateTime']['output'];
  fan: UserProfilesEntity;
  id: Scalars['String']['output'];
  order: Scalars['Float']['output'];
  post: PostsEntity;
  updatedAt: Scalars['DateTime']['output'];
};

export type UsersEntity = {
  __typename?: 'UsersEntity';
  createdAt: Scalars['DateTime']['output'];
  creatorProfile?: Maybe<CreatorProfilesEntity>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isAdmin: Scalars['Boolean']['output'];
  lastLoginAt?: Maybe<Scalars['DateTime']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  roles: Array<UserRoles>;
  updatedAt: Scalars['DateTime']['output'];
  userProfile: UserProfilesEntity;
};

export type Update_Admin_AccessMutationVariables = Exact<{
  adminUpdateUserId: Scalars['String']['input'];
  input: AdminUpdateUserInput;
}>;

export type Update_Admin_AccessMutation = {
  __typename?: 'Mutation';
  adminUpdateUser: { __typename?: 'AdminUserDto'; id: string };
};

export type GetAdminUserQueryVariables = Exact<{
  adminGetUserId: Scalars['String']['input'];
}>;

export type GetAdminUserQuery = {
  __typename?: 'Query';
  adminGetUser: {
    __typename?: 'AdminUserDto';
    id: string;
    email: string;
    createdAt: any;
    roles: Array<UserRoles>;
    userProfile?: {
      __typename?: 'AdminUserProfileDto';
      firstName: string;
      lastName: string;
      username: string;
    } | null;
  };
};

export type GetAdminUsersQueryVariables = Exact<{
  input: AdminGetUsersInput;
}>;

export type GetAdminUsersQuery = {
  __typename?: 'Query';
  adminGetUsers: {
    __typename?: 'AdminGetUsersOutput';
    totalCount: number;
    users: Array<{
      __typename?: 'AdminUserDto';
      id: string;
      email: string;
      userProfile?: {
        __typename?: 'AdminUserProfileDto';
        firstName: string;
        lastName: string;
        username: string;
      } | null;
    }>;
  };
};

export type AdminDeleteMessageBlastMutationVariables = Exact<{
  creatorId: Scalars['String']['input'];
  id: Scalars['String']['input'];
}>;

export type AdminDeleteMessageBlastMutation = {
  __typename?: 'Mutation';
  adminDeleteMessageBlast: boolean;
};

export type AdminGetMessageBlastsQueryVariables = Exact<{
  input: AdminGetMessageBlastsInput;
}>;

export type AdminGetMessageBlastsQuery = {
  __typename?: 'Query';
  adminGetMessageBlasts: {
    __typename?: 'AdminGetMessageBlastsOutput';
    totalCount: number;
    messageBlasts: Array<{
      __typename?: 'AdminMessageBlastDto';
      id: string;
      text?: string | null;
      creatorId?: string | null;
      createdAt: any;
      creatorUsername?: string | null;
      deletedAt?: any | null;
      assets: Array<{
        __typename?: 'AdminAssetDto';
        mimeType?: string | null;
        blurredURL?: string | null;
        resizedURL?: string | null;
        videoPlaybackId?: string | null;
      }>;
    }>;
  };
};

export type AdminCreateCategoryMutationVariables = Exact<{
  input: AdminCreateCategoryInput;
}>;

export type AdminCreateCategoryMutation = {
  __typename?: 'Mutation';
  adminCreateCategory: {
    __typename?: 'AdminCategoryDto';
    id: string;
    createdAt: any;
    displayOrder?: number | null;
    imageURL: string;
    isActive: boolean;
    title: string;
    updatedAt: any;
  };
};

export type AdminDeleteCategoryMutationVariables = Exact<{
  adminDeleteCategoryId: Scalars['String']['input'];
}>;

export type AdminDeleteCategoryMutation = {
  __typename?: 'Mutation';
  adminDeleteCategory: number;
};

export type AdminBulkUpdateCreatorCategoriesMutationVariables = Exact<{
  input: AdminBulkUpdateCategoriesInput;
}>;

export type AdminBulkUpdateCreatorCategoriesMutation = {
  __typename?: 'Mutation';
  adminBulkUpdateCreatorCategories: {
    __typename?: 'AdminBulkUpdateCategoriesOutput';
    totalCount: number;
    users: Array<{
      __typename?: 'UsersEntity';
      id: string;
      userProfile: {
        __typename?: 'UserProfilesEntity';
        firstName: string;
        lastName: string;
      };
    }>;
  };
};

export type AdminUpdateCategoryMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: AdminUpdateCategoryInput;
}>;

export type AdminUpdateCategoryMutation = {
  __typename?: 'Mutation';
  adminUpdateCategory: { __typename?: 'AdminCategoryDto'; id: string };
};

export type AdminGetCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type AdminGetCategoriesQuery = {
  __typename?: 'Query';
  adminGetCategories: Array<{
    __typename?: 'AdminCategoryDto';
    id: string;
    createdAt: any;
    displayOrder?: number | null;
    imageURL: string;
    isActive: boolean;
    title: string;
  }>;
};

export type AdminGetCategoryQueryVariables = Exact<{
  categoryId: Scalars['String']['input'];
}>;

export type AdminGetCategoryQuery = {
  __typename?: 'Query';
  adminGetCategory: {
    __typename?: 'AdminCategoryDto';
    id: string;
    creatorProfiles: Array<{
      __typename?: 'AdminCreatorProfileDto';
      userId: string;
      socialAccounts?: {
        __typename?: 'AdminSocailAccountDto';
        instagram?: string | null;
        tiktok?: string | null;
        twitter?: string | null;
        website?: string | null;
        youtube?: string | null;
        facebook?: string | null;
      } | null;
    }>;
  };
};

export type AdminUpdateCreatorProfileMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  input: AdminUpdateCreatorProfileInput;
}>;

export type AdminUpdateCreatorProfileMutation = {
  __typename?: 'Mutation';
  adminUpdateCreatorProfile: {
    __typename?: 'AdminUpdateCreatorProfileOutput';
    userId: string;
    acceptedAt?: any | null;
    rejectedAt?: any | null;
    foreignAccount: boolean;
  };
};

export type CreatorGetUserQueryVariables = Exact<{
  adminGetUserId: Scalars['String']['input'];
}>;

export type CreatorGetUserQuery = {
  __typename?: 'Query';
  adminGetUser: {
    __typename?: 'AdminUserDto';
    createdAt: any;
    id: string;
    email: string;
    roles: Array<UserRoles>;
    statistics?: {
      __typename?: 'AdminUserStatisticsDto';
      postsCount: number;
      subscribersCount: number;
    } | null;
    creatorProfile?: {
      __typename?: 'AdminCreatorProfileDto';
      acceptedAt?: any | null;
      allowsMessaging: boolean;
      appliedAt?: any | null;
      foreignAccount: boolean;
      freeMediaTipEnabled: boolean;
      freeMessageTipEnabled: boolean;
      isFeatured: boolean;
      minimumMediaTip: number;
      minimumMessageTip: number;
      rejectedAt?: any | null;
      personaStatus: PersonaStatus;
      socialAccounts?: {
        __typename?: 'AdminSocailAccountDto';
        facebook?: string | null;
        instagram?: string | null;
        tiktok?: string | null;
        twitter?: string | null;
        website?: string | null;
        youtube?: string | null;
      } | null;
    } | null;
    userProfile?: {
      __typename?: 'AdminUserProfileDto';
      avatarUrl?: string | null;
      bannerUrl?: string | null;
      bio?: string | null;
      firstName: string;
      lastName: string;
      username: string;
      websiteUrl?: string | null;
    } | null;
  };
};

export type CreatorGetUsersQueryVariables = Exact<{
  input: AdminGetUsersInput;
}>;

export type CreatorGetUsersQuery = {
  __typename?: 'Query';
  adminGetUsers: {
    __typename?: 'AdminGetUsersOutput';
    totalCount: number;
    users: Array<{
      __typename?: 'AdminUserDto';
      id: string;
      roles: Array<UserRoles>;
      createdAt: any;
      email: string;
      creatorProfile?: {
        __typename?: 'AdminCreatorProfileDto';
        appliedAt?: any | null;
        acceptedAt?: any | null;
        rejectedAt?: any | null;
      } | null;
      userProfile?: {
        __typename?: 'AdminUserProfileDto';
        firstName: string;
        lastName: string;
        username: string;
      } | null;
    }>;
  };
};

export type GetCreatorProfileQueryVariables = Exact<{
  adminGetUserId: Scalars['String']['input'];
}>;

export type GetCreatorProfileQuery = {
  __typename?: 'Query';
  adminGetUser: {
    __typename?: 'AdminUserDto';
    id: string;
    roles: Array<UserRoles>;
    creatorProfile?: {
      __typename?: 'AdminCreatorProfileDto';
      acceptedAt?: any | null;
      allowsMessaging: boolean;
      appliedAt?: any | null;
      foreignAccount: boolean;
      freeMediaTipEnabled: boolean;
      freeMessageTipEnabled: boolean;
      isFeatured: boolean;
      minimumMediaTip: number;
      minimumMessageTip: number;
      rejectedAt?: any | null;
      personaStatus: PersonaStatus;
      socialAccounts?: {
        __typename?: 'AdminSocailAccountDto';
        facebook?: string | null;
        instagram?: string | null;
        tiktok?: string | null;
        twitter?: string | null;
        website?: string | null;
        youtube?: string | null;
      } | null;
    } | null;
  };
};

export type AdminEndLivestreamMutationVariables = Exact<{
  input: AdminEndLivestreamInput;
}>;

export type AdminEndLivestreamMutation = {
  __typename?: 'Mutation';
  adminEndLivestream: { __typename?: 'AdminEndLivestreamOutput'; id: string };
};

export type AdminUpdateLivestreamMutationVariables = Exact<{
  input: AdminUpdateLivestreamInput;
}>;

export type AdminUpdateLivestreamMutation = {
  __typename?: 'Mutation';
  adminUpdateLivestream: {
    __typename?: 'AdminUpdateLivestreamOutput';
    id: string;
  };
};

export type AdminGetLivestreamsQueryVariables = Exact<{
  input: AdminGetLivestreamsInput;
}>;

export type AdminGetLivestreamsQuery = {
  __typename?: 'Query';
  adminGetLivestreams: {
    __typename?: 'AdminGetLivestreamsOutput';
    totalCount: number;
    livestreams: Array<{
      __typename?: 'LivestreamDto';
      title: string;
      sessionId: string;
      roomId: string;
      minimumCommentTipAmountInCents: number;
      totalTipAmountInCents?: number | null;
      id: string;
      audienceType: AudienceType;
      allowsTips: boolean;
      endedAt?: any | null;
      allowsComments: boolean;
      updatedAt: any;
      deletedAt?: any | null;
      creatorId: string;
      createdAt: any;
      startedAt?: any | null;
      creatorUserProfile: {
        __typename?: 'UserProfilesEntity';
        avatarUrl?: string | null;
        firstName: string;
        lastName: string;
        userId: string;
        username: string;
      };
    }>;
  };
};

export type AdminGetLivestreamQueryVariables = Exact<{
  adminGetLivestreamId: Scalars['String']['input'];
}>;

export type AdminGetLivestreamQuery = {
  __typename?: 'Query';
  adminGetLivestream: {
    __typename?: 'AdminGetLivestreamOutput';
    id: string;
    allowsComments: boolean;
    allowsTips: boolean;
    audienceType: AudienceType;
    createdAt: any;
    creatorId: string;
    deletedAt?: any | null;
    endedAt?: any | null;
    minimumCommentTipAmountInCents: number;
    roomId: string;
    sessionId: string;
    startedAt?: any | null;
    title: string;
    totalTipAmountInCents?: number | null;
    updatedAt: any;
  };
};

export type AdminSoftDeletePostMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type AdminSoftDeletePostMutation = {
  __typename?: 'Mutation';
  adminSoftDeletePost: number;
};

export type AdminRestorePostMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type AdminRestorePostMutation = {
  __typename?: 'Mutation';
  adminRestorePost: number;
};

export type AdminSoftDeletePostCommentMutationVariables = Exact<{
  id: Scalars['String']['input'];
  postId: Scalars['String']['input'];
}>;

export type AdminSoftDeletePostCommentMutation = {
  __typename?: 'Mutation';
  adminSoftDeletePostComment: number;
};

export type AdminRestorePostCommentMutationVariables = Exact<{
  id: Scalars['String']['input'];
  postId: Scalars['String']['input'];
}>;

export type AdminRestorePostCommentMutation = {
  __typename?: 'Mutation';
  adminRestorePostComment: number;
};

export type AdminBulkUpdatePostsMutationVariables = Exact<{
  input: AdminBulkUpdatePostsInput;
}>;

export type AdminBulkUpdatePostsMutation = {
  __typename?: 'Mutation';
  adminBulkUpdatePosts: {
    __typename?: 'AdminBulkUpdatePostsOutput';
    totalCount: number;
  };
};

export type AdminGetPostsQueryVariables = Exact<{
  input: AdminGetPostsInput;
}>;

export type AdminGetPostsQuery = {
  __typename?: 'Query';
  adminGetPosts: {
    __typename?: 'AdminGetPostsOutput';
    totalCount: number;
    posts: Array<{
      __typename?: 'PostsEntity';
      id: string;
      viewCount: number;
      title: string;
      subscriberPrice: number;
      status: PostStatus;
      nonSubscriberPrice?: number | null;
      likeCount: number;
      isPinned: boolean;
      commentCount: number;
      deletedAt?: any | null;
      createdAt: any;
      author?: {
        __typename?: 'UserProfilesEntity';
        username: string;
        userId: string;
      } | null;
    }>;
  };
};

export type AdminGetPostQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type AdminGetPostQuery = {
  __typename?: 'Query';
  adminGetPost: {
    __typename?: 'AdminGetPostOutput';
    id: string;
    viewCount: number;
    likeCount: number;
    subscriberPrice?: number | null;
    nonSubscriberPrice?: number | null;
    deletedAt?: any | null;
    createdAt: any;
    title: string;
    isExclusive: boolean;
    isPinned: boolean;
    postAssets?: Array<{
      __typename?: 'AdminPostsAssetDto';
      asset: {
        __typename?: 'AdminAssetDto';
        id: string;
        mimeType?: string | null;
        resizedURL?: string | null;
        videoPlaybackId?: string | null;
      };
    }> | null;
    comments?: Array<{
      __typename?: 'AdminCommentDto';
      id: string;
      comment: string;
      deletedAt?: any | null;
      author: {
        __typename?: 'AdminUserProfileDto';
        userId: string;
        username: string;
      };
    }> | null;
    author?: {
      __typename?: 'AdminUserProfileDto';
      userId: string;
      username: string;
    } | null;
  };
};

export type CreateAdminNoteMutationVariables = Exact<{
  input: AdminCreateAdminNoteInput;
}>;

export type CreateAdminNoteMutation = {
  __typename?: 'Mutation';
  adminCreateAdminNote: {
    __typename?: 'AdminAdminNoteDto';
    content: string;
    createdAt: any;
    id: string;
  };
};

export type UpdateNoteMutationVariables = Exact<{
  input: AdminUpdateAdminNoteInput;
  id: Scalars['String']['input'];
}>;

export type UpdateNoteMutation = {
  __typename?: 'Mutation';
  adminUpdateAdminNote: {
    __typename?: 'AdminAdminNoteDto';
    content: string;
    createdAt: any;
    id: string;
  };
};

export type DeleteAdminNotesMutationVariables = Exact<{
  input: AdminDeleteAdminNoteInput;
}>;

export type DeleteAdminNotesMutation = {
  __typename?: 'Mutation';
  adminDeleteAdminNote: number;
};

export type AdminGetAdminNotesQueryVariables = Exact<{
  input: AdminGetAdminNotesInput;
}>;

export type AdminGetAdminNotesQuery = {
  __typename?: 'Query';
  adminGetAdminNotes: Array<{
    __typename?: 'AdminAdminNoteDto';
    content: string;
    createdAt: any;
    id: string;
  }>;
};

export type AdminGetSubscriptionsQueryVariables = Exact<{
  input: AdminGetSubscriptionsInput;
}>;

export type AdminGetSubscriptionsQuery = {
  __typename?: 'Query';
  adminGetSubscriptions: {
    __typename?: 'AdminGetSubscriptionsOutput';
    totalCount: number;
    subscriptions: Array<{
      __typename?: 'AdminSubscriptionDto';
      id: string;
      price: number;
      status: string;
      fanId: string;
      creatorId: string;
      months: number;
      endDate?: any | null;
      createdAt: any;
    }>;
  };
};

export type AdminCancelSubscriptionMutationVariables = Exact<{
  input: AdminCancelSubscriptionInput;
}>;

export type AdminCancelSubscriptionMutation = {
  __typename?: 'Mutation';
  adminCancelSubscription: {
    __typename?: 'AdminCancelSubscriptionOutput';
    status: string;
    subscriptionId: string;
  };
};

export type AdminSoftDeleteUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type AdminSoftDeleteUserMutation = {
  __typename?: 'Mutation';
  adminSoftDeleteUser: number;
};

export type AdminRestoreUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type AdminRestoreUserMutation = {
  __typename?: 'Mutation';
  adminRestoreUser: number;
};

export type AdminSoftDeleteUserCommentsMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type AdminSoftDeleteUserCommentsMutation = {
  __typename?: 'Mutation';
  adminSoftDeleteUserComments: number;
};

export type AdminCreateCreatorProfileMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type AdminCreateCreatorProfileMutation = {
  __typename?: 'Mutation';
  adminCreateCreatorProfile: {
    __typename?: 'AdminCreateCreatorProfileOutput';
    userId: string;
    acceptedAt?: any | null;
    appliedAt?: any | null;
    rejectedAt?: any | null;
    isFeatured: boolean;
    minimumMediaTip: number;
    minimumMessageTip: number;
    freeMessageTipEnabled: boolean;
    freeMediaTipEnabled: boolean;
  };
};

export type AdminUpdateUserProfileMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  input: AdminUpdateUserProfileInput;
}>;

export type AdminUpdateUserProfileMutation = {
  __typename?: 'Mutation';
  adminUpdateUserProfile: {
    __typename?: 'AdminUserProfileDto';
    username: string;
    firstName: string;
    lastName: string;
    bio?: string | null;
    avatarUrl?: string | null;
    bannerUrl?: string | null;
    websiteUrl?: string | null;
  };
};

export type AdminUpdateUserMutationVariables = Exact<{
  adminUpdateUserId: Scalars['String']['input'];
  input: AdminUpdateUserInput;
}>;

export type AdminUpdateUserMutation = {
  __typename?: 'Mutation';
  adminUpdateUser: {
    __typename?: 'AdminUserDto';
    id: string;
    deletedAt?: any | null;
    email: string;
    roles: Array<UserRoles>;
  };
};

export type AdminGetUsersQueryVariables = Exact<{
  input: AdminGetUsersInput;
}>;

export type AdminGetUsersQuery = {
  __typename?: 'Query';
  adminGetUsers: {
    __typename?: 'AdminGetUsersOutput';
    totalCount: number;
    users: Array<{
      __typename?: 'AdminUserDto';
      id: string;
      createdAt: any;
      deletedAt?: any | null;
      email: string;
      roles: Array<UserRoles>;
      lastLoginAt?: any | null;
      lastSeenAt?: any | null;
      userProfile?: {
        __typename?: 'AdminUserProfileDto';
        avatarUrl?: string | null;
        firstName: string;
        lastName: string;
        username: string;
      } | null;
      creatorProfile?: {
        __typename?: 'AdminCreatorProfileDto';
        acceptedAt?: any | null;
      } | null;
    }>;
  };
};

export type AdminGetUserQueryVariables = Exact<{
  adminGetUserId: Scalars['String']['input'];
}>;

export type AdminGetUserQuery = {
  __typename?: 'Query';
  adminGetUser: {
    __typename?: 'AdminUserDto';
    id: string;
    createdAt: any;
    email: string;
    roles: Array<UserRoles>;
    lastLoginAt?: any | null;
    lastSeenAt?: any | null;
    deletedAt?: any | null;
    userProfile?: {
      __typename?: 'AdminUserProfileDto';
      avatarUrl?: string | null;
      bio?: string | null;
      firstName: string;
      lastName: string;
      username: string;
    } | null;
    creatorProfile?: {
      __typename?: 'AdminCreatorProfileDto';
      acceptedAt?: any | null;
      allowsMessaging: boolean;
      appliedAt?: any | null;
      foreignAccount: boolean;
      freeMediaTipEnabled: boolean;
      freeMessageTipEnabled: boolean;
      isFeatured: boolean;
      minimumMediaTip: number;
      minimumMessageTip: number;
      rejectedAt?: any | null;
      personaStatus: PersonaStatus;
      socialAccounts?: {
        __typename?: 'AdminSocailAccountDto';
        facebook?: string | null;
        instagram?: string | null;
        tiktok?: string | null;
        twitter?: string | null;
        website?: string | null;
        youtube?: string | null;
      } | null;
    } | null;
  };
};

export type AdminGetPaymentsQueryVariables = Exact<{
  input: AdminGetPaymentsInput;
  userId: Scalars['String']['input'];
}>;

export type AdminGetPaymentsQuery = {
  __typename?: 'Query';
  adminGetPayments: {
    __typename?: 'AdminGetPaymentsOutput';
    totalCount: number;
    payments: Array<{
      __typename?: 'AdminPaymentDto';
      amountInCents: number;
      applicationFeeAmountInCents?: number | null;
      createdAt: any;
      creatorId: string;
      deletedAt?: any | null;
      fanId: string;
      id: string;
      paymentProcessor: PaymentProcessor;
      paymentProcessorTransactionId: string;
      processingFeeAmountInCents?: number | null;
      relatedEntityId?: string | null;
      salesTaxAmountInCents?: number | null;
      status: PaymentStatus;
      tipType: TipType;
      updatedAt: any;
      salesTaxBillingLocation?: {
        __typename?: 'SalesTaxBillingLocation';
        countryCode: string;
        region?: string | null;
        zip?: string | null;
      } | null;
    }>;
  };
};

export const Update_Admin_AccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UPDATE_ADMIN_ACCESS' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'adminUpdateUserId' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminUpdateUserInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminUpdateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'adminUpdateUserId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  Update_Admin_AccessMutation,
  Update_Admin_AccessMutationVariables
>;
export const GetAdminUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAdminUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'adminGetUserId' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminGetUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'adminGetUserId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userProfile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'username' }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetAdminUserQuery, GetAdminUserQueryVariables>;
export const GetAdminUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAdminUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminGetUsersInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminGetUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userProfile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'username' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetAdminUsersQuery, GetAdminUsersQueryVariables>;
export const AdminDeleteMessageBlastDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'adminDeleteMessageBlast' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'creatorId' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminDeleteMessageBlast' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'creatorId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'creatorId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminDeleteMessageBlastMutation,
  AdminDeleteMessageBlastMutationVariables
>;
export const AdminGetMessageBlastsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'adminGetMessageBlasts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminGetMessageBlastsInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminGetMessageBlasts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'messageBlasts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assets' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mimeType' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'blurredURL' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resizedURL' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'videoPlaybackId' }
                            }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creatorId' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creatorUsername' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deletedAt' }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminGetMessageBlastsQuery,
  AdminGetMessageBlastsQueryVariables
>;
export const AdminCreateCategoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminCreateCategory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminCreateCategoryInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminCreateCategory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'displayOrder' }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'imageURL' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminCreateCategoryMutation,
  AdminCreateCategoryMutationVariables
>;
export const AdminDeleteCategoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminDeleteCategory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'adminDeleteCategoryId' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminDeleteCategory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'adminDeleteCategoryId' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminDeleteCategoryMutation,
  AdminDeleteCategoryMutationVariables
>;
export const AdminBulkUpdateCreatorCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminBulkUpdateCreatorCategories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminBulkUpdateCategoriesInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminBulkUpdateCreatorCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userProfile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminBulkUpdateCreatorCategoriesMutation,
  AdminBulkUpdateCreatorCategoriesMutationVariables
>;
export const AdminUpdateCategoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminUpdateCategory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminUpdateCategoryInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminUpdateCategory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminUpdateCategoryMutation,
  AdminUpdateCategoryMutationVariables
>;
export const AdminGetCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminGetCategories' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminGetCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'displayOrder' }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'imageURL' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminGetCategoriesQuery,
  AdminGetCategoriesQueryVariables
>;
export const AdminGetCategoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminGetCategory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'categoryId' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminGetCategory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'categoryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'categoryId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creatorProfiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'socialAccounts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'instagram' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tiktok' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'twitter' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'website' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'youtube' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'facebook' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminGetCategoryQuery,
  AdminGetCategoryQueryVariables
>;
export const AdminUpdateCreatorProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'adminUpdateCreatorProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminUpdateCreatorProfileInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminUpdateCreatorProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'acceptedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rejectedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'foreignAccount' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminUpdateCreatorProfileMutation,
  AdminUpdateCreatorProfileMutationVariables
>;
export const CreatorGetUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CreatorGetUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'adminGetUserId' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminGetUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'adminGetUserId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'statistics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'postsCount' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subscribersCount' }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creatorProfile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acceptedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'allowsMessaging' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'appliedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'foreignAccount' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freeMediaTipEnabled' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freeMessageTipEnabled' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isFeatured' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minimumMediaTip' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minimumMessageTip' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rejectedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'personaStatus' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'socialAccounts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'facebook' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'instagram' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tiktok' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'twitter' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'website' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'youtube' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userProfile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bannerUrl' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'bio' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'username' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'websiteUrl' }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CreatorGetUserQuery, CreatorGetUserQueryVariables>;
export const CreatorGetUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CreatorGetUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminGetUsersInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminGetUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creatorProfile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'appliedAt' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'acceptedAt' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rejectedAt' }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userProfile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'username' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CreatorGetUsersQuery,
  CreatorGetUsersQueryVariables
>;
export const GetCreatorProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCreatorProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'adminGetUserId' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminGetUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'adminGetUserId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creatorProfile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acceptedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'allowsMessaging' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'appliedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'foreignAccount' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freeMediaTipEnabled' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freeMessageTipEnabled' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isFeatured' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minimumMediaTip' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minimumMessageTip' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rejectedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'personaStatus' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'socialAccounts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'facebook' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'instagram' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tiktok' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'twitter' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'website' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'youtube' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetCreatorProfileQuery,
  GetCreatorProfileQueryVariables
>;
export const AdminEndLivestreamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminEndLivestream' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminEndLivestreamInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminEndLivestream' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminEndLivestreamMutation,
  AdminEndLivestreamMutationVariables
>;
export const AdminUpdateLivestreamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminUpdateLivestream' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminUpdateLivestreamInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminUpdateLivestream' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminUpdateLivestreamMutation,
  AdminUpdateLivestreamMutationVariables
>;
export const AdminGetLivestreamsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminGetLivestreams' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminGetLivestreamsInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminGetLivestreams' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'livestreams' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sessionId' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'roomId' }
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'minimumCommentTipAmountInCents'
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalTipAmountInCents' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'audienceType' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'allowsTips' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'allowsComments' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deletedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creatorId' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creatorUserProfile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatarUrl' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userId' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'username' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminGetLivestreamsQuery,
  AdminGetLivestreamsQueryVariables
>;
export const AdminGetLivestreamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminGetLivestream' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'adminGetLivestreamId' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminGetLivestream' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'adminGetLivestreamId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowsComments' }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'allowsTips' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'audienceType' }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'creatorId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'minimumCommentTipAmountInCents'
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'roomId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sessionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalTipAmountInCents' }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminGetLivestreamQuery,
  AdminGetLivestreamQueryVariables
>;
export const AdminSoftDeletePostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'adminSoftDeletePost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminSoftDeletePost' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminSoftDeletePostMutation,
  AdminSoftDeletePostMutationVariables
>;
export const AdminRestorePostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'adminRestorePost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminRestorePost' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminRestorePostMutation,
  AdminRestorePostMutationVariables
>;
export const AdminSoftDeletePostCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'adminSoftDeletePostComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'postId' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminSoftDeletePostComment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'postId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'postId' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminSoftDeletePostCommentMutation,
  AdminSoftDeletePostCommentMutationVariables
>;
export const AdminRestorePostCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'adminRestorePostComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'postId' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminRestorePostComment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'postId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'postId' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminRestorePostCommentMutation,
  AdminRestorePostCommentMutationVariables
>;
export const AdminBulkUpdatePostsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminBulkUpdatePosts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminBulkUpdatePostsInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminBulkUpdatePosts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminBulkUpdatePostsMutation,
  AdminBulkUpdatePostsMutationVariables
>;
export const AdminGetPostsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'adminGetPosts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminGetPostsInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminGetPosts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'posts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'viewCount' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subscriberPrice' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nonSubscriberPrice' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'likeCount' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isPinned' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'commentCount' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deletedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'author' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'username' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userId' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<AdminGetPostsQuery, AdminGetPostsQueryVariables>;
export const AdminGetPostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'adminGetPost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminGetPost' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'viewCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'likeCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscriberPrice' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nonSubscriberPrice' }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'postAssets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'asset' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mimeType' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resizedURL' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'videoPlaybackId' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'comments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deletedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'author' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userId' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'username' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isExclusive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPinned' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'author' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'username' }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<AdminGetPostQuery, AdminGetPostQueryVariables>;
export const CreateAdminNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAdminNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminCreateAdminNoteInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminCreateAdminNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CreateAdminNoteMutation,
  CreateAdminNoteMutationVariables
>;
export const UpdateNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminUpdateAdminNoteInput' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminUpdateAdminNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UpdateNoteMutation, UpdateNoteMutationVariables>;
export const DeleteAdminNotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteAdminNotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminDeleteAdminNoteInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminDeleteAdminNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  DeleteAdminNotesMutation,
  DeleteAdminNotesMutationVariables
>;
export const AdminGetAdminNotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminGetAdminNotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminGetAdminNotesInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminGetAdminNotes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminGetAdminNotesQuery,
  AdminGetAdminNotesQueryVariables
>;
export const AdminGetSubscriptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminGetSubscriptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminGetSubscriptionsInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminGetSubscriptions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscriptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'fanId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creatorId' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'months' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endDate' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminGetSubscriptionsQuery,
  AdminGetSubscriptionsQueryVariables
>;
export const AdminCancelSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AdminCancelSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminCancelSubscriptionInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminCancelSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscriptionId' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminCancelSubscriptionMutation,
  AdminCancelSubscriptionMutationVariables
>;
export const AdminSoftDeleteUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'adminSoftDeleteUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminSoftDeleteUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminSoftDeleteUserMutation,
  AdminSoftDeleteUserMutationVariables
>;
export const AdminRestoreUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'adminRestoreUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminRestoreUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminRestoreUserMutation,
  AdminRestoreUserMutationVariables
>;
export const AdminSoftDeleteUserCommentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'adminSoftDeleteUserComments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminSoftDeleteUserComments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminSoftDeleteUserCommentsMutation,
  AdminSoftDeleteUserCommentsMutationVariables
>;
export const AdminCreateCreatorProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'adminCreateCreatorProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminCreateCreatorProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'acceptedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'appliedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rejectedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isFeatured' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'minimumMediaTip' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'minimumMessageTip' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'freeMessageTipEnabled' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'freeMediaTipEnabled' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminCreateCreatorProfileMutation,
  AdminCreateCreatorProfileMutationVariables
>;
export const AdminUpdateUserProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'adminUpdateUserProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminUpdateUserProfileInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminUpdateUserProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bio' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bannerUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'websiteUrl' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminUpdateUserProfileMutation,
  AdminUpdateUserProfileMutationVariables
>;
export const AdminUpdateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'adminUpdateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'adminUpdateUserId' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminUpdateUserInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminUpdateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'adminUpdateUserId' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminUpdateUserMutation,
  AdminUpdateUserMutationVariables
>;
export const AdminGetUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminGetUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminGetUsersInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminGetUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deletedAt' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastLoginAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastSeenAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userProfile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatarUrl' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'username' }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creatorProfile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'acceptedAt' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<AdminGetUsersQuery, AdminGetUsersQueryVariables>;
export const AdminGetUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminGetUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'adminGetUserId' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminGetUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'adminGetUserId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastLoginAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSeenAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userProfile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'bio' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'username' }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creatorProfile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acceptedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'allowsMessaging' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'appliedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'foreignAccount' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freeMediaTipEnabled' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freeMessageTipEnabled' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isFeatured' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minimumMediaTip' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minimumMessageTip' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rejectedAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'personaStatus' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'socialAccounts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'facebook' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'instagram' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tiktok' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'twitter' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'website' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'youtube' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<AdminGetUserQuery, AdminGetUserQueryVariables>;
export const AdminGetPaymentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminGetPayments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AdminGetPaymentsInput' }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminGetPayments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amountInCents' }
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'applicationFeeAmountInCents'
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creatorId' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deletedAt' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'fanId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paymentProcessor' }
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'paymentProcessorTransactionId'
                        }
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'processingFeeAmountInCents'
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'relatedEntityId' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'salesTaxAmountInCents' }
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'salesTaxBillingLocation'
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'countryCode' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'region' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'zip' }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tipType' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  AdminGetPaymentsQuery,
  AdminGetPaymentsQueryVariables
>;
