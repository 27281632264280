import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { TextInput } from 'react-admin';
import { CenteredModalContainer, StyledBox } from './style';

interface EditFieldModalProps {
  open: boolean;
  onClose: () => void;
  onCallbackUpdate: (value: string) => void;
  title: string | undefined;
  sourceInput: string;
}

const EditFieldModal = ({
  onCallbackUpdate,
  title,
  onClose,
  open,
  sourceInput
}: EditFieldModalProps) => {
  const [fieldUpdated, setFieldUpdated] = useState('');

  return (
    <Modal open={open} onClose={onClose}>
      <CenteredModalContainer>
        <StyledBox>
          <Stack direction="column" alignItems="center" spacing={2}>
            <Typography variant="body1" color="black" mt={2}>
              {title}
            </Typography>
            <TextInput
              source={sourceInput}
              variant="filled"
              format={(v) => v && v.toLowerCase()}
              onChange={(e) => setFieldUpdated(e.target.value)}
            />
            <Box mt={2}>
              <Button
                variant="contained"
                color="success"
                size="large"
                onClick={() =>
                  onCallbackUpdate(fieldUpdated.toLowerCase() as string)
                }
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="error"
                size="large"
                onClick={onClose}
                sx={{ marginLeft: 2 }}
              >
                Cancel
              </Button>
            </Box>
          </Stack>
        </StyledBox>
      </CenteredModalContainer>
    </Modal>
  );
};
export default EditFieldModal;
