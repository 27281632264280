import {
  AdminGetUserQueryVariables,
  AdminGetUsersQueryVariables,
  AdminUpdateUserMutationVariables
} from '@/gql/generated/graphql';
import {
  getUserForAdminsDataProviderMutation,
  getUsersForAdminsDataProviderMutation,
  updateAdminAccessMutation
} from './admins.server';
import clientWrapper from './wrappers/client.wrapper';

export const getAdminsActions = () => {
  const getUserForAdminsDataProvider = (
    variables: AdminGetUserQueryVariables
  ) => {
    return getUserForAdminsDataProviderMutation(variables);
  };

  const getUsersForAdminsDataProvider = (
    variables: AdminGetUsersQueryVariables
  ) => {
    return getUsersForAdminsDataProviderMutation(variables);
  };

  const updateAdminAccess = (variables: AdminUpdateUserMutationVariables) => {
    return updateAdminAccessMutation(variables);
  };

  return {
    getUserForAdminsDataProvider: clientWrapper(getUserForAdminsDataProvider),
    getUsersForAdminsDataProvider: clientWrapper(getUsersForAdminsDataProvider),
    updateAdminAccess: clientWrapper(updateAdminAccess)
  };
};
