import {
  AdminBulkUpdatePostsMutationVariables,
  AdminGetPostQueryVariables,
  AdminGetPostsQueryVariables,
  AdminRestorePostCommentMutationVariables,
  AdminRestorePostMutationVariables,
  AdminSoftDeletePostCommentMutationVariables,
  AdminSoftDeletePostMutationVariables
} from '@/gql/generated/graphql';
import {
  deletePostCommentMutation,
  deletePostMutation,
  getPostQuery,
  getPostsQuery,
  restorePostCommentMutation,
  restorePostMutation,
  updatePostsMutation
} from './posts.server';
import clientWrapper from './wrappers/client.wrapper';

export const getPostsActions = () => {
  const getPosts = (variables: AdminGetPostsQueryVariables) => {
    return getPostsQuery(variables);
  };

  const getPost = (variables: AdminGetPostQueryVariables) => {
    return getPostQuery(variables);
  };

  const deletePost = (variables: AdminSoftDeletePostMutationVariables) => {
    return deletePostMutation(variables);
  };

  const restorePost = (variables: AdminRestorePostMutationVariables) => {
    return restorePostMutation(variables);
  };

  const deletePostComment = (
    variables: AdminSoftDeletePostCommentMutationVariables
  ) => {
    return deletePostCommentMutation(variables);
  };

  const restorePostComment = (
    variables: AdminRestorePostCommentMutationVariables
  ) => {
    return restorePostCommentMutation(variables);
  };

  const updatePosts = (variables: AdminBulkUpdatePostsMutationVariables) => {
    return updatePostsMutation(variables);
  };
  return {
    getPost: clientWrapper(getPost),
    getPosts: clientWrapper(getPosts),
    deletePost: clientWrapper(deletePost),
    restorePost: clientWrapper(restorePost),
    deletePostComment: clientWrapper(deletePostComment),
    restorePostComment: clientWrapper(restorePostComment),
    updatePosts: clientWrapper(updatePosts)
  };
};
