import {
  AdminGetSubscriptionsQueryVariables,
  MutationAdminCancelSubscriptionArgs
} from '@/gql/generated/graphql';
import {
  cancelSubscriptionMutation,
  getSubscriptionsQuery
} from './subscriptions.server';
import clientWrapper from './wrappers/client.wrapper';

export const getSubscriptionsActions = () => {
  const getSubscriptions = (variables: AdminGetSubscriptionsQueryVariables) => {
    return getSubscriptionsQuery(variables);
  };

  const cancelSubscription = (
    variables: MutationAdminCancelSubscriptionArgs
  ) => {
    return cancelSubscriptionMutation(variables);
  };

  return {
    getSubscriptions: clientWrapper(getSubscriptions),
    cancelSubscription: clientWrapper(cancelSubscription)
  };
};
