import { AdminAdminNoteDto } from '@/gql/generated/graphql';
import { getNotesActions } from '@/server-actions';
import { useEffect, useState } from 'react';

const useAdminNotes = (userId: string) => {
  const [list, setList] = useState<AdminAdminNoteDto[]>([]);
  const { createNote, deleteNote, updateNote, getNotes } = getNotesActions();

  const createAdminNote = async (content: string) => {
    try {
      const data = await createNote({ input: { userId, content } });

      if (data.adminCreateAdminNote) {
        setList((prevList) => [data.adminCreateAdminNote, ...prevList]);
      }
    } catch (err: any) {
      throw new Error(err?.message);
    }
  };

  const deleteAdminNote = async (noteId: string) => {
    try {
      const data = await deleteNote({
        input: { id: noteId }
      });

      if (data?.adminDeleteAdminNote > 0) {
        setList((prev) => prev.filter((note) => note.id !== noteId));
      }
    } catch (err: any) {
      throw new Error(err?.message);
    }
  };

  const editAdminNote = async (noteId: string, content: string) => {
    try {
      const data = await updateNote({ id: noteId, input: { content } });

      if (data.adminUpdateAdminNote) {
        setList((prev) =>
          prev.map((note) =>
            note.id === noteId
              ? { ...note, content: data.adminUpdateAdminNote.content }
              : note
          )
        );
      }
    } catch (err: any) {
      throw new Error(err?.message);
    }
  };

  const fetchAdminNotes = async () => {
    try {
      const data = await getNotes({ input: { userId } });

      if (data.adminGetAdminNotes) {
        setList(data.adminGetAdminNotes);
      }
    } catch (err: any) {
      throw new Error(err?.message);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchAdminNotes();
    }
  }, [userId]);

  return {
    list,
    fetchAdminNotes,
    createAdminNote,
    deleteAdminNote,
    editAdminNote
  };
};
export default useAdminNotes;
