import type { OperationVariables } from '@apollo/client';
export class FanFixException extends Error {
  public path?: string;
  public variables?: OperationVariables;
  public errors?: any; // eslint-disable-line

  constructor(
    message: string,
    options: { path?: string; variables?: Record<string, any>; errors?: any }
  ) {
    super(message);
    this.path = options.path;
    this.variables = options.variables ?? {};
    this.errors = options.errors;
    Error.captureStackTrace?.(this, FanFixException); // eslint-disable-line
  }
}
