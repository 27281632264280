import {
  AdminGetPostQueryVariables,
  AdminGetUsersFilterOptionInput,
  FilterProps,
  UserRoles
} from '@/gql/generated/graphql';
import {
  getAdminLivestreamActions,
  getAdminsActions,
  getBlastsActions,
  getCreatorsActions,
  getPostsActions,
  getUsersActions
} from '@/server-actions';
import _ from 'lodash';
import {
  CreateParams,
  CreateResult,
  DataProvider,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneResult,
  Identifier,
  RaRecord,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult
} from 'react-admin';

const { getPosts, getPost } = getPostsActions();

const {
  getUsersForUsersDataProvider,
  getUserForUsersDataProvider,
  updateUser
} = getUsersActions();

const { getUsersForCreatorsDataProvider, getUserForCreatorsDataProvider } =
  getCreatorsActions();

const { getAdminLivestreams, getAdminLivestream } = getAdminLivestreamActions();

const { getMessageBlasts } = getBlastsActions();

const {
  getUsersForAdminsDataProvider,
  getUserForAdminsDataProvider,
  updateAdminAccess
} = getAdminsActions();

export const postsDataProvider: DataProvider = {
  getList: async (_, { pagination, filter }): Promise<GetListResult> => {
    const result = await getPosts({
      input: {
        pagination: {
          limit: pagination.perPage,
          offset: (pagination.page - 1) * pagination.perPage
        },
        filter: {
          query: filter.query,
          isRecommended: filter.isRecommended
        }
      }
    });

    return {
      data: result?.['adminGetPosts']['posts'] || [],
      total: result?.['adminGetPosts']['totalCount'] ?? 0
    };
  },
  getOne: async (_param, { id }): Promise<GetOneResult> => {
    const result = await getPost({ id } as AdminGetPostQueryVariables);
    return { data: result['adminGetPost'] ?? {} };
  },

  update: async (_param, { id, data }) => {
    return {
      data: {} as any
    };
  },
  delete: async (_param, { id }) => {
    return {
      data: {} as any
    };
  },
  getMany: async () => {
    return { data: [] };
  },
  getManyReference: async () => {
    return { data: [] };
  },
  create: async (_param, { data }) => {
    return {
      data: {} as any
    };
  },
  updateMany: async () => {
    return { data: [] };
  },
  deleteMany: async () => {
    return { data: [] };
  }
};

export const creatorsDataProviders: DataProvider = {
  getList: async (
    _param,
    { pagination, filter, sort }
  ): Promise<GetListResult> => {
    const filters: AdminGetUsersFilterOptionInput[] = [
      {
        property: FilterProps.Roles,
        values: ['creator']
      }
    ];
    const { property, query } = filter;
    if (property && query) {
      filters.push({
        property,
        value: query
      });
    }

    const result = await getUsersForCreatorsDataProvider({
      input: {
        pagination: {
          limit: pagination.perPage,
          offset: (pagination.page - 1) * pagination.perPage,
          order: 'DESC',
          orderBy: 'creatorProfile.appliedAt'
        },
        filters
      }
    });

    return {
      data: result?.['adminGetUsers']['users'] ?? [],
      total: result?.['adminGetUsers']['totalCount'] ?? 0
    };
  },

  getOne: async (_param, { id }): Promise<GetOneResult> => {
    const result = await getUserForCreatorsDataProvider({
      adminGetUserId: id as string
    });

    return {
      data: {
        ...result['adminGetUser']
      }
    };
  },
  update: async (_param, { id, data }) => {
    return {
      data: {} as any
    };
  },
  delete: async (_param, { id }) => {
    return {
      data: {} as any
    };
  },
  getMany: async () => {
    return { data: [] };
  },
  getManyReference: async () => {
    return { data: [] };
  },
  create: async (_param, { data }) => {
    return {
      data: {} as any
    };
  },
  updateMany: async () => {
    return { data: [] };
  },
  deleteMany: async () => {
    return { data: [] };
  }
};

export const usersDataProviders: DataProvider = {
  getList: async (_param, { pagination, filter }): Promise<GetListResult> => {
    const filters: AdminGetUsersFilterOptionInput[] = [];
    if (filter.property && filter.query) {
      filters.push({
        property: filter.property,
        value: filter.query
      });
    }
    const result = await getUsersForUsersDataProvider({
      input: {
        pagination: {
          limit: pagination.perPage,
          offset: (pagination.page - 1) * pagination.perPage,
          order: 'DESC'
        },
        filters
      }
    });

    return {
      data: result?.['adminGetUsers']['users'] ?? [],
      total: result?.['adminGetUsers']['totalCount'] ?? 0
    };
  },

  getOne: async (_param, { id }): Promise<GetOneResult> => {
    const result = await getUserForUsersDataProvider({
      adminGetUserId: id as string
    });

    return {
      data: {
        ...result['adminGetUser']
      }
    };
  },

  update: async (_param, { id, data }): Promise<UpdateResult> => {
    const result = await updateUser({
      adminUpdateUserId: id,
      input: {
        roles: _.xor(data.roles, [UserRoles.Admin])
      }
    });

    return {
      data: result?.['adminUpdateUser']
    };
  },
  delete: async (_, { id }) => {
    return {
      data: {} as any
    };
  },
  getMany: async () => {
    return { data: [] };
  },
  getManyReference: async () => {
    return { data: [] };
  },
  create: async (_, { data }) => {
    return {
      data: {} as any
    };
  },
  updateMany: async () => {
    return { data: [] };
  },
  deleteMany: async () => {
    return { data: [] };
  }
};

export const adminsDataProviders: DataProvider = {
  getList: async (_, { pagination, filter }): Promise<GetListResult> => {
    const filters: AdminGetUsersFilterOptionInput[] = [
      {
        property: FilterProps.Roles,
        values: ['admin']
      }
    ];
    if (filter.property && filter.query) {
      filters.push({
        property: filter.property,
        value: filter.query
      });
    }
    const result = await getUsersForAdminsDataProvider({
      input: {
        pagination: {
          limit: pagination.perPage,
          offset: (pagination.page - 1) * pagination.perPage,
          order: 'DESC'
        },
        filters
      }
    });

    return {
      data: result?.['adminGetUsers']['users'] ?? [],
      total: result?.['adminGetUsers']['totalCount'] ?? 0
    };
  },
  getOne: async (_, { id }): Promise<GetOneResult> => {
    const result = await getUserForAdminsDataProvider({
      adminGetUserId: id as string
    });

    return {
      data: result['adminGetUser']
    };
  },

  update: async (_, { id, data }) => {
    return {
      data: {} as any
    };
  },
  delete: async (_, { id }) => {
    return {
      data: {} as any
    };
  },
  getMany: async () => {
    return { data: [] };
  },
  getManyReference: async () => {
    return { data: [] };
  },
  create: async (_, { data }): Promise<CreateResult> => {
    const result = await updateAdminAccess({
      input: { roles: [UserRoles.Admin] },
      adminUpdateUserId: data.id
    });

    return {
      data: result['adminUpdateUser']
    };
  },

  updateMany: async () => {
    return { data: [] };
  },
  deleteMany: async () => {
    return { data: [] };
  }
};

export const blastsDataProviders: DataProvider = {
  getOne: () => {
    return {} as any;
  },
  getList: async (_, { pagination, filter }): Promise<GetListResult> => {
    const result = await getMessageBlasts({
      input: {
        pagination: {
          limit: pagination.perPage,
          offset: (pagination.page - 1) * pagination.perPage,
          order: 'DESC'
        },
        filter: {
          query: filter.query
        }
      }
    });

    return {
      data: result?.['adminGetMessageBlasts']['messageBlasts'] ?? [],
      total: result?.['adminGetMessageBlasts']['totalCount'] ?? 0
    };
  },

  update: async (_, { id, data }) => {
    return {
      data: {} as any
    };
  },
  delete: async (_, { id }) => {
    return {
      data: {} as any
    };
  },
  getMany: async () => {
    return { data: [] };
  },
  getManyReference: async () => {
    return { data: [] };
  },
  create: async (_, { data }): Promise<CreateResult> => {
    const result = await updateAdminAccess({
      input: { roles: [UserRoles.Admin] },
      adminUpdateUserId: data.id
    });

    return {
      data: result['adminUpdateUser']
    };
  },

  updateMany: async () => {
    return { data: [] };
  },
  deleteMany: async () => {
    return { data: [] };
  }
};

export const adminsLiveStreamsDataProviders: DataProvider = {
  getList: async (_, { pagination, filter }): Promise<GetListResult> => {
    const result = await getAdminLivestreams({
      input: {
        filter: {
          audienceType: filter.audienceType,
          isLive: filter.isLive,
          title: filter.title,
          id: filter.id,
          creatorId: filter.creatorId
        },
        pagination: {
          limit: pagination.perPage,
          offset: (pagination.page - 1) * pagination.perPage,
          orderBy: 'createdAt',
          order: 'DESC'
        }
      }
    });

    return {
      data: result?.['adminGetLivestreams']['livestreams'] ?? [],
      total: result?.['adminGetLivestreams']['totalCount'] ?? 0
    };
  },
  getOne: async (_param, { id }): Promise<GetOneResult> => {
    const result = await getAdminLivestream({
      adminGetLivestreamId: id as string
    });
    return {
      data: result?.adminGetLivestream
    };
  },

  getMany: function <RecordType extends RaRecord = any>(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<RecordType>> {
    throw new Error('Function not implemented.');
  },
  getManyReference: function <RecordType extends RaRecord = any>(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<RecordType>> {
    throw new Error('Function not implemented.');
  },
  update: function <RecordType extends RaRecord = any>(
    resource: string,
    params: UpdateParams
  ): Promise<UpdateResult<RecordType>> {
    throw new Error('Function not implemented.');
  },
  updateMany: function <RecordType extends RaRecord = any>(
    resource: string,
    params: UpdateManyParams
  ): Promise<UpdateManyResult<RecordType>> {
    throw new Error('Function not implemented.');
  },
  create: function <
    RecordType extends Omit<RaRecord, 'id'> = any,
    ResultRecordType extends RaRecord = RecordType & { id: Identifier }
  >(
    resource: string,
    params: CreateParams
  ): Promise<CreateResult<ResultRecordType>> {
    throw new Error('Function not implemented.');
  },
  delete: function <RecordType extends RaRecord = any>(
    resource: string,
    params: DeleteParams<RecordType>
  ): Promise<DeleteResult<RecordType>> {
    throw new Error('Function not implemented.');
  },
  deleteMany: function <RecordType extends RaRecord = any>(
    resource: string,
    params: DeleteManyParams<RecordType>
  ): Promise<DeleteManyResult<RecordType>> {
    throw new Error('Function not implemented.');
  }
};
